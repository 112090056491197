import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { Button, Fade, FormControl, InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { discountFetchController, pricingPOSTController } from "../../dataController/dataController";
import { useQuote } from "../../context/quoteContext";
import { useLocation } from "../../context/locationContext";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { sanitizeNumber } from "../../helpers/sanitize";
import { useFormData } from "../../context/formContext";

const useStyles = makeStyles(
  () => ({
    discountButton: {
      marginLeft: "20px !important",
      height: "47px",
      backgroundColor: "#675CE5 !important",
      "&:hover": {
        backgroundColor: "#FA9134 !important",
      },
    },
    AnotherDiscountButton: {
      marginLeft: "20px !important",
      height: "47px",
      backgroundColor: "#FA9134 !important",
      "&:hover": {
        backgroundColor: "#FA9134 !important",
      },
    },
    discountSuccessText: {
      fontWeight: "400",
      fontSize: "0.75rem",
      lineHeight: 1.66,
      marginTop: "3px",
      color: "#675CE5",
    },
    discountAnotherDiscount: {
      color: "#675CE5",
      fontFamily: "Inter",
      fontSize: "12px",
      paddingTop: "0.5rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "120%"
    },
    discountErrorText: {
      fontWeight: "400",
      fontSize: "0.75rem",
      lineHeight: 1.66,
      marginTop: "3px",
      color: "#d32f2f",
    },
  }),
  { classNamePrefix: "discountCode" }
);

const DiscountCode = ({ setPurchaseDetail, control, Controller }) => {

  const { getFormData, setFormData, parsedData } = useFormData();
  const storedDisCode = getFormData()["discode"];
  const storedDisCode2 = getFormData()["discode_dual"];
  const { t } = useTranslation();
  const classes = useStyles();
  const { data } = useQuote();
  const { quote } = data || {};
  const { geolocation } = useLocation();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [status, setStatus] = useState(false);
  const [status2, setStatus2] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitted2, setSubmitted2] = useState(false);
  const [disCode, setDisCode] = useState(storedDisCode);
  const [disCode2, setDisCode2] = useState(null);
  const [disCode2Vis, setDisCode2Vis] = useState(false);
  const [anotherDiscode, setAnotherDiscode] = useState(false);
  const ERROR_MESSAGES = {
    form: {
      INVALID_CODE: t("form.error.invalidDiscountCode"),
      VALID_CODE: t("form.error.successDiscountCode"),
    },
  };

  // CHECK if localStorage is enabled

  const isLocalStorageEnabled = () => {
    try {
      const key = `__storage__test`;
      window.localStorage.setItem(key, null);
      window.localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (disCode) {

      // console.log('disCode', disCode);
      // console.log('submitted', submitted);
      // console.log('status', status);


      const additionalDiscodeControl = ['365atulado', 'experiencia365'];
      if (disCode && additionalDiscodeControl.includes(disCode.toLowerCase())) {
        setAnotherDiscode(true);
      } else {
        setAnotherDiscode(false);
      }
    }
  }, [disCode]);

  useEffect(() => {
    try {

      if (isLocalStorageEnabled() && localStorage.getItem('voucher')) {
        const voucher = localStorage.getItem('voucher');
        setDisCode(voucher);
        onSubmit("discode");
        setFormData((prev) => ({ ...prev, ...{ discode: voucher } }));

        const additionalDiscodeControl = ['365atulado', 'experiencia365'];
        if (additionalDiscodeControl.includes(voucher.toLowerCase()) && localStorage.getItem('voucher2')) {
          const voucher2 = localStorage.getItem('voucher2');
          setDisCode2(voucher2);
          onSubmit("discode2");
          setFormData((prev) => ({ ...prev, ...{ discode_dual: voucher2 } }));
        }

      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const statusIcon = useMemo(() => {
    if (loading) {
      return <CircularProgress size={20} sx={{ color: "#006FE8" }} />;
    }

    if ((status && submitted) || storedDisCode) {
      return <CheckIcon size={20} color="success" />;
    }

    if (!status && submitted) {
      return <ClearIcon size={20} color="error" />;
    }

    return null;
  }, [status, loading, submitted, storedDisCode]);

  const statusIcon2 = useMemo(() => {
    if (loading2) {
      return <CircularProgress size={20} sx={{ color: "#006FE8" }} />;
    }

    if ((status2 && submitted2) || storedDisCode2) {
      return <CheckIcon size={20} color="success" />;
    }

    if (!status2 && submitted2) {
      return <ClearIcon size={20} color="error" />;
    }

    return null;
  }, [status2, loading2, submitted2, storedDisCode2]);

  const onSubmit = useCallback(async (target) => {

    // console.log('onsubmit_________________');
    // console.log('disCode', disCode);
    // console.log('disCode2', disCode2);
    // console.log('target', target);

    if (disCode && target === "discode" || disCode2 && target === "discode2") {

      // console.log('onSubmit target ------------>', target);
      // setSubmitted(true);

      try {

        if (target === "discode") {
          parsedData.discode = disCode;
          // console.log('disCode ---> ', disCode);
          setLoading(true);
        } else {
          parsedData.discode_dual = disCode2;
          setLoading2(true);
        }

        const fetchData = async () => {
          try {
            const result = await pricingPOSTController.fetchData({
              body: parsedData,
            });

            setPurchaseDetail((prev) => ({ ...prev, ...result.data }));
            let status = false;

            if (target === "discode") {
              setDisCode(disCode);
              setFormData((prev) => ({ ...prev, ...{ discode: disCode } }));
              status = result.data.hasOwnProperty('discount') ? true : false;

              setStatus(status);
              setSubmitted(true);
            } else {
              setDisCode2(disCode2);
              setFormData((prev) => ({ ...prev, ...{ discode_dual: disCode2 } }));
              status = result.data.hasOwnProperty('discount_dual') ? true : false;
              setStatus2(status);
              setSubmitted2(true);
            }

            if (!status && (target === "discode" || target === "discode2")) {
              resetForm(target);
              resetPurchaseDetail(target);
            } else {
              if (target === "discode") {
                window.localStorage.setItem('voucher', disCode);
              } else {
                window.localStorage.setItem('voucher2', disCode2);
              }
            }

          } catch (error) {
            handleSubmissionError(error);
          } finally {
            if (target === "discode") {
              setLoading(false);
            } else {
              setLoading2(false);
            }
            console.info("Submission done <DiscountCode>");
          }
        };
        fetchData();


        // const queryParams = {
        //   affiliate_code: disCode,
        //   total_price: sanitizeNumber(quote?.total_price),
        //   country: geolocation?.country_id,
        //   product_id: quote?.product_id,
        // };

        // const queryString = new URLSearchParams(queryParams).toString();

        // const result = await discountFetchController.fetchData(
        //   queryString,
        //   setLoading
        // );

        //***** setLoading

        // handleDiscountResult(result, disCode);

      } catch (error) {
        handleSubmissionError(error);
      } finally {
        console.info("Submission done");
      }
    }
  }, [quote, geolocation, disCode, disCode2]);

  const handleDiscountResult = (result) => {

    const totalPriceBack = Number(sanitizeNumber(quote.total_price));
    // const resultTotalPrice = Number(result.data.total_price);
    // const totalDiscount = !isNaN(resultTotalPrice)
    //   ? (totalPriceBack - resultTotalPrice).toFixed(2)
    //   : null;

    const totalDiscount = Number(result?.data.discount) ?? totalPriceBack;

    setPurchaseDetail((prev) => ({
      ...prev,
      total_price: result.data.total_price || sanitizeNumber(quote.total_price),
      total_discount: totalDiscount,
    }));

    const status = result.data.status;
    setStatus(status);

    if (status) {
      setFormData((prev) => ({ ...prev, ...{ discode: disCode } }));
    }
  };

  const handleSubmissionError = (error) => {
    console.error("Error during submission:", error);
  };

  const resetForm = useCallback((target) => {

    if (target === "discode") {
      let cleanDisCode = { discode: undefined };
      cleanDisCode.discode_dual = undefined
      setFormData((prev) => ({ ...prev, ...cleanDisCode }));
      setSubmitted2(false);
    } else {
      setDisCode2("");
      setFormData((prev) => ({ ...prev, ...{ discode_dual: undefined } }));
      localStorage.removeItem('voucher2');
    }
  }, []);

  const resetPurchaseDetail = (target) => {

    if (target === "discode") {
      setPurchaseDetail((prev) => ({
        ...prev,
        total_price: sanitizeNumber(quote.total_price),
        total_discount: null,
        discount: null,
        discount_dual: null,
      }));
    } else {
      setPurchaseDetail((prev) => ({
        ...prev,
        total_price: sanitizeNumber(quote.total_price),
        discount_dual: null,
      }));
    }
  };

  const onChange = (e, target) => {

    const value = e.target.value;

    if (target === "discode") {
      setDisCode(value);
    } else {
      setDisCode2(value);
    }

    if (value === "") {
      resetForm(target);
      resetPurchaseDetail(target);
    } else {

      // if (target === "discode") {
      //   setDisCode(value);
      // } else {
      //   setDisCode2(value);
      // }
    }


  };
  const helperText = useMemo(() => {

    if (loading) {
      return null;
    }
    if ((status && submitted) || (storedDisCode)) {
      return (
        <small className={classes.discountSuccessText}>
          {t(ERROR_MESSAGES.form.VALID_CODE)}
        </small>
      );
    }

    if ((!status && submitted) || (!status && storedDisCode)) {
      return (
        <small className={classes.discountErrorText}>
          {t(ERROR_MESSAGES.form.INVALID_CODE)}
        </small>
      );
    }

    return null;
  }, [status, loading, submitted, storedDisCode]);

  const helperText2 = useMemo(() => {

    if (loading2) {
      return null;
    }
    if ((status2 && submitted2) || storedDisCode2) {
      return (
        <small className={classes.discountSuccessText}>
          {t(ERROR_MESSAGES.form.VALID_CODE)}
        </small>
      );
    }

    if ((!status2 && submitted2) || (!status2 && storedDisCode2)) {
      return (
        <small className={classes.discountErrorText}>
          {t(ERROR_MESSAGES.form.INVALID_CODE)}
        </small>
      );
    }

    return null;
  }, [status2, loading2, submitted2, storedDisCode2]);

  return (
    <>
      <div>
        <h1 className="font-bold text-2xl text-[#006FE8] mb-5">
          {t("index.discountForm")}
        </h1>
        <FormControl fullWidth>
          <label
            className="text-[#414D58] font-bold text-xs mb-2"
            aria-controls="#email-input"
          >
            {t("form.label.discountCode")}
          </label>
          <div className="flex justify-between">
            <Controller
              name={"discode"}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoComplete="off"
                  fullWidth
                  onChange={(e) => onChange(e, "discode")}
                  value={disCode}
                  helperText={helperText}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{statusIcon}</InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Button
              id="cta-apply-discount"
              className={classes.discountButton}
              variant="contained"
              onClick={() => onSubmit('discode')}
            >
              <span className="text-[20px] mx-6 text-white rounded-lg">
                {t("index.discountFormButton")}
              </span>
            </Button>
          </div>

        </FormControl>
      </div>

      {((status && submitted) || storedDisCode) && anotherDiscode &&
        <div>
          {!disCode2Vis && <button type="button" onClick={() => setDisCode2Vis(true)} className={classes.discountAnotherDiscount}>¿Tienes otro código de descuento?</button>}
          {anotherDiscode && disCode2Vis && (
            <div className="mt-4">
              <FormControl fullWidth>
                <div className="flex justify-between">
                  <Controller
                    name={"discode_dual"}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoComplete="off"
                        fullWidth
                        onChange={(e) => onChange(e, "discode2")}
                        value={disCode2}
                        helperText={helperText2}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">{statusIcon2}</InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Button
                    id="cta-apply-discount"
                    className={status2 ? classes.discountButton : classes.AnotherDiscountButton}
                    variant="contained"
                    onClick={() => onSubmit("discode2")}
                  >
                    <span className="text-[20px] mx-6 text-white rounded-lg">
                      {t("index.discountFormButton")}
                    </span>
                  </Button>
                </div>
              </FormControl>
            </div>)}
        </div>}

    </>
  );
};

export default DiscountCode;
