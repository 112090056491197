// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* StepperWithTransition.css */

.stepper-transition-enter {
  transform: translateX(100%);
}

.stepper-transition-enter-active {
  transform: translateX(0);
  transition: transform 500ms ease-in-out;
}

.stepper-transition-exit {
  transform: translateX(0);
}

.stepper-transition-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/StepContent/StepperTransition.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;EACE,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;EACxB,uCAAuC;AACzC;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,4BAA4B;EAC5B,uCAAuC;AACzC","sourcesContent":["/* StepperWithTransition.css */\n\n.stepper-transition-enter {\n  transform: translateX(100%);\n}\n\n.stepper-transition-enter-active {\n  transform: translateX(0);\n  transition: transform 500ms ease-in-out;\n}\n\n.stepper-transition-exit {\n  transform: translateX(0);\n}\n\n.stepper-transition-exit-active {\n  transform: translateX(-100%);\n  transition: transform 500ms ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
