import React, { useState, useCallback, useEffect } from "react";
import * as paymentProcessHelper from "../helpers/paymentProcessHelper";
import { useCountries } from "../context/countriesContext";
import { mapBillingData } from "../helpers/dataMapper";
import { useLoader } from "../context/loaderContext";
import { useQuote } from "../context/quoteContext";

const MercadoPagoHandler = ({ parsedData, purchaseDetail, controlError }) => {
  const { countries } = useCountries();
  const [billingState, setBillingState] = useState(false);
  const [reserveData, setReserveData] = useState(parsedData);
  const { setLoader } = useLoader();
  const { isLink } = useQuote();
  const [dataLink, setDataLink] = useState({});

  const userCountry =
    (countries &&
      countries.find((country) => country.id === reserveData?.country)) ||
    null;

  const billingData = reserveData?.reserve_id
    ? mapBillingData(reserveData, userCountry?.id, "MercadoPago")
    : null;

  useEffect(() => {
    setDataLink({
      reserve_id: reserveData?.reserve_id,
      transaction_amount: purchaseDetail?.total_price,
      description: `${purchaseDetail?.name} Reserva ${reserveData?.reserve_id}`,
      payer: reserveData["beneficiary[email][0]"],
    });
  }, []);

  const emit = useCallback(async () => {
    const result = await paymentProcessHelper.emit(parsedData);

    setReserveData((prev) => ({
      ...prev,
      ...{
        reserve_id: result?.reserve_id,
        reserve_status: result?.reserve_status,
        transaction_amount: purchaseDetail?.total_price,
        description: `${purchaseDetail?.name} Reserva ${result?.reserve_id}`,
        payer: parsedData["beneficiary[email][0]"],
      },
    }));
  }, [parsedData, purchaseDetail]);

  const fetchBilling = useCallback(async () => {
    const result = await paymentProcessHelper.fetchBilling(billingData);
    setBillingState(result.status);
  }, [billingData]);

  const fetchPay = useCallback(async () => {
    const data_reserve = isLink ? dataLink : reserveData;
    const body = {
      method: "Mercadopago",
      reserve_id: data_reserve?.reserve_id,
      country_id: reserveData?.country,
      transaction_amount: data_reserve?.transaction_amount,
      description: data_reserve?.description,
      payer: data_reserve?.payer,
      pref: "checkout",
      back_url: window.location.href,
    };

    if (data_reserve?.discode_dual) {
      body["affiliate_code_dual"] = data_reserve?.discode_dual;
    }

    const success = await paymentProcessHelper.fetchPay(body);

    if (success?.data?.status) {
      setLoader(true);
      window.location.href = success?.data?.url;
    } else {
      controlError(true);
      setLoader(false);
    }
  }, [reserveData, dataLink]);

  // const setDataLayer = () => {
  //   try {

  //     const { currency_symbol, total_price, name, product_id } = data.quote;
  //     const impression = {
  //       "event": "add_payment_info",
  //       "currency": currency_symbol,
  //       "value": total_price,
  //       "payment_type": paymentMethod,
  //     };
  //     const voucher = localStorage.getItem("voucher") ?? null;
  //     if (voucher) {
  //       impression["coupon"] = voucher;
  //     }

  //     dataLayer.push({ ecommerce: null });

  //     impression["ecommerce"] = {
  //       items: [
  //         {
  //           "item_id": product_id,
  //           "item_name": name,
  //         }
  //       ]
  //     }
  //     dataLayer.push(impression);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   if (!isLink) {
  //     setDataLayer();
  //   }
  // }, []);

  useEffect(() => {
    if (billingData) {
      fetchBilling();
    }
  }, [billingData]);

  useEffect(() => {
    if (billingState) {
      fetchPay();
    }
  }, [billingState]);

  useEffect(() => {
    if (parsedData?.method === "mp" || parsedData?.method === "mercadopago") {
      console.log("loader TRUE");
      setLoader(true);
      emit();
    }
  }, [parsedData]);

  return null; // No renderiza ningún elemento visual.
};

export default MercadoPagoHandler;
