import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "./locationContext";
import { useTranslation } from "react-i18next";

const WhatsappContext = createContext({
  data: null,
});

export function WhatsappProvider({ children }) {
  const { geolocation } = useLocation();
  const [Whatsapp, setWhatsapp] = useState("");
  const { i18n } = useTranslation();

  /* 
  6: "Australia"
  49: "Costa Rica"
  52: "Mexico"
  135: "Usa"
  137: "Canada"
  157: "Uruguay"
  161: "Peru"
  164: "Argentina"
  165: "Chile"
  199: "España"
  */

  const chatCenter = (country_id) => {
    return [6, 49, 52, 135, 137, 157, 161, 164, 165, 199].includes(
      parseInt(country_id)
    );
  };

  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const initWhatsApp = (today_day, today_hour, country_id, header_whatsapp) => {
    /*
     * Static data
     */

    let whatsapp_number = "";

    const todayMinutes = timeToMinutes(today_hour);
    const startMinutes = timeToMinutes("21:01");
    const endMinutes = timeToMinutes("02:00");

    const _rules = `
      (${country_id} == 164 && (
      (${todayMinutes} >= ${startMinutes}) || (${todayMinutes} <= ${endMinutes}) || 
      (${today_day} == 6 || ${today_day} == 7)
  )) || ${country_id} != 164`;

    // if (!_rules) {
    //   _rules = `(${today_day} === "6") || (${today_day} === "7" && "${today_hour}" < "22:01")`;
    // }

    if (eval(_rules)) {
      whatsapp_number = "5491122647575";
    } else {
      if (country_id === 164) {
        whatsapp_number = header_whatsapp.substring(1);
      } else {
        whatsapp_number = chatCenter(country_id)
          ? "5491122647575"
          : header_whatsapp.substring(1);
      }
      if (
        country_id === 155 ||
        country_id === 201 ||
        i18n.language === "pt-BR"
      ) {
        whatsapp_number = "5548988523408";
      }
    }

    setWhatsapp(whatsapp_number);
  };

  useEffect(() => {
    if (geolocation) {
      let today_day = geolocation?.today_day;
      let today_hour = geolocation?.today_hour;
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("today_day") || urlParams.has("today_hour")) {
        if (urlParams.get("today_day")) {
          today_day = urlParams.get("today_day").toString();
        }
        if (urlParams.get("today_hour")) {
          today_hour = urlParams.get("today_hour").toString();
        }
      }
      initWhatsApp(
        today_day,
        today_hour,
        geolocation?.country_id,
        geolocation?.header_whatsapp
      );
    }
  }, [geolocation]);

  const value = {
    whatsapp_number: Whatsapp,
  };

  return (
    <WhatsappContext.Provider value={value}>
      {children}
    </WhatsappContext.Provider>
  );
}

export function useWhatsapp() {
  const context = useContext(WhatsappContext);
  if (!context) {
    throw new Error("useData debe ser utilizado dentro de un DataProvider");
  }
  return context;
}
