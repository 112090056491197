const API_BASE_URL = "https://app.assist-365.com/api";

const apiService = {
  fetchData: async (
    endpoint,
    method = "GET",
    queryString = "",
    options = {},
    setLoading = () => null
  ) => {
    try {
      setLoading(true);

      const url = `${API_BASE_URL}/${endpoint}${
        queryString ? `?${queryString}` : ""
      }`;

      const formData = new FormData();
      for (let key in options.body) {
        formData.append(key, options.body[key]);
      }

      const requestOptions = {
        method,
        headers: {
          Accept: "application/json, text/plain, */*",
        },
        body: formData,
      };

      const response = await fetch(
        url,
        method !== "GET" ? requestOptions : null
      );

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      if (endpoint === "billing" || endpoint === "send-deposit-notification") {
        return { data: null, error: null, status: response.ok };
      }
      const data = await response.json();
      return { data, error: null, status: response.status };
    } catch (error) {
      console.error("Error fetching data:", error.message);
      return { data: null, error: error.message };
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  },
};

export default apiService;
