// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dlocal-form .DlocalField {
  background-color: white;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

#dlocal-form .DlocalField input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.23);
  font-style: oblique;
  font-family: "Inter UI medium", sans-serif !important;
}

#dlocal-form .DlocalField input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.23);
  font-style: oblique;
  font-family: "Inter UI medium", sans-serif !important;
}

#dlocal-form .DlocalField input,
select {
  font-family: "Inter UI medium", sans-serif !important;
  display: block;
  font-size: 16px;
  width: 100%;
  border: none;
  border-radius: 4px;
  background: white;
  outline: none;
  color: #1b2225;
}

#dlocal-form .DlocalField div {
  height: 100%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/DlocalCreditCardForm/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,qCAAqC;AACvC;;AAEA;EACE,0BAA0B;EAC1B,mBAAmB;EACnB,qDAAqD;AACvD;;AAEA;EACE,0BAA0B;EAC1B,mBAAmB;EACnB,qDAAqD;AACvD;;AAEA;;EAEE,qDAAqD;EACrD,cAAc;EACd,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":["#dlocal-form .DlocalField {\n  background-color: white;\n  padding: 12px;\n  border-radius: 10px;\n  border: 1px solid rgba(0, 0, 0, 0.23);\n}\n\n#dlocal-form .DlocalField input::-moz-placeholder {\n  color: rgba(0, 0, 0, 0.23);\n  font-style: oblique;\n  font-family: \"Inter UI medium\", sans-serif !important;\n}\n\n#dlocal-form .DlocalField input::-webkit-input-placeholder {\n  color: rgba(0, 0, 0, 0.23);\n  font-style: oblique;\n  font-family: \"Inter UI medium\", sans-serif !important;\n}\n\n#dlocal-form .DlocalField input,\nselect {\n  font-family: \"Inter UI medium\", sans-serif !important;\n  display: block;\n  font-size: 16px;\n  width: 100%;\n  border: none;\n  border-radius: 4px;\n  background: white;\n  outline: none;\n  color: #1b2225;\n}\n\n#dlocal-form .DlocalField div {\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
