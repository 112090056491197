export const dateFormat = (date, locale) => {
  const originalDate = new Date(date);

  const format = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return originalDate.toLocaleDateString(locale, format);
};

export const convertDate = (inputDate) => {
  const date = new Date(inputDate);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return `${day}/${month}/${year}`;
};

export const convertDateFormat = (
  inputDate,
  format = "yyyy-mm-dd",
  separator = "-"
) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  let formattedDate = "";

  if (format === "yyyy-mm-dd") {
    formattedDate = `${year}${separator}${month}${separator}${day}`;
  }
  return formattedDate;
};
