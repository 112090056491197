import React, { useCallback, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LockIcon from "@mui/icons-material/Lock";

import {
  FormGroup,
  TextField,
  MenuItem,
  Card,
  CardContent,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import * as paymentProcessHelper from "../../helpers/paymentProcessHelper";
import { useFormData } from "../../context/formContext";
import { removeBeneficiaryKeys } from "../../helpers/sanitize";
import { useLocation } from "../../context/locationContext";
import { useQuote } from "../../context/quoteContext";
const CieloCreditCardForm = ({
  purchaseDetail,
  control,
  Controller,
  errors,
  handleSubmit,
  formRef,
}) => {
  const { t } = useTranslation();

  const ERROR_MESSAGES = {
    form: {
      REQUIRED: t("form.error.required"),
    },
  };

  const [dues, setDues] = useState([]);
  const { parsedData } = useFormData();
  const { basePath } = useLocation();
  const [reserveData, setReserveData] = useState(parsedData);
  const { isLink } = useQuote();
  const [requestError, setRequestError] = useState(null);

  useEffect(() => {
    if (!purchaseDetail || purchaseDetail.total_price == null) {
      return;
    }
    const installments = purchaseDetail.total_price <= 399 ? 5 : 10;
    const newInstallments = Array.from({ length: installments }, (_, index) => {
      const installmentNumber = index + 1;
      const pricePerInstallment = (
        purchaseDetail.total_price / installmentNumber
      ).toFixed(2);
      return {
        value: installmentNumber,
        label: `${installmentNumber} X ${pricePerInstallment} R$`,
      };
    });
    setDues(newInstallments);
  }, [purchaseDetail]);

  const emit = useCallback(
    async (cardData) => {
      const result = await paymentProcessHelper.emit(parsedData);
      setReserveData((prev) => ({
        ...prev,
        ...cardData,
        ...{
          reserve_id: result?.reserve_id,
          reserve_status: result?.reserve_status,
        },
      }));
    },
    [parsedData]
  );

  const emitVoucher = useCallback(async () => {
    const success = await paymentProcessHelper.emitVoucher(
      parsedData,
      reserveData
    );

    if (success?.data?.status) {
      // window.location.href = `${basePath}/success/?reserve_id=${reserveData?.reserve_id}&method=cielo`;
      window.location.href = `${basePath}/success?reserve_id=${reserveData?.reserve_id}&method=cielo`;
    } else {
      console.error("emitVoucher Error done", success?.data?.err);
      alert("Error!!");
    }
    console.info("emitVoucher done");
  }, [reserveData, parsedData, basePath]);

  const fetchPay = useCallback(
    async (cardData) => {
      const payData = removeBeneficiaryKeys(reserveData);
      const formData = !isLink ? payData : { ...payData, ...cardData };

      const success = await paymentProcessHelper.fetchPay(formData);

      if (success?.data?.status) {
        await emitVoucher();
      } else {
        console.error("fetchPay Error", success?.data?.err);
        setRequestError(success?.data?.err);
      }
      console.info("fetchPay done");
    },
    [reserveData, isLink]
  );

  const onSubmit = (cardData) => {
    const date = cardData?.card_date_exp.split("/");
    const body = {
      payment_method: "Cielo",
      numCartao: cardData?.card_number,
      anoCartao: date[1],
      mesCartao: date[0],
      ccMarca: cardData?.card_id,
      codCartao: cardData?.card_security_code,
      titular: cardData?.card_owner,
      parcelas: cardData?.dues,
    };

    if (!isLink) {
      emit(body);
    } else {
      fetchPay(body);
    }
  };

  useEffect(() => {
    if (reserveData.reserve_status) {
      fetchPay();
    }
  }, [reserveData]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Card>
          <CardContent>
            <div className="mb-8">
              <h1 className="font-bold text-2xl text-[#006FE8] mb-8">
                {t("steps.payment.title.creditCard")}
              </h1>
              <FormGroup>
                <Controller
                  name={`card_id`}
                  rules={{ required: ERROR_MESSAGES.form.REQUIRED }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      error={!!errors[`card_id`]}
                      helperText={errors[`card_id`]?.message}
                    >
                      <MenuItem value="vi">Visa</MenuItem>
                      <MenuItem value="ma">Mastercard</MenuItem>
                      <MenuItem value="di">Dinners</MenuItem>
                      <MenuItem value="am">Amex</MenuItem>
                    </TextField>
                  )}
                />
              </FormGroup>
            </div>
            <h1 className="font-bold text-2xl text-[#006FE8] mb-8">
              {t("steps.payment.cardDataFormTitle")}
            </h1>
            <FormGroup className="mb-4">
              <label className="font-bold text-xs mb-2 text-[#596E80]">
                {t("form.label.cardNumber")}
              </label>
              <Controller
                name={`card_number`}
                control={control}
                rules={{ required: ERROR_MESSAGES.form.REQUIRED }}
                render={({ field }) => (
                  <InputMask
                    mask="9999-9999-9999-9999"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  >
                    {() => (
                      <TextField
                        fullWidth
                        error={!!errors[`card_number`]}
                        helperText={errors[`card_number`]?.message}
                        placeholder={t("form.placeholder.cardNumber")}
                        InputProps={{
                          startAdornment: <CreditCardIcon />,
                        }}
                      />
                    )}
                  </InputMask>
                )}
              />
            </FormGroup>
            <div className="mb-4 flex justify-between">
              <FormGroup className="w-full mr-6">
                <label className="font-bold text-xs mb-2 text-[#596E80] truncate w-24 sm:w-full">
                  {t("form.label.expiration")}
                </label>
                <Controller
                  name={`card_date_exp`}
                  rules={{ required: ERROR_MESSAGES.form.REQUIRED }}
                  control={control}
                  render={({ field }) => (
                    <InputMask
                      mask="99/99"
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                    >
                      {() => (
                        <TextField
                          fullWidth
                          error={!!errors[`card_date_exp`]}
                          helperText={errors[`card_date_exp`]?.message}
                          placeholder={t("form.placeholder.expiration")}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </FormGroup>
              <FormGroup className="w-full">
                <label className="font-bold text-xs mb-2 text-[#596E80]">
                  {t("form.label.securityCode")}
                </label>
                <Controller
                  name={`card_security_code`}
                  control={control}
                  rules={{ required: ERROR_MESSAGES.form.REQUIRED }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      InputProps={{
                        startAdornment: <LockIcon />,
                      }}
                      error={!!errors[`card_security_code`]}
                      helperText={errors[`card_security_code`]?.message}
                      placeholder={t("form.placeholder.securityCode")}
                    />
                  )}
                />
              </FormGroup>
            </div>
            <FormGroup className="mb-4">
              <label className="font-bold text-xs mb-2 text-[#596E80]">
                {t("form.label.cardholder")}
              </label>
              <Controller
                name={`card_owner`}
                control={control}
                rules={{ required: ERROR_MESSAGES.form.REQUIRED }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!errors[`card_owner`]}
                    helperText={errors[`card_owner`]?.message}
                    placeholder={t("form.placeholder.cardholder")}
                  />
                )}
              />
            </FormGroup>
            <FormGroup className="mb-4">
              <label className="font-bold text-xs mb-2 text-[#596E80]">
                {t("form.label.dues")}
              </label>
              <Controller
                name={`dues`}
                rules={{ required: ERROR_MESSAGES.form.REQUIRED }}
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    {...field}
                    error={!!errors[`dues`]}
                    helperText={errors[`dues`]?.message}
                  >
                    {dues.map((row, index) => (
                      <MenuItem key={index} value={row.value}>
                        {row.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </FormGroup>
            <Alert
              severity="error"
              id="card-errors"
              className={!requestError ? "!hidden" : "flex"}
            >
              {requestError}
            </Alert>
          </CardContent>
        </Card>
      </form>
    </>
  );
};

export default CieloCreditCardForm;
