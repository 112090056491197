import React from "react";
import ArrowLeft from "../../assets/icons/ArrowLeft";
import { Link } from "@mui/material";
const BackButton = ({ label, event }) => {
  return (
    <Link
      className="flex items-center cursor-pointer"
      onClick={event}
      underline="none"
    >
      <ArrowLeft />
      <span className="ml-2 font-semibold text-[#675CE5]">{label}</span>
    </Link>
  );
};

export default BackButton;
