import React from "react";
import "./style.css";

const Stepper = ({ steps, activeStep, isLink }) => {

  const _activeStep = isLink && activeStep === 2 ? 1 : activeStep;

  return (
    <div id="stepper" className="w-full overflow-hidden">
      <div className="w-full sm:hidden">
        <div className={`step flex content-between w-full`}>
          <div className="flex !w-[50%]">
            <div className={`stepCircle activeStepCircle`}>
              {_activeStep + 1}
            </div>
            <div className="flex ml-[16px] items-center">
              Etapa <span className="mr-1 ml-1">{_activeStep + 1}</span> de{" "}
              <span className="ml-1">{steps?.length}</span>
            </div>
          </div>

          <div className={`stepName font-bold !text-right !w-[50%]`}>
            {steps[_activeStep]}
          </div>
        </div>
      </div>
      <div
        className={`${
          !isLink ? "stepperContainer" : "stepperContainerThree"
        } lg:ml-[45px] w-full !hidden sm:!grid sm:mt-4 lg:mt-0`}
      >
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step ${index === _activeStep ? "activeStep" : ""}`}
          >
            <div
              className={`stepCircle ${
                index === _activeStep
                  ? "activeStepCircle"
                  : "inactiveStepCircle"
              }`}
            >
              {index + 1}
            </div>
            <div className="stepName">{step}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
