import React, { useState, useEffect } from "react";
import { useQuote } from "../../context/quoteContext";
import { useTranslation } from "react-i18next";

const CountdownTimer = () => {
  const { t } = useTranslation();
  const { data } = useQuote();

  useEffect(() => {
    if (data?.quote?.assistpay?.countdown) {
      setTimeRemaining(parseInt(data?.quote?.assistpay?.countdown));
    }
  }, [data]);

  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timerInterval);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, []); // The empty dependency array ensures the effect runs only once on mount

  // Convert seconds to hours, minutes, and seconds
  const hours = Math.floor(timeRemaining / 3600);
  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;

  return (
    <div className="ml-6 font-sans text-sm text-white">
      {t("index.assistPay.countDown")}{" "}
      <span className="font-bold block sm:inline-block sm:pl-2"> {`${hours}h ${minutes}m ${seconds}s`}</span>
    </div>
  );
};

export default CountdownTimer;
