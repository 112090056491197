import React from "react";
const ArrowLeft = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70711 17.6085C9.31658 17.999 8.68342 17.999 8.29289 17.6085L2.29289 11.6085C1.90237 11.2179 1.90237 10.5848 2.29289 10.1943L8.29289 4.19426C8.68342 3.80374 9.31658 3.80374 9.70711 4.19426C10.0976 4.58478 10.0976 5.21795 9.70711 5.60847L5.41421 9.90137H17C17.5523 9.90137 18 10.3491 18 10.9014C18 11.4537 17.5523 11.9014 17 11.9014L5.41421 11.9014L9.70711 16.1943C10.0976 16.5848 10.0976 17.2179 9.70711 17.6085Z"
        fill="#675CE5"
      />
    </svg>
  );
};

export default ArrowLeft;
