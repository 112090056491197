import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import CustomerForm from "../../components/CustomerForm";
import AddressForm from "../../components/AddressForm";
import { useForm, Controller } from "react-hook-form";
import AdditionalForm from "../../components/AdditionalForm";
import { useLocation } from "../../context/locationContext";

const BillingData = ({
  onSubmit,
  isValidForm,
  customerFormRef,
  defaultValues,
  upgrades,
  setPurchaseDetail,
  currency_symbol
}) => {
  const { geolocation } = useLocation();
  const { t } = useTranslation();
  const formRef = useRef(null);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    resetField,
  } = useForm({
    defaultValues: defaultValues,
  });

  const handleRenderContent = useCallback(() => {
    // return geolocation?.country === "BR" ? (
    //   <>
    //     <div className="mb-6">
    //       <h3 className="font-bold text-sm text-[#006FE8] mb-5">
    //         {t("steps.billingData.buyerDetails")}
    //       </h3>
    //       <div className="bg-white shadow-lg py-8 px-3 rounded-xl">
    //         <CustomerForm
    //           control={control}
    //           Controller={Controller}
    //           errors={errors}
    //         />
    //       </div>
    //     </div>
    //     <div>
    //       <h3 className="font-bold text-sm text-[#006FE8] mb-5">
    //         {t("steps.billingData.buyerAddress")}
    //       </h3>
    //       <div className="bg-white shadow-lg py-8 px-3 rounded-xl">
    //         <AddressForm
    //           control={control}
    //           Controller={Controller}
    //           errors={errors}
    //           setValue={setValue}
    //           resetField={resetField}
    //         />
    //       </div>
    //     </div>
    //   </>
    // ) : 
    return (
      <div className="clear-both">
        <AdditionalForm
          control={control}
          Controller={Controller}
          errors={errors}
          currency_symbol={currency_symbol}
          upgrades={upgrades}
          setValue={setValue}
          form={formRef}
          setPurchaseDetail={setPurchaseDetail}
          geolocation={geolocation}
        />
      </div>
    );
  }, [
    geolocation,
    control,
    Controller,
    errors,
    setValue,
    resetField,
    upgrades,
    formRef,
  ]);

  useEffect(() => {
    isValidForm(isValid);
  }, [isValid]);

  useEffect(() => {
    customerFormRef(formRef);
  }, [formRef]);

  return (
    <section>
      <h1 className="font-bold text-2xl text-[#006FE8] mb-6">
        {t("steps.billingData.title")}
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        {handleRenderContent()}
      </form>
    </section>
  );
};

export default BillingData;
