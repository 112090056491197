import React from "react";
const Paypal = ({ fill }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="MuiSvgIcon-root"
        d="M15.8237 8.08612C15.2132 10.8578 12.9305 12.4887 10.2 12.4887H7.34719L6.3693 16.9293H2.37586L2.75301 15.2517H4.08789H5.14937L5.37785 14.2155L6.12727 10.8111H7.91855C11.3898 10.8111 14.1031 8.67596 14.8317 5.37288C14.8931 5.0928 14.9343 4.82678 14.9595 4.57065C15.7402 5.31877 16.1795 6.4767 15.8237 8.08612ZM3.04969 13.9305H1.69582H0.09375L3.10992 0.523071H9.91562C11.1755 0.523071 12.5645 1.09311 13.2439 2.30022C13.4779 2.7167 13.6283 3.20807 13.6597 3.77948C13.6818 4.1762 13.6468 4.61237 13.5417 5.08784C13.3034 6.17085 12.807 7.07502 12.1314 7.78026C11.0987 8.85893 9.6423 9.46155 8.0193 9.48545C7.98555 9.48608 7.95238 9.48975 7.91859 9.48975H5.0652L4.08789 13.9305H3.04969ZM5.63582 6.92151H7.51004C7.9259 6.92151 8.33992 6.78448 8.70363 6.556C8.84242 6.46694 8.97578 6.36678 9.09613 6.25311C9.43273 5.93682 9.68645 5.53198 9.79277 5.08784C9.97945 4.42135 9.76316 3.84272 9.31719 3.52264C9.08258 3.35436 8.7859 3.25479 8.44805 3.25479H6.49215L6.42945 3.52264L5.63582 6.92151Z"
        fill="none"
      />
    </svg>
  );
};

export default Paypal;
