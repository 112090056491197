import React from "react";
const Technology = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_650_25230)">
        <path
          d="M10.4766 5.10705C10.4766 4.03928 11.3484 3.16748 12.4161 3.16748H27.9352C29.003 3.16748 29.8723 4.03928 29.8723 5.10705V35.0347C29.8723 36.1025 29.003 36.9743 27.9352 36.9743H12.4161C11.3484 36.9743 10.4766 36.1025 10.4766 35.0347V5.10705Z"
          fill="white"
        />
        <path
          d="M11.4453 6.33542C11.4453 5.32293 12.2744 4.49384 13.2894 4.49384H28.0322C29.0472 4.49384 29.8737 5.32293 29.8737 6.33542V34.7682C29.8737 35.7832 29.0472 36.6123 28.0322 36.6123H13.2894C12.2744 36.6123 11.4453 35.7832 11.4453 34.7682V6.33542Z"
          fill="#57B7FF"
        />
        <path
          d="M27.9351 37.3336H12.416C11.1473 37.3336 10.1172 36.3036 10.1172 35.0348V5.10713C10.1172 3.84088 11.1473 2.80829 12.416 2.80829H27.9351C29.2013 2.80829 30.2314 3.83837 30.2314 5.10713V35.0348C30.2314 36.301 29.2013 37.3336 27.9351 37.3336ZM12.416 3.52432C11.5442 3.52432 10.8332 4.23282 10.8332 5.10462V35.0323C10.8332 35.9041 11.5417 36.6126 12.416 36.6126H27.9351C28.8044 36.6126 29.5129 35.9041 29.5129 35.0323V5.10713C29.5129 4.23533 28.8044 3.52683 27.9351 3.52683H12.416V3.52432Z"
          fill="#31319B"
        />
        <path
          d="M20.1812 32.4796C19.4576 32.4796 18.8672 33.0649 18.8672 33.791C18.8672 34.5171 19.4551 35.1025 20.1812 35.1025C20.9072 35.1025 21.4951 34.5146 21.4951 33.791C21.4951 33.0675 20.9047 32.4796 20.1812 32.4796Z"
          fill="white"
        />
        <path
          d="M20.6627 33.0272C19.9743 33.0272 19.4141 33.5824 19.4141 34.2733C19.4141 34.9642 19.9743 35.5195 20.6627 35.5195C21.3511 35.5195 21.9114 34.9592 21.9114 34.2733C21.9114 33.5874 21.3511 33.0272 20.6627 33.0272Z"
          fill="#57B7FF"
        />
        <path
          d="M20.1811 35.4593C19.259 35.4593 18.5078 34.7106 18.5078 33.791C18.5078 32.8715 19.2565 32.1228 20.1811 32.1228C21.1056 32.1228 21.8543 32.8715 21.8543 33.791C21.8543 34.7106 21.1031 35.4593 20.1811 35.4593ZM20.1811 32.8388C19.6535 32.8388 19.2264 33.2659 19.2264 33.791C19.2264 34.3161 19.6535 34.7432 20.1811 34.7432C20.7087 34.7432 21.1358 34.3161 21.1358 33.791C21.1358 33.2659 20.7062 32.8388 20.1811 32.8388Z"
          fill="#31319B"
        />
        <path d="M28.1275 7.78546H12.2266V30.5H28.1275V7.78546Z" fill="white" />
        <path
          d="M28.2164 8.83557H13.1094V30.4145H28.2164V8.83557Z"
          fill="#57B7FF"
        />
        <path
          d="M28.1274 30.859H12.2265C12.028 30.859 11.8672 30.6982 11.8672 30.4997V7.78512C11.8672 7.58664 12.028 7.42584 12.2265 7.42584H28.1274C28.3259 7.42584 28.4867 7.58664 28.4867 7.78512V30.5022C28.4867 30.7007 28.3259 30.8615 28.1274 30.8615V30.859ZM12.5832 30.1429H27.7682V8.14439H12.5832V30.1429Z"
          fill="#31319B"
        />
        <path
          d="M22.941 6.0142H21.8732C21.6747 6.0142 21.5139 5.85341 21.5139 5.65493C21.5139 5.45645 21.6747 5.29565 21.8732 5.29565H22.941C23.1394 5.29565 23.3002 5.45645 23.3002 5.65493C23.3002 5.85341 23.1394 6.0142 22.941 6.0142ZM21.1044 6.0142H17.4061C17.2077 6.0142 17.0469 5.85341 17.0469 5.65493C17.0469 5.45645 17.2077 5.29565 17.4061 5.29565H21.1044C21.3029 5.29565 21.4637 5.45645 21.4637 5.65493C21.4637 5.85341 21.3029 6.0142 21.1044 6.0142Z"
          fill="#31319B"
        />
        <path
          d="M20.1742 13.8604C23.0911 13.8604 25.4578 16.2246 25.4578 19.144C25.4578 22.0634 23.0911 24.4225 20.1742 24.4225C17.2573 24.4225 14.8906 22.0584 14.8906 19.144C14.8906 16.2296 17.2548 13.8604 20.1742 13.8604Z"
          fill="white"
        />
        <path
          d="M20.6604 14.6063C23.4316 14.6063 25.6802 16.8523 25.6802 19.626C25.6802 22.3997 23.4316 24.6408 20.6604 24.6408C17.8892 24.6408 15.6406 22.3947 15.6406 19.626C15.6406 16.8574 17.8867 14.6063 20.6604 14.6063Z"
          fill="#57B7FF"
        />
        <path
          d="M20.1794 24.7793C17.069 24.7793 14.5391 22.2518 14.5391 19.144C14.5391 16.0362 17.069 13.5012 20.1794 13.5012C23.2897 13.5012 25.8222 16.0311 25.8222 19.144C25.8222 22.2569 23.2923 24.7793 20.1794 24.7793ZM20.1794 14.2197C17.4635 14.2197 15.2551 16.4281 15.2551 19.144C15.2551 21.8599 17.4635 24.0633 20.1794 24.0633C22.8953 24.0633 25.1037 21.8574 25.1037 19.144C25.1037 16.4306 22.8953 14.2197 20.1794 14.2197Z"
          fill="#31319B"
        />
        <path
          d="M20.1794 15.9807L22.4682 21.5331L20.187 20.3297L17.8906 21.5331L20.1794 15.9807Z"
          fill="white"
        />
        <path
          d="M20.6576 16.6039L22.8308 21.8775L20.6651 20.7343L18.4844 21.8775L20.6576 16.6039Z"
          fill="#57B7FF"
        />
        <path
          d="M17.8898 21.8924C17.8018 21.8924 17.7164 21.8597 17.6486 21.7994C17.538 21.6964 17.5004 21.5381 17.5581 21.3974L19.8469 15.8451C19.9022 15.7094 20.0329 15.624 20.1786 15.624C20.3243 15.624 20.4549 15.7119 20.5102 15.8451L22.799 21.3974C22.8568 21.5381 22.8216 21.6989 22.7086 21.7994C22.5955 21.8999 22.4322 21.9225 22.299 21.8522L20.1861 20.7367L18.0556 21.8522C18.0028 21.8798 17.9451 21.8924 17.8898 21.8924ZM20.1786 16.9229L18.5983 20.7568L20.0203 20.0106C20.1258 19.9553 20.2514 19.9553 20.3544 20.0106L21.7564 20.7518L20.1786 16.9204V16.9229Z"
          fill="#31319B"
        />
        <path
          d="M33.5589 39.0596H10.578C10.3795 39.0596 10.2188 38.8988 10.2188 38.7003C10.2188 38.5019 10.3795 38.3411 10.578 38.3411H33.5589C33.7574 38.3411 33.9182 38.5019 33.9182 38.7003C33.9182 38.8988 33.7574 39.0596 33.5589 39.0596Z"
          fill="#31319B"
        />
        <path
          d="M9.86296 39.0596H8.03896C7.84048 39.0596 7.67969 38.8988 7.67969 38.7003C7.67969 38.5019 7.84048 38.3411 8.03896 38.3411H9.86296C10.0614 38.3411 10.2222 38.5019 10.2222 38.7003C10.2222 38.8988 10.0614 39.0596 9.86296 39.0596Z"
          fill="#31319B"
        />
        <path
          d="M7.37394 39.0596H6.49208C6.2936 39.0596 6.13281 38.8988 6.13281 38.7003C6.13281 38.5019 6.2936 38.3411 6.49208 38.3411H7.37394C7.57242 38.3411 7.73321 38.5019 7.73321 38.7003C7.73321 38.8988 7.57242 39.0596 7.37394 39.0596Z"
          fill="#31319B"
        />
        <path
          d="M26.9571 40.7707H10.1015C9.90298 40.7707 9.74219 40.6099 9.74219 40.4114C9.74219 40.2129 9.90298 40.0521 10.1015 40.0521H26.9571C27.1556 40.0521 27.3164 40.2129 27.3164 40.4114C27.3164 40.6099 27.1556 40.7707 26.9571 40.7707Z"
          fill="#31319B"
        />
        <path
          d="M28.8156 40.7707H27.4765C27.278 40.7707 27.1172 40.6099 27.1172 40.4114C27.1172 40.2129 27.278 40.0521 27.4765 40.0521H28.8156C29.014 40.0521 29.1748 40.2129 29.1748 40.4114C29.1748 40.6099 29.014 40.7707 28.8156 40.7707Z"
          fill="#31319B"
        />
        <path
          d="M29.9503 40.7707H29.3046C29.1061 40.7707 28.9453 40.6099 28.9453 40.4114C28.9453 40.2129 29.1061 40.0521 29.3046 40.0521H29.9503C30.1488 40.0521 30.3095 40.2129 30.3095 40.4114C30.3095 40.6099 30.1488 40.7707 29.9503 40.7707Z"
          fill="#31319B"
        />
      </g>
      <defs>
        <clipPath id="clip0_650_25230">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.0234375 0.77063)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Technology;
