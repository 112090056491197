import React from "react";
const Pregnant = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4102 3.01024L19.9818 3.11276C20.8857 3.27487 21.6986 3.76334 22.266 4.48538C22.521 4.80988 22.7217 5.17745 22.8579 5.56704C23.1624 6.43833 23.1431 7.39779 22.7988 8.25417L22.6511 8.62173C22.0814 9.88888 21.0283 10.8754 19.7267 11.3612L19.2563 11.5368C19.1728 11.568 19.0927 11.6074 19.0172 11.6545C18.2576 12.1283 18.1723 13.2014 18.8474 13.7893L18.9467 13.8757C19.014 13.9344 19.0758 13.9992 19.131 14.0694L21.3851 16.9318L23.36 20.1065C23.729 20.6997 24.191 21.2297 24.7282 21.6762C25.6006 22.4013 26.2687 23.3416 26.6663 24.4041L27.2855 26.0584C27.6882 27.1344 27.5863 28.3345 27.0079 29.3272C26.6918 29.8697 26.2472 30.3261 25.7132 30.6562L24.9242 31.1439C24.6196 31.3323 24.2685 31.432 23.9103 31.432C23.1452 31.432 22.4524 31.8844 22.1448 32.585L21.6495 33.7129C21.4751 34.1101 21.3851 34.5391 21.3851 34.9728V35.084C21.3851 36.86 19.8652 38.2556 18.0957 38.1045C17.0526 38.0155 16.1293 37.3946 15.6533 36.4622L15.6395 36.435L14.3195 34.3278C14.0315 33.868 13.7848 33.3836 13.5825 32.8802L13.1238 31.739C12.7326 30.7657 12.645 29.6969 12.8726 28.673C13.0276 27.9759 13.3245 27.3183 13.7448 26.7411L13.8559 26.5886C14.9795 25.0457 15.3166 23.066 14.7668 21.2382L14.5683 20.5781L13.5672 17.4981C13.2843 16.6276 13.5042 15.672 14.1392 15.0128L14.9857 14.1339C15.3603 13.745 15.3901 13.1393 15.0555 12.7155C14.7651 12.3477 14.2706 12.2091 13.8314 12.3726L12.4613 12.8823C12.2563 12.9585 12.0272 12.931 11.8462 12.8083C11.6606 12.6825 11.5494 12.4729 11.5494 12.2486V11.5831C11.5494 11.4185 11.5704 11.2546 11.612 11.0954L12.2583 8.61929C12.4342 7.94532 12.6957 7.29666 13.0364 6.68914L13.4845 5.89029C14.1822 4.6463 15.3158 3.70475 16.6668 3.24725L16.7885 3.20603C17.6318 2.92042 18.5338 2.85306 19.4102 3.01024Z"
        fill="#57B7FF"
      />
      <path
        d="M15.7034 38.9844C15.7347 38.9844 15.7668 38.9827 15.7988 38.9788C16.1649 38.9332 16.4191 38.6419 16.3669 38.327L16.0576 36.471C16.0472 36.4108 16.0263 36.3524 15.9949 36.2978L12.9117 30.9251C12.5855 30.1997 12.6221 29.3285 13.0365 28.5816L14.953 25.1274C15.3779 24.3602 15.4655 23.489 15.1994 22.673C14.5595 20.7063 13.6144 17.4393 13.581 16.6766C13.5549 16.0729 14.6608 15.1359 15.455 14.6638C15.4903 14.6424 15.5243 14.618 15.5544 14.5912C15.5707 14.5778 16.1256 14.1318 17.0479 14.2991C18.1611 14.5032 20.4423 15.7315 23.2118 21.6675C23.2491 21.7473 23.3066 21.8193 23.3798 21.8772L24.8943 23.0746C25.7113 23.7208 26.3232 24.4125 26.7127 25.1307C26.9337 25.539 27.0984 25.9563 27.2023 26.3697C27.3886 27.107 27.3873 27.7819 27.1997 28.3758C26.9323 29.2216 26.2761 29.9359 25.2492 30.4988C23.789 31.2997 22.0954 31.5061 22.0784 31.5078C21.7124 31.5511 21.4555 31.8408 21.5051 32.1557C21.5542 32.4706 21.8889 32.6911 22.2569 32.6495C22.3372 32.6399 24.2517 32.4116 25.9682 31.4707C27.2847 30.7486 28.1337 29.8093 28.4913 28.6784C28.7358 27.904 28.7423 27.0457 28.5109 26.1261C28.3861 25.6295 28.1893 25.1307 27.9259 24.6442C27.4625 23.7883 26.7474 22.975 25.8002 22.2265L24.3968 21.1174C22.1091 16.2535 19.7135 13.5778 17.2754 13.1636C15.8374 12.9198 14.8942 13.5544 14.6536 13.7401C14.2569 13.9811 12.1829 15.3092 12.2436 16.7199C12.2868 17.7177 13.3856 21.371 13.9104 22.984C14.0889 23.5318 14.0301 24.1167 13.7451 24.6313L11.8286 28.0856C11.2553 29.1187 11.2044 30.3228 11.688 31.3891L14.7477 36.7252L15.0412 38.4901C15.0896 38.7774 15.3759 38.9844 15.7034 38.9844Z"
        fill="#313195"
      />
      <path
        d="M21.7395 38.9841C22.0964 38.9841 22.3931 38.7411 22.4075 38.4312L22.6166 33.9781C22.6317 33.6603 22.3441 33.3927 21.9748 33.3797C21.6087 33.3493 21.2944 33.6143 21.2793 33.932L21.0701 38.3851C21.0551 38.7029 21.3427 38.9705 21.712 38.9835C21.7212 38.9841 21.7303 38.9841 21.7395 38.9841Z"
        fill="#313195"
      />
      <path
        d="M17.9759 11.8063C20.0307 12.1461 22.2328 10.8849 23.0561 8.81164C23.6585 7.27974 23.3573 5.70177 22.4002 4.65363C21.9651 4.16987 21.3895 3.8013 20.7001 3.59973"
        stroke="#313195"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.3984 4.64844V4.6542C22.3784 4.85576 21.9768 8.61064 15.7118 7.5625"
        stroke="#313195"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7031 3.60009C20.7031 3.60009 15.2815 1.54412 13.1263 5.89794C11.3124 9.57219 11.5132 12.3077 11.6203 13.0737C11.6404 13.1831 11.7742 13.258 11.8947 13.2176C12.7247 12.9585 15.4355 12.0486 15.7835 11.1617L15.7099 7.56229V7.55653"
        stroke="#313195"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.0895 29.0407C23.5648 29.0407 24.0818 29.0098 24.6465 28.944C25.0133 28.9013 25.2701 28.6111 25.2204 28.2961C25.1708 27.9811 24.8335 27.759 24.4674 27.8023C20.6704 28.2461 19.6644 26.9525 18.6493 25.0792C17.1002 21.7728 17.3623 17.359 17.3649 17.3146C17.3858 16.9974 17.1028 16.7258 16.7335 16.7083C16.374 16.6763 16.0492 16.9333 16.0289 17.2516C16.0164 17.4423 15.7478 21.9679 17.4218 25.5381C18.4304 27.4002 19.6376 29.0407 23.0895 29.0407Z"
        fill="#313195"
      />
    </svg>
  );
};

export default Pregnant;
