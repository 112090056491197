import React, { createContext, useContext, useEffect, useState } from "react";
import { paymentMethodsFetchController } from "../dataController/dataController";
import { useLocation } from "./locationContext";
import { useQuote } from "./../context/quoteContext";

const PaymentMethodContext = createContext();
export const usePaymentMethod = () => {
  return useContext(PaymentMethodContext);
};
export const PaymentMethodProvider = ({ children }) => {
  const { geolocation } = useLocation();
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethodLink, setPaymentMethodLink] = useState(null);
  const [installments, setInstallments] = useState(null);
  const [rawInstallments, setRawInstallments] = useState(null);

  const { data, isLink } = useQuote();

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState({
    status: false,
  });

  useEffect(() => {
    let country = null;

    const fetchData = async () => {
      if (isLink && data?.quote.from_id) {
        country = data.quote.from_id;
      } else {
        // country = localStorage.getItem("country_id") ?? geolocation?.country_id;
        if (geolocation) {
          country = geolocation?.country_id;
        }
      }

      if (country) {
        try {
          const result = await paymentMethodsFetchController.fetchData(country);

          let total_installments = 1;

          if (country == "164") {
            total_installments = result.data.installments.Decidir;
            total_installments = total_installments
              .slice(1, -1)
              .split(",")
              [total_installments.slice(1, -1).split(",").length - 1].split(
                ":"
              )[1];
            setRawInstallments(result.data.installments.Decidir.slice(1, -1));
          }

          if (country == "165") {
            total_installments = result.data.installments.Dlocal
              ? result.data.installments.Dlocal
              : "{11:11}";
            // total_installments = '"{12:12}"';

            total_installments = total_installments
              .slice(1, -1)
              .split(",")
              [total_installments.slice(1, -1).split(",").length - 1].split(
                ":"
              )[1];
            ``;

            setRawInstallments(result.data?.installments?.Dlocal?.slice(1, -1));
          }

          // 155=BR PIX

          if (country == "155") {
            result.data.methods["Pix"] = "";
          }

          // 158=CO 165=CL REBILL

          if (country == "158" || country == "165") {
            result.data.methods["Rebill"] = "";
          }

          // 52=MX 165=CL DLOCAL MERCADO PAGO

          if (country == "52" || country == "165") {
            result.data.methods["DlocalMP"] = "";
          }

          // SET TOTAL INSTALLMENTS

          if (total_installments) {
            setInstallments(total_installments);
          }

          setAvailablePaymentMethods(result.data);
        } catch (error) {
          console.warn(error);
        } finally {
          // console.warn("done");
        }
      }
    };
    fetchData();
  }, [geolocation, isLink]);

  const contextValue = {
    paymentMethod,
    setPaymentMethod,
    availablePaymentMethods,
    setPaymentMethodLink,
    paymentMethodLink,
    installments,
    rawInstallments,
  };

  return (
    <PaymentMethodContext.Provider value={contextValue}>
      {children}
    </PaymentMethodContext.Provider>
  );
};
