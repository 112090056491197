import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Chubb from "../../components/Chubb";
import { Link } from "@mui/material";
import PassengerFormBr from "../../components/PassengerForm/pt-BR";
import { useForm, Controller } from "react-hook-form";
import ContactForm from "../../components/ContactForm";
import PolicyForm from "../../components/PolicyForm";
import PassengerForm from "../../components/PassengerForm/es-AR";
import Typography from "@mui/material/Typography";
import DiscountCode from "../../components/DiscountCode";
import { useLocation } from "../../context/locationContext";

const PersonalData = ({
  total_pax = 0,
  onSubmit,
  isValidForm,
  passengerFormRef,
  defaultValues,
  setPurchaseDetail,
  countries,
  setPricingStatus,
  setException,
  exception,
  setActionButtonEnabled,
  setActionButtonAvailable,
}) => {
  const { t } = useTranslation();
  const { geolocation } = useLocation();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    getValues,
    resetField,
    setError,
    clearErrors,
    getFieldState,
    trigger,
  } = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const formRef = useRef(null);

  const passengerForms = useCallback(() => {

    const forms = Array.from({ length: total_pax }, (_, i) => (
      <div className="mb-8" key={i + 1}>
        <Chubb
          summary={
            <Typography fontSize={20} fontWeight={600} color="#1B2225">
              {`${t("steps.personalData.passenger")} ${i + 1}`}
            </Typography>
          }
        >
          {geolocation?.country ? (
            <PassengerForm
              index={i}
              trigger={trigger}
              setActionButtonEnabled={setActionButtonEnabled}
              setActionButtonAvailable={setActionButtonAvailable}
              total_pax={total_pax}
              control={control}
              Controller={Controller}
              errors={errors}
              getFieldState={getFieldState}
              setError={setError}
              clearErrors={clearErrors}
              setPricingStatus={setPricingStatus}
              setException={setException}
              exception={exception}
              setValue={setValue}
              getValues={getValues}
              resetField={resetField}
              countries={countries}
              form={formRef}
              setPurchaseDetail={setPurchaseDetail}
              isValid={isValid}
              isValidForm={isValidForm}
            />
          ) : (
            <h1>BR</h1>
            // <PassengerFormBr
            //   index={i}
            //   control={control}
            //   Controller={Controller}
            //   errors={errors}
            //   setValue={setValue}
            //   totalPax={total_pax}
            //   form={formRef}
            //   setPurchaseDetail={setPurchaseDetail}
            // />
          )}
        </Chubb>
      </div>
    ));

    return <>{forms}</>;
  }, [total_pax, control, Controller, errors, isValid, geolocation, countries]);

  const contactForm = useCallback(() => {
    return geolocation?.country ? (
      <div className="mb-12">
        <h1 className="font-bold text-2xl text-[#006FE8] mb-2">
          {t("steps.personalData.emergencyContact")}
        </h1>
        <div className="mb-5">
          {/* <Link href="#" className="text-sm text-[#2F6DE0] underline">
            {t("steps.personalData.WhyDoWeAskForThis")}
          </Link> */}
        </div>
        <Chubb
          summary={
            <Typography fontSize={20} fontWeight={600} color="#1B2225">
              {t("steps.personalData.contactData")}
            </Typography>
          }
          defaultExpanded={false}
        >
          <ContactForm
            control={control}
            Controller={Controller}
            errors={errors}
          />
        </Chubb>
      </div>
    ) : null;
  }, [geolocation, control, Controller, errors, isValid]);

  useEffect(() => {
    isValidForm(isValid);
  }, [isValid, errors]);

  useEffect(() => {
    passengerFormRef(formRef);
  }, [formRef]);

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef} className="mb-12">
        <div className="mb-5">
          <h1 className="font-bold text-2xl text-[#006FE8] mb-5">
            {t("steps.personalData.title")}
          </h1>
          {passengerForms()}
        </div>
        <>{contactForm()}</>
        <div>
          <h1 className="font-bold text-2xl text-[#006FE8] mb-5">
            {t("steps.personalData.whereToSendPolicy")}
          </h1>
          <PolicyForm
            control={control}
            Controller={Controller}
            errors={errors}
          />
        </div>
      </form>
      <div>
        <DiscountCode
          setPurchaseDetail={setPurchaseDetail}
          control={control}
          Controller={Controller}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
        />
      </div>
    </section>
  );
};

export default PersonalData;
