import React from 'react';

const Notification = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="101" height="98" viewBox="0 0 101 98" fill="none">
            <path d="M0.5 0.191406H100.5V97.808H0.5V0.191406Z" fill="white" fill-opacity="0.01" />
            <path d="M50.5026 89.6734C62.0084 89.6734 72.4251 85.1208 79.9653 77.7603C87.5055 70.3998 92.1693 60.2314 92.1693 48.9998C92.1693 37.7682 87.5055 27.5998 79.9653 20.2392C72.4251 12.8788 62.0084 8.32623 50.5026 8.32623C38.9968 8.32623 28.5801 12.8788 21.0398 20.2392C13.4996 27.5998 8.83594 37.7682 8.83594 48.9998C8.83594 60.2314 13.4996 70.3998 21.0398 77.7603C28.5801 85.1208 38.9968 89.6734 50.5026 89.6734Z" fill="white" fill-opacity="0.74" stroke="#4C64D6" stroke-width="7.33456" stroke-linejoin="round" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M50.5013 75.4376C53.3778 75.4376 55.7096 73.1613 55.7096 70.3534C55.7096 67.5455 53.3778 65.2692 50.5013 65.2692C47.6248 65.2692 45.293 67.5455 45.293 70.3534C45.293 73.1613 47.6248 75.4376 50.5013 75.4376Z" fill="#4C64D6" />
            <path d="M50.5 24.5953V57.1342" stroke="#4C64D6" stroke-width="7.33456" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Notification;
