
const IconInfo = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="101" height="99" viewBox="0 0 101 99" fill="none">
  <path d="M0.5 0.69165H100.5V98.3083H0.5V0.69165Z" fill="white" fill-opacity="0.01"/>
  <path d="M50.5006 90.1736C62.0065 90.1736 72.4232 85.621 79.9634 78.2605C87.5036 70.9 92.1673 60.7316 92.1673 49.5C92.1673 38.2684 87.5036 28.1 79.9634 20.7394C72.4232 13.379 62.0065 8.82642 50.5006 8.82642C38.9948 8.82642 28.5781 13.379 21.0379 20.7394C13.4977 28.1 8.83398 38.2684 8.83398 49.5C8.83398 60.7316 13.4977 70.9 21.0379 78.2605C28.5781 85.621 38.9948 90.1736 50.5006 90.1736Z" fill="white" fill-opacity="0.74" stroke="#4C64D6" stroke-width="7.33456" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M50.5003 75.9379C53.3768 75.9379 55.7087 73.6616 55.7087 70.8537C55.7087 68.0458 53.3768 65.7695 50.5003 65.7695C47.6239 65.7695 45.292 68.0458 45.292 70.8537C45.292 73.6616 47.6239 75.9379 50.5003 75.9379Z" fill="#4C64D6"/>
  <path d="M50.499 25.0955V57.6343" stroke="#4C64D6" stroke-width="7.33456" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default IconInfo;