// sentryConfig.js
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://ac6d1e7c8642d5cdbd19051e89a4c539@o1194355.ingest.sentry.io/4506560802324480",
  integrations: [
    new Sentry.BrowserTracing({
      // Configura tus URLs para el tracing
      tracePropagationTargets: ["localhost", /^https:\/\/app.assist-365\.com\/api/,/^https:\/\/assist-365\.com/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default Sentry;
