import React, { createContext, useContext, useEffect, useState } from "react";
import { geolocationFetchController } from "../dataController/dataController";

const LocationContext = createContext();

export const useLocation = () => {
  return useContext(LocationContext);
};

const getPathFromUrl = (url) => {
  const id = url.split("/")[3];

  const data = {
    transactionId: !isNaN(id) ? Number(id) : null,
    isCongrats: id === "success",
    isAgeException: id === "age-exception",
  };

  try {
    if (id === "age-exception") {
      const transactionId = getParamFromUrl(
        window.location.href,
        "transaction_id"
      );
      if (transactionId) {
        data.transactionId = Number(transactionId);
      }
    }
  } catch (error) {
    console.error(error);
  }

  return data;
};

const getFormParam = (url) => {
  const currentUrl = new URL(url);
  const formParam = currentUrl.searchParams.get("form");
  return formParam ? JSON.parse(formParam.toLowerCase()) : false;
};

const getMethodParam = (url) => {
  const currentUrl = new URL(url);
  return currentUrl.searchParams.get("method") || "";
};

const getIdParam = (url) => {
  const currentUrl = new URL(url);
  return currentUrl.searchParams.get("id") || "";
};

const getParamFromUrl = (url, param) => {
  const currentUrl = new URL(url);
  return currentUrl.searchParams.get(param) || "";
};

export const LocationProvider = ({ children }) => {
  const url = window.location;
  const isBrLocal = url.hostname === "assist-365.com.br" ? "BR" : null;
  //const isBrLocal = "AR";

  const [geolocation, setGeoLocation] = useState(null);

  // const basePath = geolocation?.country
  //   ? `/${geolocation?.country.toLowerCase()}/checkout`
  //   : null;

  const currentGEO = location.pathname.split("/")[1];
  const basePath = `/${currentGEO}/checkout`;

  const getGeoUrl = () => {
    const geoUrl = window.location.pathname.split("/")[1];
    return geoUrl;
  };

  const contextValue = {
    ...getPathFromUrl(url.pathname),
    form: getFormParam(url.href),
    method: getMethodParam(url.href),
    id: getIdParam(url.href),
    geoUrl: getGeoUrl(),
    geolocation,
    basePath,
    getParamFromUrl: (param) => getParamFromUrl(url.href, param),
    url,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const country_code = window.localStorage.getItem("country_code");
        // const result = await geolocationFetchController.fetchData(isBrLocal);
        const result = await geolocationFetchController.fetchData(country_code);
        setGeoLocation(result.data);
      } catch (error) {
        console.warn(error);
      } finally {
        // console.warn("done");
      }
    };

    fetchData();
  }, []);

  return (
    <LocationContext.Provider value={contextValue}>
      {children}
    </LocationContext.Provider>
  );
};
