import React, { useCallback, useEffect, useState } from "react";
import { Alert, Card, CardContent, FormGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "../../context/locationContext";
import { dlocalCountryReferenceFetchController } from "../../dataController/dataController";
import { useFormData } from "../../context/formContext";

import "./style.css";
import { usePaymentMethod } from "../../context/paymentMethodContext";
import * as paymentProcessHelper from "../../helpers/paymentProcessHelper";
import { mapBillingData } from "../../helpers/dataMapper";
import { useCountries } from "../../context/countriesContext";
import { removeBeneficiaryKeys } from "../../helpers/sanitize";
import { useQuote } from "../../context/quoteContext";
import { useLoader } from "../../context/loaderContext";
import WhatsappSupport from "../WhatsappSupport/WhatsappSupport";

const DlocalCreditCardForm = ({ purchaseDetail, formRef }) => {
  const { t } = useTranslation();
  const [countryReference, setCountryReference] = useState(null);
  const { geolocation } = useLocation();
  const { parsedData } = useFormData();
  const { data, isLink } = useQuote();
  const { availablePaymentMethods } = usePaymentMethod();
  const minLength = countryReference?.document_format_min;
  const maxLength = countryReference?.document_format_max;
  const documentType = countryReference?.document_name;
  const [reserveData, setReserveData] = useState(parsedData);
  const { countries } = useCountries();
  const [billingState, setBillingState] = useState(false);
  const { basePath } = useLocation();
  const [token, setToken] = useState(null);
  const [requestError, setRequestError] = useState(null);
  const { setLoader } = useLoader();

  const locale = countryReference?.country_code === "BR" ? "pt" : "es";
  const country = countryReference?.country_code;
  const currency = countryReference?.currency_code;
  const total_amount = purchaseDetail?.total_price;
  const currency_symbol =
    countryReference?.country_code === "BR"
      ? "R$"
      : countryReference?.currency_code;

  const userCountry = countries.find(
    (country) => country.id === reserveData.country
  );

  const billingData = reserveData?.reserve_id
    ? mapBillingData(reserveData, userCountry, "Dlocal")
    : null;

  const dlocalInit = dlocal(availablePaymentMethods?.methods?.Dlocal) || null;

  const form = document.getElementById("dlocal-form");

  const style = {
    base: {
      fontSize: "16px",
      fontFamily: "'Inter UI medium', sans-serif",
      lineHeight: "22px",
      height: "44px",
      fontSmoothing: "antialiased",
      fontWeight: "400",
      color: "#1B2225",
      "::placeholder": {
        color: "#B6BBBF",
        fontSize: "16px",
        fontWeight: "400",
        fontStyle: "italic",
      },
      iconColor: "#adbfd3",
    },
  };

  const buildInstallments = (
    installmentsInput,
    installmentsPlan,
    currencySymbol = currency_symbol
  ) => {
    installmentsInput.innerHTML = "";
    installmentsInput.disabled = false;

    installmentsPlan.installments.forEach((plan) => {
      const option = document.createElement("option");
      option.value = plan.id;
      option.textContent = `${plan.installments} of ${currencySymbol} ${plan.installment_amount} (Total : ${currencySymbol} ${plan.total_amount})`;
      installmentsInput.appendChild(option);
    });
  };

  const emit = useCallback(async () => {
    const result = await paymentProcessHelper.emit(parsedData);

    setReserveData((prev) => ({
      ...prev,
      ...{
        reserve_id: result?.reserve_id,
        reserve_status: result?.reserve_status,
      },
    }));
  }, [parsedData]);

  const fetchBilling = useCallback(async () => {
    const result = await paymentProcessHelper.fetchBilling(billingData);
    // console.log(" fetchBilling result", result);
    if (result.status) {
      setBillingState(result.status);
    } else {
      setBillingState(false);
      setLoader(false);
    }
    console.info("fetchBilling done");
  }, [billingData]);

  const emitVoucher = useCallback(async () => {
    const success = await paymentProcessHelper.emitVoucher(
      parsedData,
      reserveData
    );

    if (success?.data?.status) {
      // window.location.href = `${basePath}/success/?reserve_id=${reserveData?.reserve_id}&method=dlocal`;
      window.location.href = `${basePath}/success?reserve_id=${reserveData?.reserve_id}&method=dlocal`;
    } else {
      console.error("emitVoucher error", success?.data?.err);
      alert("Emit Voucher Error!!");
    }
    console.log("emitVoucher done");
  }, [reserveData, parsedData, basePath]);

  const fetchPay = useCallback(
    async (tokenString) => {
      const payData = removeBeneficiaryKeys(reserveData);
      const installmentsCount = document.getElementById("installments");
      const payer_name = document.getElementById("payer_name").value;
      const payer_document = document.getElementById("payer_document").value;
      const email = document.getElementById("email").value;

      const currency_geo =
        localStorage.getItem("country_id") ?? geolocation?.country_id;

      const success = await paymentProcessHelper.fetchPay({
        ...payData,
        ...{
          method: "Dlocal",
          transaction_amount: total_amount,
          currency_code: currency,
          country_code: country,
          installment: installmentsCount.value,
          token: !isLink ? token : tokenString,
          reserve_id: reserveData?.reserve_id,
          payer_email: email,
          payer_name: payer_name,
          payer_document: payer_document,
          currency_geo: currency_geo,
        },
      });

      if (success?.data?.status) {
        await emitVoucher();
      } else {
        console.error("fetchPay done", success?.data?.err);
        setLoader(false);

        const dLocal_backend_error = [
          {
            translation: "anErrorOccurred",
            text: "Ocurrió un error, intento de nuevo más tarde por favor.",
          },
          {
            translation: "insufficientAmount",
            text: "Monto insuficiente",
          },
          {
            translation: "blockedCard",
            text: "Su tarjeta esta deshabilitada",
          },
          {
            translation: "notApproved",
            text: "Su pago fue rechazado, comuníquese con su banco emisor",
          },
        ];

        try {
          const msg_error = dLocal_backend_error.find(
            (err) => success.data.err === err.text
          );
          const errorMsg = msg_error
            ? t(`form.error.dlocal.${msg_error.translation}`)
            : success?.data?.err;
          setRequestError(errorMsg);
        } catch (error) {
          console.error("error", error);
        }
      }
      console.log("fetchPay done");
    },
    [reserveData, locale, country, currency, total_amount, token, isLink]
  );

  const init = (country, locale, currency, totalAmount) => {
    const fields = dlocalInit.fields({
      locale,
      country,
    });
    const displayError = (display, message) => {
      const errorWrapper = document.getElementById("card-errors");
      errorWrapper.style = display
        ? "display: flex !important"
        : "display: none !important";

      const errorMessage = errorWrapper.querySelector(".MuiAlert-message");
      errorMessage.textContent = message;
    };

    const cardField = fields.create("pan", { style });
    cardField.mount(document.getElementById("pan-field"));

    const cvvField = fields.create("cvv", { style });
    cvvField.mount(document.getElementById("cvv-field"));

    const expField = fields.create("expiration", { style });
    expField.mount(document.getElementById("exp-field"));

    cardField.addEventListener("change", function (event) {
      if (event.error) {
        displayError(true, event.error.message);
      } else {
        displayError(false, "");
      }
    });

    cardField.on("brand", function (event) {

      const _totalAmount = totalAmount.replace(/[.]/g, '');
      
      if (event.brand) {
        dlocalInit
          .createInstallmentsPlan(cardField, _totalAmount, currency)
          .then((result) => {
            const installmentsSelect = document.getElementById("installments");
            buildInstallments(installmentsSelect, result.installments);
          })
          .catch((e) => {
            console.error("error --> ", e);
          });
      }
    });

    form.addEventListener("submit", function (event) {
      event.preventDefault();
      const payer_name = document.getElementById("payer_name").value;
      const payer_document = document.getElementById("payer_document").value;

      dlocalInit
        .createToken(cardField, {
          name: payer_name.value,
        })
        .then(function (result) {
          if (payer_name === "") {
            displayError(true, t("form.error.dlocal.addCardOwner"));
            return;
          }

          if (payer_document === "") {
            displayError(true, t("form.error.dlocal.addDocOwner"));
            return;
          }

          if (payer_document.length < minLength) {
            displayError(
              true,
              `El ${documentType} debe conformarce entre ${minLength} y ${maxLength} caracteres`
            );
            return;
          }

          displayError(false, "");

          if (result.token) {
            setToken(result.token);
            if (!isLink) {
              emit();
              setLoader(true);
            } else {
              fetchPay(result.token);
            }
          }
        })
        .catch((result) => {
          if (result.error) {
            displayError(true, result.error.message);
          }
        });
    });
  };

  useEffect(() => {
    if (country && locale && currency && total_amount) {
      init(country, locale, currency, total_amount);
    }
  }, [locale, country, currency, total_amount]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let countryID = null;

        if (isLink && data?.quote.from_id) {
          countryID = data.quote.from_id;
        } else {
          countryID =
            localStorage.getItem("country_id") ?? geolocation?.country_id;
        }
        const result = await dlocalCountryReferenceFetchController.fetchData(
          countryID
        );
        setCountryReference(result.data);
      } catch (error) {
        console.warn(error);
      } finally {
        console.warn("done");
      }
    };

    fetchData();
  }, [geolocation]);

  useEffect(() => {
    if (billingData && !isLink) {
      fetchBilling();
    }
  }, [billingData, isLink]);

  useEffect(() => {
    // console.log(billingState);

    if (billingState && !isLink) {
      fetchPay();
    }
  }, [billingState, isLink]);

  return (
    <>
      {requestError && <WhatsappSupport reserve_id={reserveData?.reserve_id} />}
      <form id="dlocal-form" ref={formRef}>
        <Card>
          <CardContent>
            <div className="mb-8">
              <h1 className="font-bold text-2xl text-[#006FE8] mb-8">
                {t("steps.payment.title.creditCard")}
              </h1>
              <>
                <FormGroup className="mb-4">
                  <label className="font-bold text-xs mb-2 text-[#596E80]">
                    {t("form.label.cardNumber")}
                  </label>
                  <div id="pan-field" className="w-full" />
                </FormGroup>
                <div className="mb-4 flex justify-between">
                  <FormGroup className="w-full mr-4">
                    <label className="font-bold text-xs mb-2 text-[#596E80]">
                      {t("form.label.expiration")}
                    </label>
                    <div id="exp-field" className="w-full" />
                  </FormGroup>
                  <FormGroup className="w-full ml-4">
                    <label className="font-bold text-xs mb-2 text-[#596E80]">
                      {t("form.label.securityCode")}
                    </label>
                    <div id="cvv-field" className="w-full" />
                  </FormGroup>
                </div>
                <FormGroup className="mb-4 w-full" ariaRequired="#payer_name">
                  <label className="font-bold text-xs mb-2 text-[#596E80]">
                    {t("form.label.cardholder")}
                  </label>
                  <div className="DlocalField">
                    <input
                      id="payer_name"
                      type="text"
                      placeholder={t("form.label.nameAndLastName")}
                      name="payer_name"
                      className="w-full"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="mb-4 w-full">
                  <label className="font-bold text-xs mb-2 text-[#596E80]">
                    {t("form.label.cardholderDocument")}
                  </label>
                  <div className="DlocalField">
                    <input
                      className="w-full"
                      id="payer_document"
                      type="text"
                      name="payer_document"
                      placeholder={documentType}
                      maxLength={maxLength}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="mb-4 w-full">
                  <label className="font-bold text-xs mb-2 text-[#596E80]">
                    {t("form.label.dues")}
                  </label>
                  <div className="DlocalField">
                    <select id="installments" className="w-full" disabled>
                      <option value="" disabled>
                        {t("form.label.selectDues")}
                      </option>
                    </select>
                  </div>
                </FormGroup>
                <Alert
                  severity="error"
                  id="card-errors"
                  className={"!hidden"}
                ></Alert>
                <Alert
                  severity="error"
                  className={!requestError ? "!hidden" : "flex"}
                >
                  {requestError}
                </Alert>
              </>
            </div>
          </CardContent>
        </Card>
        <input
          type="hidden"
          id="email"
          name="email"
          value={parsedData["beneficiary[email][0]"]}
        />
      </form>
    </>
  );
};

export default DlocalCreditCardForm;
