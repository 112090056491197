export const removeCepMask = (inputString) => {
  return inputString.replace(/_/g, "").replace(/-/g, "");
};

export const numberWithDots = (number) => {
  if (number) {
    try {
      const formatter = new Intl.NumberFormat("es-ES");
      return formatter.format(number);
    } catch (error) {
      console.error(error);
    }
  }
}

export const formatNumber = (number) => {
  const numberWithoutPoint = number.toString().replace(".", "");
  return numberWithoutPoint.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const sanitizeNumber = (number) => {
  return number.toString().replace(".", "");
};

export const removeBeneficiaryKeys = (obj) => {
  const newObj = {};

  for (const key in obj) {
    if (!key.includes("beneficiary")) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export const extractQRData = (inputString) => {
  const regex = /^(\d+).*?[a-zA-Z]/;
  const matches = inputString.match(regex);

  if (matches && matches.length >= 2) {
    return matches[1];
  } else {
    return null;
  }
};
