import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFormData } from "../../context/formContext";
import * as paymentProcessHelper from "../../helpers/paymentProcessHelper";

import {
  emitPOSTController,
  payPOSTController,
} from "../../dataController/dataController";
import { removeBeneficiaryKeys } from "../../helpers/sanitize";
import { useActionButton } from "../../context/actionButtonContext";
import { Alert, FormGroup, TextField } from "@mui/material";
import { useLoader } from "../../context/loaderContext";
import { mapBillingData } from "../../helpers/dataMapper";
import { useCountries } from "../../context/countriesContext";
import { useQuote } from "../../context/quoteContext";

const infoAlertStyle = {
  borderRadius: "10px",
  backgroundColor: "#E5E3FC",
  border: "solid 1px #3C2CE8",
  color: "#0E0849",
  fontWeight: "normal",
  boxShadow: "none",
};
const Rebill = ({ transaction_type }) => {
  const { t } = useTranslation();
  const { parsedData } = useFormData();
  const [reserveData, setReserveData] = useState(parsedData);
  const [billingState, setBillingState] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { setLoader } = useLoader();
  const { setActionButtonEnabled, setActionButtonAvailable } =
    useActionButton();
  const { isLink } = useQuote();

  const transaction =
    transaction_type === "rebillTransfer" ? "de la transferencia" : "";
  const transaction_text =
    transaction_type === "rebillTransfer"
      ? "Transferencia bancaria"
      : "efectivo";
  setActionButtonAvailable(false);

  const handleSubmissionError = (error) => {
    console.error("Error during submission:", error);
  };

  useEffect(() => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = pattern.test(email);
    setError(() => (email && isValid ? false : true));
  }, [email]);

  useEffect(() => {
    const userEmail = parsedData?.other_email_send_voucher
      ? parsedData?.other_email_send_voucher
      : parsedData?.["beneficiary[email][0]"];
    if (userEmail) {
      setEmail(userEmail);
    }
  }, []);

  useEffect(() => {
    console.log("email has changed", email);
  }, [email]);

  useEffect(() => {
    console.log("reserveData", reserveData);
  }, [reserveData]);

  // UPDATE REBILL ****************************************************

  const { countries } = useCountries();

  const userCountry = countries.find(
    (country) => country.id === reserveData.country
  );

  const billingData = reserveData
    ? mapBillingData(reserveData, userCountry, "Rebill")
    : null;

  const fetchBilling = useCallback(
    async (reserve_id) => {
      const _billing_data = billingData;
      _billing_data.reserve_id = reserve_id;

      console.log("fetchBilling");
      console.log("email", email);

      const result = await paymentProcessHelper.fetchBilling(_billing_data);
      if (result.status) {
        setBillingState(result.status);
        console.warn("aca");
        Pay(reserve_id);
      } else {
        setBillingState(false);
        setLoader(false);
      }

      console.log("reserveDAta", reserveData);
      console.info("fetchBilling done");
    },
    [billingData.reserveData, email]
  );

  const sendEmail = async (params, reserve_id) => {
    // const { reserve_id } = reserveData;
    const { url } = params;

    const data = {
      reserve_id: reserve_id,
      email: email,
      payment_method: "Rebill",
    };

    const result = await paymentProcessHelper.sendDepositNotification(data);

    if (result?.status) {
      setTimeout(() => {
        window.location.href = url;
      }, 500);
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  // useEffect(() => {
  //   if (reserveData.reserve_status) {
  //     setLoader(true);
  //     const fetchData = async () => {
  //       try {
  //         const { reserve_id } = reserveData;
  //         const transaction_types = {
  //           rebillTransfer: "TRANSFER",
  //           rebillCash: "CASH",
  //         };

  //         const payload_transaction_type = transaction_types[transaction_type];

  //         const payData = {
  //           method: "Rebill",
  //           transaction_type: payload_transaction_type,
  //           reserve_id: reserve_id,
  //           email: email,
  //         };
  //         const result = await payPOSTController.fetchData({
  //           body: payData,
  //         });

  //         if (result?.data?.status) {
  //           await sendEmail(result?.data);
  //         } else {
  //           setLoader(false);
  //         }
  //       } catch (error) {
  //         handleSubmissionError(error);
  //       } finally {
  //         console.info("Submission done");
  //       }
  //     };
  //     fetchData();
  //   }
  // }, [reserveData]);

  const Pay = useCallback(
    (_reserve_id) => {
      setLoader(true);
      const fetchData = async () => {
        try {
          const reserve_id = !isLink ? _reserve_id : reserveData.reserve_id;

          // const { reserve_id } = reserveData;

          const transaction_types = {
            rebillTransfer: "TRANSFER",
            rebillCash: "CASH",
          };

          const payload_transaction_type = transaction_types[transaction_type];

          const payData = {
            method: "Rebill",
            transaction_type: payload_transaction_type,
            reserve_id: reserve_id,
            email: email,
          };
          const result = await payPOSTController.fetchData({
            body: payData,
          });

          if (result?.data?.status) {
            await sendEmail(result?.data, reserve_id);
          } else {
            setLoader(false);
          }
        } catch (error) {
          handleSubmissionError(error);
        } finally {
          console.info("Submission done");
        }
      };
      fetchData();
    },
    [reserveData, email, isLink]
  );

  const renderAlertMsg = useCallback(() => {
    return (
      <Alert
        severity="warning"
        variant="filled"
        sx={{
          borderRadius: "10px",
          backgroundColor: "#FFF7B0",
          borderColor: "#EDC95E",
          color: "#453608",
          fontWeight: "normal",
          border: "solid 1px #EDC95E",
          boxShadow: "none",
        }}
      >
        <Trans
          i18nKey={"steps.paymentMethods.methods.rebill.alert"}
          components={{
            transaction: transaction,
            strong: <strong></strong>,
            br: <br />,
            a: <a></a>,
          }}
        />
      </Alert>
    );
  }, []);

  const continuePurchase = useCallback(() => {
    setSubmitted(true);
    setLoader(true);

    if (email) {
      if (!isLink) {
        const fetchData = async () => {
          try {
            const result = await emitPOSTController.fetchData({
              body: parsedData,
            });

            const reserve = result?.data?.reserve;
            const status = result?.data?.status;

            if (status) {

              setReserveData((prev) => ({
                ...prev,
                ...{
                  reserve_id: reserve.new_id,
                  reserve_status: status,
                },
              }));

              if (billingData && !isLink) {
                fetchBilling(reserve.new_id);
              }
            }
          } catch (error) {
            handleSubmissionError(error);
          } finally {
            console.info("Submission done");
            setLoader(false);
          }
        };
        fetchData();
      } else {
        console.log("no hay emit -> pay");
        Pay();
      }
    }
  }, [email]);

  return (
    <>
      <h1 className="font-bold text-2xl text-[#006FE8] mb-2">
        {t("steps.paymentMethods.methods.rebill.title", {
          transaction_type: transaction_text,
        })}
      </h1>

      <div className="my-7">{renderAlertMsg()}</div>

      <h1 className="font-bold text-2xl text-[#006FE8] mb-2">
        {t("steps.paymentMethods.methods.rebill.titleEmail")}
      </h1>

      <FormGroup className="my-5">
        <label className="font-bold text-xs mb-2 text-[#596E80]">
          {t("form.label.email")}
        </label>
        <TextField
          sx={{ marginBottom: "1rem" }}
          fullWidth
          autoComplete="off"
          onChange={handleChange}
          value={email}
          error={error && submitted}
          helperText={error && submitted && t("form.error.invalidEmail")}
        />

        <button
          className="btn-continue-workflow block"
          type="button"
          disabled={error}
          onClick={continuePurchase}
        >
          {t("steps.paymentMethods.methods.rebill.btnPay")}
        </button>
      </FormGroup>
    </>
  );
};

export default Rebill;
