import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Chubb = ({ summary, children, defaultExpanded = true }) => {
  return (
    <div className="chubb-container">
      <Accordion
        style={{
          boxShadow:
            "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
          borderRadius: "10px",
          display: 'flex',
          flexDirection: 'column',
        }}
        className="shadow-md"
        defaultExpanded={defaultExpanded}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: "#1B2225", fontSize: "32px" }} />
          }
          sx={{ paddingTop: "5px", paddingBottom: "5px" }}
        >
          <div className="w-full flex items-center justify-start">
            {summary}
          </div>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Chubb;
