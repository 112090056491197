import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { mapPassengersData } from "../helpers/dataMapper";
import { useQuote } from "./quoteContext";
import { useLocation } from "./locationContext";
import { convertDate } from "../helpers/dateFormat";
import { useLanguage } from "./languageContext";

const FormDataContext = createContext();

export const FormDataProvider = ({ children }) => {
  const [form, setForm] = useState({});
  const { data, isLink } = useQuote();
  const { quote } = data || {};
  const { geolocation, transactionId } = useLocation();

  const setFormData = (newObject) => {
    setForm(newObject);
  };
  const getFormData = () => {
    return form;
  };

  const initData = useMemo(() => {
    if (quote && !isLink) {
      return {
        entity: geolocation?.entity,
        seller: geolocation?.seller,
        product_id: quote?.product_id,
        country: geolocation?.country_id,
        region: quote?.to,
        date_from: convertDate(quote?.date_from),
        date_to: convertDate(quote?.date_to),
        user_follow_id: transactionId,
        b2b_auth_code: false,
      };
    }
    return {};
  }, [isLink, geolocation, quote, transactionId]);

  const linkData = useMemo(() => {
    if (quote && isLink) {
      const birthday = quote?.beneficiaries[0]?.birthday.split("-");
      return {
        entity: geolocation?.entity,
        seller: geolocation?.seller,
        product_id: quote?.product_id,
        country: geolocation?.country_id,
        region: quote?.to_id,
        date_from: convertDate(quote?.from_date),
        date_to: convertDate(quote?.to_date),
        user_follow_id: transactionId,
        reserve_id: transactionId,
        b2b_auth_code: false,
        "beneficiary[name][0]": quote?.beneficiaries[0]?.firstname,
        "beneficiary[lastname][0]": quote?.beneficiaries[0]?.lastname,
        "beneficiary[email][0]": quote?.beneficiaries[0]?.email,
        "beneficiary[doc_type][0]": 3,
        "beneficiary[doc_number][0]": quote?.beneficiaries[0]?.passport,
        "beneficiary[phone][0]": null,
        "beneficiary[bday][0]": birthday[0],
        "beneficiary[bmonth][0]": birthday[1],
        "beneficiary[byear][0]": birthday[2],
        "beneficiary[country_residence][0]": geolocation?.country_id,
        "beneficiary[nacionality][0]": geolocation?.country_id,
      };
    }

    return {};
  }, [isLink, geolocation, quote, transactionId]);

  const sendData = !isLink ? initData : linkData;

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      ...sendData,
    }));
  }, [isLink]);

  const parsedData = mapPassengersData(form);

  return (
    <FormDataContext.Provider value={{ setFormData, getFormData, parsedData }}>
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = () => {
  return useContext(FormDataContext);
};
