import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IconInfo from "./../../assets/icons/IcoInfo";
import { useWhatsapp } from "../../context/whatsappContext";

const LinkExpired = () => {
  const { t } = useTranslation();

  const { whatsapp_number } = useWhatsapp();
  const reserveID = location.pathname.split("/")[3];

  return (
    <div className="space-y-8 flex flex-col text-center items-center justify-center w-full h-full mt-20  md:mt-24 p-4">
      <h1 className="text-[#006FE8] font-titillium font-bold text-4xl">
        {t("index.assistPay.expiredLink")}
      </h1>
      <IconInfo />
      <p className="text-[#122A56] font-titillium text-base max-w-md md:max-w-2xl">
        {t("index.assistPay.expirationDescription")}
      </p>
      <a
        id="btn_whatsapp_checkout"
        className="w-full cursor-pointer max-w-[260px] text-white font-semibold text-xl rounded-lg py-4 
        px-8 bg-[#59D3C3]"
        href={`https://wa.me/+${whatsapp_number}/?text=${t(
          "index.whatsappSupportMessage"
        )}${reserveID}`}
        target="_blank"
      >
        {t("index.assistPay.contactSupport")}
      </a>
    </div>
  );
};

export default LinkExpired;
