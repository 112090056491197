import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import InputMask from "react-input-mask";
import {
  FormGroup,
  TextField,
  MenuItem,
  Autocomplete,
  Box,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import {
  validateAge,
  validateDocs,
  validateDate,
  matchWords,
} from "../../../helpers/validators";
import { useTranslation } from "react-i18next";
import { useLocation } from "../../../context/locationContext";
import { useFormData } from "../../../context/formContext";
import { pricingPOSTController } from "../../../dataController/dataController";
import { mapPassengersData } from "../../../helpers/dataMapper";
import PricingStatus from "../../PricingStatus";

const PassengerForm = ({
  index,
  total_pax,
  control,
  Controller,
  errors,
  setError,
  clearErrors,
  setValue,
  trigger,
  getValues,
  countries,
  form,
  setPurchaseDetail,
  setPricingStatus,
  setException,
  isValidForm,
  isValid,
  setActionButtonEnabled,
  setActionButtonAvailable,
}) => {
  const { t, i18n } = useTranslation();
  const { geolocation, geoUrl } = useLocation();
  const { parsedData, setFormData, getFormData } = useFormData();
  const [br, setBR] = useState(null);
  const [docType, setDocType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openPricingModalStatus, setOpenPricingModalStatus] = useState(false);
  const defaultCountry = countries?.find(
    (country) => country.id === geolocation?.country_id
  );
  const [paxName, setPaxName] = useState("");

  const handleClose = () => {};

  useEffect(() => {
    try {
      if (geolocation?.country === "BR" || geoUrl === "br") {
        setBR(true);
        for (let i = 0; i < total_pax; i++) {
          setValue(`doc_type_${i}`, "1");
        }
      } else {
        setBR(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const ERROR_MESSAGES = {
    ageValidation: {
      INCORRECT_DATE_FORMAT: t("form.error.invalidDateFormat"),
      INVALID_MONTH: t("form.error.invalidMonth"),
      INVALID_DAY: t("form.error.invalidDay"),
      INVALID_BIRTHDATE: t("form.error.invalidBirthdate"),
      INVALID_AGE: t("form.error.invalidAge"),
    },
    form: {
      REQUIRED: t("form.error.required"),
      INVALID_EMAIL: t("form.error.invalidEmail"),
      INVALID_EMAIL_CLONED: "cloned email",
    },
    info: {
      CPF: t("form.info.cpf"),
    },
  };

  const statusIcon = useMemo(() => {
    if (loading) {
      return <CircularProgress size={20} sx={{ color: "#006FE8" }} />;
    }
    return null;
  }, [loading]);

  useEffect(() => {
    setValue(`nacionality_${index}`, geolocation?.country_id);
    setValue(`phone_${index}`, "null");
  }, [index, setValue, geolocation]);

  const [beneficiaryBirthDate, setBeneficiaryBirthDate] = useState({});

  useEffect(() => {
    isValidForm(isValid);
    // const checkDateFormat = /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$/.test(value);
    if (isValid) {
      const dataForm = mapPassengersData(getValues());
      setFormData((prev) => ({ ...prev, ...dataForm }));
    }
  }, [isValid, beneficiaryBirthDate]);

  const onChange = (e, field) => {
    const value = e.target.value;
    setValue(field?.name, value, { shouldValidate: true });

    if (field?.name.includes("birth_date_")) {
      const checkDateFormat = validateDate(value);
      if (checkDateFormat) {
        const beneficiaryBirthDate = mapPassengersData(getValues());
        setBeneficiaryBirthDate(beneficiaryBirthDate);
        // setFormData((prev) => ({ ...prev, ...beneficiaryBirthDate }));
        trigger(field?.name);
      }
    }
    form.current.requestSubmit();
  };

  const handleSubmissionError = (error) => {
    console.error("Error during submission:", error);
  };

  useEffect(() => {
    if (
      parsedData &&
      parsedData.date_from !== "NaN/NaN/NaN" &&
      parsedData.date_from
    ) {
      setLoading(true);
      setActionButtonEnabled(false);

      const fetchData = async () => {
        try {
          const result = await pricingPOSTController.fetchData({
            body: parsedData,
          });

          const error = !result.data?.status ? true : false;
          let forceException = { status: false, type: null, who: null };

          if (error) {
            let msg = result?.data?.hasOwnProperty("msg")
              ? result?.data?.msg
              : "Ha ocurrido un error. Por favor, intente nuevamente.";

            // EXCEPTION HANDLING FOR AGE LIMIT PRODUCT

            const age_error = [
              "el producto",
              "El beneficiario",
              "tiene una edad incorrecta",
            ];

            if (matchWords(msg, age_error).length >= 3) {
              const patterns = {
                age: /\([0-9]*\)/,
                name: /beneficiario(.*?)tiene/,
                product: /producto(.*?)no/,
              };

              const age = msg.match(patterns.age)[0];
              const name = msg.match(patterns.name)[1];
              const product = msg.match(patterns.product)[1];

              let error_message = "";

              if (age && name && product) {
                msg = t("index.errors.ageLimitProduct", {
                  age,
                  name,
                  product,
                });
              } else {
                msg = t("index.errors.ageLimitProductDefault");
              }
            }

            var patternExclud =
              /no se puede actualizar el beneficiario porque tiene una reserva en vigencia/i;

            if (patternExclud.test(msg)) {
              setActionButtonEnabled(false);
              setActionButtonAvailable(false);
              setPaxName(msg.split(",")[0]);
              setOpenPricingModalStatus(true);
              msg = t("index.errors.reserveVigency");
            }

            // EXCEPTION HANDLING FOR BMONTH VALIDATION

            if (msg === "Undefined index: bmonth") {
              forceException = { status: true, type: "bmonth" };
            }

            // EXCEPTION HANDLING FOR COMPLETE BIRTHDATE VALIDATION

            if (
              msg ===
              "Error en el registro del pasaporte o documento de identidad. Revisar los datos ingresados."
            ) {
              forceException = { status: true, type: "document" };
            }

            // EXCEPTION HANDLING FOR MULTITRIP AGE VALIDATION

            const regex = new RegExp("multitrip", "i");
            if (regex.test(msg)) {
              forceException = { status: true, type: 69 };

              if (total_pax > 1) {
                try {
                  const pax_fullname = msg.match(
                    /(?<=beneficiario\s+).*?(?=\s+tiene)/gs
                  )[0]; // Returns multiple matches if any
                  forceException = { ...forceException, who: pax_fullname };
                } catch (error) {
                  console.error(error);
                }
              }
            }

            if (!forceException.status) {
              if (!openPricingModalStatus) {} else {
                setPricingStatus({ error: true, msg: msg });
              }
            }
          } else {
            setPricingStatus({ error: false, msg: null });
          }

          //^ AGE EXCEPTION

          const beneficiaryFlag = "beneficiary[name][0]" in parsedData;
          const ageCodeExceptions = [65, 86];

          if (
            beneficiaryFlag &&
            (result.data?.status || forceException.status)
          ) {
            if (
              (result.data?.hasOwnProperty("age_alert") ||
                forceException.status) &&
              // (exception?.continue !== true || error) &&
              (ageCodeExceptions.includes(result.data?.age_alert?.age_fault) ||
                ageCodeExceptions.includes(forceException.type))
            ) {
              const age_alert = forceException.status
                ? { age_fault: forceException.type, who: forceException.who }
                : result.data?.age_alert;
              setException({
                status: true,
                age_alert: age_alert,
                continue: false,
                visibility: false,
              });
            } else {
              if (
                !forceException.status &&
                !error &&
                !result.data?.hasOwnProperty("age_alert")
              ) {
                setException({
                  status: false,
                  age_alert: null,
                  continue: true,
                  visibility: false,
                });
              }
            }
          }
          setPurchaseDetail((prev) => ({ ...prev, ...result.data }));
        } catch (error) {
          handleSubmissionError(error);
        } finally {
          setLoading(false);
          setActionButtonEnabled(true);

          console.info("Submission done <PassengerForm-EsAR>");
        }
      };
      fetchData();
    }
  }, [parsedData]);

  return (
    <>
      <PricingStatus
        close={setOpenPricingModalStatus}
        open={openPricingModalStatus}
        paxName={paxName}
      />
      {/* <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            Agree
        </DialogActions>
      </Dialog> */}

      <FormGroup className="mb-4">
        <label className="font-bold text-xs mb-2 text-[#596E80]">
          {t("form.label.nameAndLastName")}
        </label>
        <Controller
          name={`fullName_${index}`}
          control={control}
          rules={{
            required: ERROR_MESSAGES.form.REQUIRED,
            validate: (value) => {
              // Validación adicional: Debe tener tanto nombre como apellido
              const names = value.split(" ");
              if (names.length < 2) {
                return "Ingrese nombre completo";
              }

              // Validación adicional: No permite caracteres especiales
              if (/[^A-Za-zñÑáéíóú\s]/.test(value)) {
                return "No se permiten caracteres especiales";
              }

              return true;
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              autoComplete="none"
              error={!!errors[`fullName_${index}`]}
              helperText={errors[`fullName_${index}`]?.message}
            />
          )}
        />
      </FormGroup>
      <FormGroup className="mb-4">
        <label className="font-bold text-xs mb-2 text-[#596E80]">
          {t("form.label.email")}
        </label>
        <Controller
          name={`email_${index}`}
          control={control}
          rules={{
            required: ERROR_MESSAGES.form.REQUIRED,
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: ERROR_MESSAGES.form.INVALID_EMAIL,
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              fullWidth
              autoComplete="none"
              error={!!errors[`email_${index}`]}
              helperText={errors[`email_${index}`]?.message}
            />
          )}
        />
      </FormGroup>
      <div className="mb-4 flex flex-col lg:flex-row lg:justify-between">
        {!br && (
          <FormGroup className="w-full mr-6">
            <label className="font-bold text-xs mb-2 text-[#596E80] w-24 sm:w-full">
              {t("form.label.documentType")}
            </label>
            <Controller
              name={`doc_type_${index}`}
              rules={{ required: ERROR_MESSAGES.form.REQUIRED }}
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  {...field}
                  value={field.value || ""}
                  // onChange={(e) => { setDocTypes(e.target.value, index) }}
                  error={!!errors[`doc_type_${index}`]}
                  helperText={errors[`doc_type_${index}`]?.message}
                >
                  <MenuItem value="5">
                    {t("form.options.docType.passport")}
                  </MenuItem>
                  <MenuItem value="1">
                    {t("form.options.docType.document")}
                  </MenuItem>
                </TextField>
              )}
            />
          </FormGroup>
        )}
        <FormGroup className="w-full mt-4 lg:mt-0">
          <label className="font-bold text-xs mb-2 text-[#596E80]">
            {br ? t("form.label.cpf") : t("form.label.documentNumber")}
          </label>
          <Controller
            name={`doc_number_${index}`}
            control={control}
            rules={{
              validate: {
                required: (value) => {
                  const validDoc = validateDocs(
                    value,
                    index,
                    total_pax,
                    getValues,
                    br
                  );
                  if (!value) return ERROR_MESSAGES.form.REQUIRED;
                  if (!validDoc.status) return t(validDoc.message);
                },
              },
              // pattern: {
              //   value: /^[a-zA-Z0-9.-]+$/,
              //   message:
              //     docType === 1 && br
              //       ? t("form.error.invalidCPF")
              //       : t("form.error.onlyLetterAndNumbers"),
              // },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                autoComplete="none"
                error={!!errors[`doc_number_${index}`]}
                helperText={errors[`doc_number_${index}`]?.message}
              />
            )}
          />
        </FormGroup>
      </div>
      <FormGroup className="mb-4">
        <label className="font-bold text-xs mb-2 text-[#596E80]">
          {t("form.label.birthdate")}
        </label>
        <Controller
          name={`birth_date_${index}`}
          control={control}
          defaultValue=""
          rules={{
            validate: {
              required: (value) => {
                const validAge = validateAge(
                  value,
                  86,
                  ERROR_MESSAGES.ageValidation
                );
                if (!value || !validAge.status) {
                  setError(`birth_date_${index}`);
                } else {
                  clearErrors(`birth_date_${index}`);
                }
                if (!value) return ERROR_MESSAGES.form.REQUIRED;
                if (!validAge.status) return validAge.message;
              },
            },
          }}
          render={({ field }) => (
            <InputMask
              mask="99/99/9999"
              value={field.value}
              onChange={(e) => onChange(e, field)}
            >
              {() => (
                <TextField
                  fullWidth
                  autoComplete="none"
                  error={!!errors[`birth_date_${index}`]}
                  helperText={errors[`birth_date_${index}`]?.message}
                  placeholder="00/00/0000"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {statusIcon}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </InputMask>
          )}
          // render={({ field: { onChange, value } }) => (
          //   <InputMask mask="99/99/9999" value={value} onChange={onChange}>
          //     {() => (
          //       <TextField
          //         fullWidth
          //         error={!!errors[`birth_date_${index}`]}
          //         helperText={errors[`birth_date_${index}`]?.message}
          //         placeholder="00/00/0000"
          //       />
          //     )}
          //   </InputMask>
          // )}
        />
      </FormGroup>
      <FormGroup id="countryResidence" className="mb-4">
        <label className="font-bold text-xs mb-2 text-[#596E80]">
          {t("form.label.countryResidence")}
        </label>
        <Controller
          name={`country_residence_${index}`}
          control={control}
          rules={{ required: ERROR_MESSAGES.form.REQUIRED }}
          render={({ field }) => {
            return (
              <Autocomplete
                noOptionsText={t("form.label.countryResidenceNoOptions")}
                options={countries}
                getOptionLabel={(option) => option?.name}
                onChange={(event, value) => field.onChange(value?.id)}
                value={
                  field.value
                    ? countries.find((country) => country.id === field.value)
                    : null
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                      alt=""
                    />
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    autoComplete="none"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    error={!!errors[`country_residence_${index}`]}
                    helperText={errors[`country_residence_${index}`]?.message}
                  />
                )}
              />
            );
          }}
        />
      </FormGroup>
    </>
  );
};

export default PassengerForm;
