import apiService from "../service/apiService";

const PRICING_ENDPOINT = "pricing";
const QUOTE_ENDPOINT = "quote";
const GEOLOCATION_ENDPOINT = "geolocation";
const DISCOUNT_ENDPOINT = "discount";
const COUNTRIES_ENDPOINT = "countries";
const EMIT_ENDPOINT = "emit";
const PAY_ENDPOINT = "pay";
const PAYMENTS_ENDPOINT = "get-payment-methods";
const BILLING_ENDPOINT = "billing";
const USER_FOLLOW_END_ENDPOINT = "user-follow-end";
const DLOCAL_COUNTRY_REFERENCE_ENDPOINT = "dlocal-country-reference";
const VOUCHER_ENDPOINT = "voucher";
const DEPOSIT_NOTIFICATION_ENDPOINT = "send-deposit-notification";
const apiRequest = async (
  endpoint,
  method = "GET",
  queryString = "",
  options = {},
  setLoading = () => null
) => {
  try {
    return await apiService.fetchData(
      endpoint,
      method,
      queryString,
      options,
      setLoading
    );
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}`, error);
    throw error;
  }
};

export const pricingPOSTController = {
  fetchData: async (body) => apiRequest(PRICING_ENDPOINT, "POST", null, body),
};

export const quoteFetchController = {
  fetchData: async (id) => apiRequest(QUOTE_ENDPOINT, "GET", `id=${id}`),
};

export const geolocationFetchController = {
  fetchData: async (country_code) => {
    const q = country_code ? `flag=true&country_code=${country_code}` : "";
    return apiRequest(GEOLOCATION_ENDPOINT, "GET", q);
  },
};

export const discountFetchController = {
  fetchData: async (params, setLoading) => {
    return apiRequest(DISCOUNT_ENDPOINT, "GET", params, {}, setLoading);
  },
};

export const countriesFetchController = {
  fetchData: async () => apiRequest(COUNTRIES_ENDPOINT, "GET"),
};

export const emitPOSTController = {
  fetchData: async (body, reserveId) => {
    const EMIT_URL = !reserveId
      ? EMIT_ENDPOINT
      : `${EMIT_ENDPOINT}/${reserveId}`;
    return apiRequest(EMIT_URL, "POST", null, body);
  },
};

export const payPOSTController = {
  fetchData: async (body) => apiRequest(PAY_ENDPOINT, "POST", null, body),
};

export const paymentMethodsFetchController = {
  fetchData: async (country_id) =>
    apiRequest(PAYMENTS_ENDPOINT, "GET", `country_id=${country_id}`),
};

export const billingPOSTController = {
  fetchData: async (body) => apiRequest(BILLING_ENDPOINT, "POST", null, body),
};

export const userFollowEndPOSTController = {
  fetchData: async (body) =>
    apiRequest(USER_FOLLOW_END_ENDPOINT, "POST", null, body),
};

export const dlocalCountryReferenceFetchController = {
  fetchData: async (country_id) =>
    apiRequest(
      DLOCAL_COUNTRY_REFERENCE_ENDPOINT,
      "GET",
      `country_id=${country_id}`
    ),
};

export const voucherFetchController = {
  fetchData: async (reserve_id) =>
    apiRequest(VOUCHER_ENDPOINT, "GET", `reserve_id=${reserve_id}`),
};

export const sendEmailNotificationPOSTController = {
  fetchData: async (body) =>
    apiRequest(DEPOSIT_NOTIFICATION_ENDPOINT, "POST", null, body),
};
