import React from "react";

const SuccessGuy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0.00 0.00 118.00 157.00"
      width={118}
      height={157}
    >
      <g stroke-width="2.00" fill="none" stroke-linecap="butt">
        <path
          stroke="#8f5f83"
          vector-effect="non-scaling-stroke"
          d="
    M 35.63 36.88
    Q 33.68 39.26 32.11 42.52
    A 0.33 0.24 81.4 0 0 32.51 42.93
    Q 32.89 42.23 33.16 41.48
    Q 33.20 41.39 33.28 41.33
    Q 33.40 41.25 33.53 41.17
    A 0.27 0.26 69.7 0 1 33.93 41.36
    L 35.31 50.78"
        />
        <path
          stroke="#8094cd"
          vector-effect="non-scaling-stroke"
          d="
    M 71.13 53.90
    L 49.56 54.70"
        />
        <path
          stroke="#8f5f83"
          vector-effect="non-scaling-stroke"
          d="
    M 85.22 51.86
    Q 84.95 51.53 84.97 51.00
    Q 85.17 46.20 85.62 41.40
    C 85.79 39.62 85.23 38.38 84.16 37.12"
        />
        <path
          stroke="#19194e"
          vector-effect="non-scaling-stroke"
          d="
    M 68.25 31.01
    Q 66.62 31.17 65.58 30.74"
        />
        <path
          stroke="#687b80"
          vector-effect="non-scaling-stroke"
          d="
    M 74.46 101.54
    L 61.70 101.98"
        />
        <path
          stroke="#687b80"
          vector-effect="non-scaling-stroke"
          d="
    M 58.56 102.11
    L 46.04 102.64"
        />
        <path
          stroke="#8f5f83"
          vector-effect="non-scaling-stroke"
          d="
    M 64.05 30.97
    C 63.26 36.36 57.71 36.36 56.69 31.17"
        />
        <path
          stroke="#19194e"
          vector-effect="non-scaling-stroke"
          d="
    M 54.17 30.99
    Q 52.62 31.36 50.07 31.10"
        />
        <path
          stroke="#774636"
          vector-effect="non-scaling-stroke"
          d="
    M 62.18 28.92
    L 58.31 30.09"
        />
        <path
          stroke="#774636"
          vector-effect="non-scaling-stroke"
          d="
    M 64.66 24.55
    L 62.74 27.25"
        />
        <path
          stroke="#774636"
          vector-effect="non-scaling-stroke"
          d="
    M 62.14 27.66
    Q 60.20 28.51 58.29 28.20"
        />
        <path
          stroke="#774636"
          vector-effect="non-scaling-stroke"
          d="
    M 57.42 27.86
    Q 55.08 25.91 54.88 26.79"
        />
        <path
          stroke="#774636"
          vector-effect="non-scaling-stroke"
          d="
    M 74.44 103.32
    L 61.90 104.25"
        />
        <path
          stroke="#774636"
          vector-effect="non-scaling-stroke"
          d="
    M 58.51 103.78
    L 45.99 103.85"
        />
        <path
          stroke="#774636"
          vector-effect="non-scaling-stroke"
          d="
    M 64.61 20.97
    Q 58.49 15.41 54.17 23.49"
        />
        <path
          stroke="#777eaa"
          vector-effect="non-scaling-stroke"
          d="
    M 74.48 113.20
    L 62.55 113.79"
        />
        <path
          stroke="#7769a6"
          vector-effect="non-scaling-stroke"
          d="
    M 58.21 113.42
    L 53.38 113.52"
        />
        <path
          stroke="#777eaa"
          vector-effect="non-scaling-stroke"
          d="
    M 53.38 113.52
    L 46.35 113.54"
        />
        <path
          stroke="#6caaf4"
          vector-effect="non-scaling-stroke"
          d="
    M 74.51 133.47
    L 69.61 133.21
    A 1.35 1.35 0.0 0 0 68.20 134.60
    Q 68.27 136.60 69.37 136.72"
        />
        <path
          stroke="#3c78bb"
          vector-effect="non-scaling-stroke"
          d="
    M 69.37 136.72
    Q 69.39 137.19 69.50 137.16"
        />
        <path
          stroke="#6caaf4"
          vector-effect="non-scaling-stroke"
          d="
    M 69.50 137.16
    L 63.88 134.65"
        />
        <path
          stroke="#6c96f0"
          vector-effect="non-scaling-stroke"
          d="
    M 58.11 134.58
    Q 57.39 135.25 56.87 135.32"
        />
        <path
          stroke="#005be4"
          vector-effect="non-scaling-stroke"
          d="
    M 56.87 135.32
    Q 57.37 133.58 56.88 132.27
    C 54.83 126.72 54.08 119.44 53.38 113.52"
        />
        <path
          stroke="#6caaf4"
          vector-effect="non-scaling-stroke"
          d="
    M 56.87 135.32
    Q 56.19 136.16 54.91 136.56
    Q 54.38 136.73 54.52 136.19
    L 54.81 135.09
    A 1.51 1.51 0.0 0 0 53.26 133.20
    L 47.07 133.60"
        />
        <path
          stroke="#a7b3c6"
          vector-effect="non-scaling-stroke"
          d="
    M 69.50 137.16
    L 72.13 137.06
    Q 72.36 137.05 72.43 137.26
    Q 72.48 137.42 72.53 137.58
    Q 72.59 137.74 72.42 137.80
    Q 71.95 137.95 71.59 138.25
    A 0.13 0.12 -66.0 0 0 71.67 138.47
    L 77.83 138.16"
        />
        <path
          stroke="#a7b3c6"
          vector-effect="non-scaling-stroke"
          d="
    M 82.25 144.24
    L 65.39 144.27
    Q 65.19 144.27 65.19 144.48
    L 65.19 144.75
    A 0.26 0.25 90.0 0 0 65.44 145.01
    L 82.16 145.05"
        />
        <path
          stroke="#a7b3c6"
          vector-effect="non-scaling-stroke"
          d="
    M 75.64 136.14
    L 69.37 136.72"
        />
        <path
          stroke="#a7b3c6"
          vector-effect="non-scaling-stroke"
          d="
    M 40.95 145.47
    Q 40.91 144.97 41.25 144.97
    Q 49.37 144.98 57.53 145.14
    A 0.39 0.38 0.0 0 0 57.92 144.76
    L 57.92 144.45
    Q 57.92 144.20 57.67 144.20
    L 40.78 144.24"
        />
        <path
          stroke="#a7b3c6"
          vector-effect="non-scaling-stroke"
          d="
    M 45.22 138.15
    L 50.97 138.51
    A 0.29 0.28 -37.2 0 0 51.05 137.95
    Q 50.63 137.86 50.43 137.60
    A 0.35 0.35 0.0 0 1 50.73 137.04
    Q 51.73 137.09 53.49 137.27
    Q 53.91 137.31 54.00 136.93
    Q 54.11 136.50 53.67 136.48
    L 47.09 136.12"
        />
        <path
          stroke="#b2877c"
          vector-effect="non-scaling-stroke"
          d="
    M 39.11 19.80
    A 0.47 0.47 0.0 0 1 38.39 20.26
    Q 37.42 19.64 36.49 20.19
    Q 35.94 20.52 36.54 20.74
    Q 37.70 21.15 38.85 20.82
    Q 39.46 20.64 39.61 20.02
    L 39.80 19.24
    A 0.24 0.24 0.0 0 0 39.36 19.05
    Q 39.07 19.46 39.11 19.80"
        />
        <path
          stroke="#b2877c"
          vector-effect="non-scaling-stroke"
          d="
    M 37.60 24.57
    C 38.33 23.59 38.55 22.80 40.04 22.46
    Q 40.60 22.33 40.72 21.77
    Q 41.04 20.37 42.25 19.70
    Q 42.62 19.50 42.48 19.09
    Q 42.43 18.92 42.28 18.77
    Q 41.93 18.40 41.72 18.86
    Q 40.66 21.18 38.02 23.00
    Q 37.33 23.48 37.28 24.45
    Q 37.25 25.03 37.60 24.57"
        />
        <path
          stroke="#8f5f83"
          vector-effect="non-scaling-stroke"
          d="
    M 81.10 20.93
    Q 80.85 20.87 80.59 20.96
    Q 80.47 21.00 80.45 21.12
    Q 80.44 21.24 80.54 21.36
    Q 80.60 21.42 80.68 21.40
    Q 81.70 21.11 82.74 21.03
    Q 83.08 21.00 83.20 20.77
    A 0.20 0.20 0.0 0 0 82.96 20.49
    L 81.91 20.88
    Q 81.51 21.02 81.10 20.93"
        />
        <path
          stroke="#774636"
          vector-effect="non-scaling-stroke"
          d="
    M 61.66 22.97
    L 62.18 23.03
    A 0.89 0.89 0.0 0 0 63.17 22.28
    Q 63.32 21.24 62.60 21.07
    Q 62.13 20.95 61.95 21.41
    Q 61.24 23.20 59.20 22.81
    A 0.23 0.23 0.0 0 0 59.04 23.23
    Q 59.86 23.74 60.68 23.20
    Q 61.13 22.91 61.66 22.97"
        />
        <path
          stroke="#774636"
          vector-effect="non-scaling-stroke"
          d="
    M 58.1628 23.6495
    A 1.05 0.88 10.4 0 0 57.2889 22.5945
    A 1.05 0.88 10.4 0 0 56.0972 23.2705
    A 1.05 0.88 10.4 0 0 56.9711 24.3255
    A 1.05 0.88 10.4 0 0 58.1628 23.6495"
        />
        <path
          stroke="#8f5f83"
          vector-effect="non-scaling-stroke"
          d="
    M 54.69 25.96
    Q 54.97 26.12 54.95 25.79
    Q 54.92 25.21 54.48 24.85
    Q 54.26 24.67 54.04 24.85
    Q 53.90 24.96 53.87 25.12
    Q 53.82 25.36 54.06 25.55
    Q 54.29 25.73 54.69 25.96"
        />
      </g>
      <path
        fill="#ed8c6b"
        d="
  M 35.63 36.88
  Q 33.68 39.26 32.11 42.52
  A 0.33 0.24 81.4 0 0 32.51 42.93
  Q 32.89 42.23 33.16 41.48
  Q 33.20 41.39 33.28 41.33
  Q 33.40 41.25 33.53 41.17
  A 0.27 0.26 69.7 0 1 33.93 41.36
  L 35.31 50.78
  C 30.51 52.42 24.24 51.26 23.08 45.62
  C 21.85 39.60 30.48 27.57 34.11 22.97
  C 35.58 21.11 35.69 19.27 36.47 17.12
  Q 36.67 16.55 37.26 16.57
  L 42.29 16.72
  Q 42.87 16.74 43.18 16.25
  L 44.57 14.01
  A 0.65 0.64 -61.8 0 1 45.38 13.76
  L 45.69 13.90
  Q 46.63 14.33 46.07 15.20
  L 43.64 19.01
  A 1.20 1.16 -36.4 0 0 43.49 19.90
  C 44.56 24.45 41.10 24.58 39.63 27.87
  Q 37.70 32.19 35.63 36.88
  Z
  M 39.11 19.80
  A 0.47 0.47 0.0 0 1 38.39 20.26
  Q 37.42 19.64 36.49 20.19
  Q 35.94 20.52 36.54 20.74
  Q 37.70 21.15 38.85 20.82
  Q 39.46 20.64 39.61 20.02
  L 39.80 19.24
  A 0.24 0.24 0.0 0 0 39.36 19.05
  Q 39.07 19.46 39.11 19.80
  Z
  M 37.60 24.57
  C 38.33 23.59 38.55 22.80 40.04 22.46
  Q 40.60 22.33 40.72 21.77
  Q 41.04 20.37 42.25 19.70
  Q 42.62 19.50 42.48 19.09
  Q 42.43 18.92 42.28 18.77
  Q 41.93 18.40 41.72 18.86
  Q 40.66 21.18 38.02 23.00
  Q 37.33 23.48 37.28 24.45
  Q 37.25 25.03 37.60 24.57
  Z"
      />
      <path
        fill="#ed8c6b"
        d="
  M 85.22 51.86
  Q 84.95 51.53 84.97 51.00
  Q 85.17 46.20 85.62 41.40
  C 85.79 39.62 85.23 38.38 84.16 37.12
  Q 81.73 32.27 79.34 27.76
  C 78.36 25.93 74.55 23.02 76.04 20.58
  Q 76.23 20.26 76.04 19.94
  L 73.44 15.58
  A 0.63 0.63 0.0 0 1 73.67 14.70
  L 74.01 14.51
  Q 74.61 14.17 75.00 14.74
  L 76.85 17.48
  Q 77.16 17.95 77.51 17.50
  Q 77.61 17.36 77.75 17.29
  Q 78.11 17.11 78.25 17.11
  L 82.07 17.08
  Q 82.63 17.08 82.86 17.58
  C 83.46 18.85 83.39 20.21 83.96 21.39
  C 87.22 28.10 98.07 36.05 96.80 43.90
  Q 95.39 52.64 85.22 51.86
  Z
  M 81.10 20.93
  Q 80.85 20.87 80.59 20.96
  Q 80.47 21.00 80.45 21.12
  Q 80.44 21.24 80.54 21.36
  Q 80.60 21.42 80.68 21.40
  Q 81.70 21.11 82.74 21.03
  Q 83.08 21.00 83.20 20.77
  A 0.20 0.20 0.0 0 0 82.96 20.49
  L 81.91 20.88
  Q 81.51 21.02 81.10 20.93
  Z"
      />
      <path
        fill="#010000"
        d="
  M 64.61 20.97
  Q 58.49 15.41 54.17 23.49
  Q 57.47 12.68 64.61 20.97
  Z"
      />
      <path
        fill="#ed8c6b"
        d="
  M 64.61 20.97
  Q 68.01 21.88 64.66 24.55
  L 62.74 27.25
  Q 62.37 27.35 62.14 27.66
  Q 60.20 28.51 58.29 28.20
  Q 57.81 27.78 57.42 27.86
  Q 55.08 25.91 54.88 26.79
  Q 53.17 26.14 53.07 25.25
  Q 52.94 24.12 54.17 23.49
  Q 58.49 15.41 64.61 20.97
  Z
  M 61.66 22.97
  L 62.18 23.03
  A 0.89 0.89 0.0 0 0 63.17 22.28
  Q 63.32 21.24 62.60 21.07
  Q 62.13 20.95 61.95 21.41
  Q 61.24 23.20 59.20 22.81
  A 0.23 0.23 0.0 0 0 59.04 23.23
  Q 59.86 23.74 60.68 23.20
  Q 61.13 22.91 61.66 22.97
  Z
  M 58.1628 23.6495
  A 1.05 0.88 10.4 0 0 57.2889 22.5945
  A 1.05 0.88 10.4 0 0 56.0972 23.2705
  A 1.05 0.88 10.4 0 0 56.9711 24.3255
  A 1.05 0.88 10.4 0 0 58.1628 23.6495
  Z
  M 54.69 25.96
  Q 54.97 26.12 54.95 25.79
  Q 54.92 25.21 54.48 24.85
  Q 54.26 24.67 54.04 24.85
  Q 53.90 24.96 53.87 25.12
  Q 53.82 25.36 54.06 25.55
  Q 54.29 25.73 54.69 25.96
  Z"
      />
      <path
        fill="#77818d"
        d="
  M 39.11 19.80
  Q 39.07 19.46 39.36 19.05
  A 0.24 0.24 0.0 0 1 39.80 19.24
  L 39.61 20.02
  Q 39.46 20.64 38.85 20.82
  Q 37.70 21.15 36.54 20.74
  Q 35.94 20.52 36.49 20.19
  Q 37.42 19.64 38.39 20.26
  A 0.47 0.47 0.0 0 0 39.11 19.80
  Z"
      />
      <path
        fill="#77818d"
        d="
  M 40.04 22.46
  C 38.55 22.80 38.33 23.59 37.60 24.57
  Q 37.25 25.03 37.28 24.45
  Q 37.33 23.48 38.02 23.00
  Q 40.66 21.18 41.72 18.86
  Q 41.93 18.40 42.28 18.77
  Q 42.43 18.92 42.48 19.09
  Q 42.62 19.50 42.25 19.70
  Q 41.04 20.37 40.72 21.77
  Q 40.60 22.33 40.04 22.46
  Z"
      />
      <path
        fill="#31319b"
        d="
  M 81.91 20.88
  L 82.96 20.49
  A 0.20 0.20 0.0 0 1 83.20 20.77
  Q 83.08 21.00 82.74 21.03
  Q 81.70 21.11 80.68 21.40
  Q 80.60 21.42 80.54 21.36
  Q 80.44 21.24 80.45 21.12
  Q 80.47 21.00 80.59 20.96
  Q 80.85 20.87 81.10 20.93
  Q 81.51 21.02 81.91 20.88
  Z"
      />
      <path
        fill="#010000"
        d="
  M 60.68 23.20
  Q 59.86 23.74 59.04 23.23
  A 0.23 0.23 0.0 0 1 59.20 22.81
  Q 61.24 23.20 61.95 21.41
  Q 62.13 20.95 62.60 21.07
  Q 63.32 21.24 63.17 22.28
  A 0.89 0.89 0.0 0 1 62.18 23.03
  L 61.66 22.97
  Q 61.13 22.91 60.68 23.20
  Z"
      />
      <ellipse
        fill="#010000"
        cx="0.00"
        cy="0.00"
        transform="translate(57.13,23.46) rotate(10.4)"
        rx="1.05"
        ry="0.88"
      />
      <path
        fill="#31319b"
        d="
  M 54.69 25.96
  Q 54.29 25.73 54.06 25.55
  Q 53.82 25.36 53.87 25.12
  Q 53.90 24.96 54.04 24.85
  Q 54.26 24.67 54.48 24.85
  Q 54.92 25.21 54.95 25.79
  Q 54.97 26.12 54.69 25.96
  Z"
      />
      <path
        fill="#010000"
        d="
  M 64.66 24.55
  Q 65.67 26.56 67.01 27.01
  A 0.72 0.71 -82.9 0 1 67.50 27.65
  Q 67.58 29.47 68.25 31.01
  Q 66.62 31.17 65.58 30.74
  Q 65.17 28.55 62.74 27.25
  L 64.66 24.55
  Z"
      />
      <path
        fill="#010000"
        d="
  M 54.88 26.79
  Q 55.08 25.91 57.42 27.86
  Q 57.05 28.35 56.86 29.20
  A 1.14 1.13 -6.8 0 1 56.25 29.97
  L 54.17 30.99
  Q 52.62 31.36 50.07 31.10
  Q 53.78 28.67 54.88 26.79
  Z"
      />
      <path
        fill="#010000"
        d="
  M 62.14 27.66
  L 62.18 28.92
  L 58.31 30.09
  Q 58.04 29.05 58.29 28.20
  Q 60.20 28.51 62.14 27.66
  Z"
      />
      <path
        fill="#ed8c6b"
        d="
  M 62.18 28.92
  L 62.17 30.52
  A 0.55 0.54 87.6 0 0 62.76 31.07
  L 64.05 30.97
  C 63.26 36.36 57.71 36.36 56.69 31.17
  Q 58.38 31.32 58.31 30.09
  L 62.18 28.92
  Z"
      />
      <path
        fill="#31319b"
        d="
  M 65.58 30.74
  Q 66.62 31.17 68.25 31.01
  C 74.24 31.74 78.86 34.74 84.16 37.12
  C 85.23 38.38 85.79 39.62 85.62 41.40
  Q 85.17 46.20 84.97 51.00
  Q 84.95 51.53 85.22 51.86
  L 84.73 52.39
  Q 84.39 52.77 83.90 52.64
  L 73.89 50.08
  Q 73.24 49.92 73.25 50.58
  L 73.26 53.28
  Q 73.26 53.82 72.72 53.84
  L 71.13 53.90
  L 49.56 54.70
  L 47.43 54.74
  Q 46.93 54.75 46.89 54.26
  L 46.46 49.78
  A 0.33 0.33 0.0 0 0 46.02 49.50
  L 36.24 53.03
  Q 35.82 53.18 35.73 52.75
  L 35.31 50.78
  L 33.93 41.36
  A 0.27 0.26 69.7 0 0 33.53 41.17
  Q 33.40 41.25 33.28 41.33
  Q 33.20 41.39 33.16 41.48
  Q 32.89 42.23 32.51 42.93
  A 0.33 0.24 81.4 0 1 32.11 42.52
  Q 33.68 39.26 35.63 36.88
  C 38.94 35.24 43.04 32.18 46.49 31.56
  Q 48.47 31.20 50.07 31.10
  Q 52.62 31.36 54.17 30.99
  L 56.69 31.17
  C 57.71 36.36 63.26 36.36 64.05 30.97
  L 65.58 30.74
  Z"
      />
      <path
        fill="#cff6ff"
        d="
  M 71.13 53.90
  C 75.20 70.07 75.57 81.00 76.71 100.88
  Q 76.74 101.43 76.19 101.45
  L 74.46 101.54
  L 61.70 101.98
  L 58.56 102.11
  L 46.04 102.64
  L 44.09 102.66
  Q 43.61 102.67 43.62 102.18
  Q 44.08 85.11 46.72 68.29
  C 47.36 64.22 48.83 59.30 49.56 54.70
  L 71.13 53.90
  Z"
      />
      <path
        fill="#010000"
        d="
  M 74.46 101.54
  L 74.44 103.32
  L 61.90 104.25
  L 61.70 101.98
  L 74.46 101.54
  Z"
      />
      <path
        fill="#010000"
        d="
  M 58.56 102.11
  Q 58.72 102.88 58.51 103.78
  L 45.99 103.85
  L 46.04 102.64
  L 58.56 102.11
  Z"
      />
      <path
        fill="#ed8c6b"
        d="
  M 74.44 103.32
  L 74.48 113.20
  L 62.55 113.79
  L 61.90 104.25
  L 74.44 103.32
  Z"
      />
      <path
        fill="#ed8c6b"
        d="
  M 58.51 103.78
  L 58.21 113.42
  L 53.38 113.52
  L 46.35 113.54
  L 45.99 103.85
  L 58.51 103.78
  Z"
      />
      <path
        fill="#0046e0"
        d="
  M 58.21 113.42
  L 57.58 134.41
  Q 57.57 134.68 57.84 134.63
  Q 57.97 134.61 58.11 134.58
  Q 57.39 135.25 56.87 135.32
  Q 57.37 133.58 56.88 132.27
  C 54.83 126.72 54.08 119.44 53.38 113.52
  L 58.21 113.42
  Z"
      />
      <path
        fill="#006fe8"
        d="
  M 74.48 113.20
  L 74.51 133.47
  L 69.61 133.21
  A 1.35 1.35 0.0 0 0 68.20 134.60
  Q 68.27 136.60 69.37 136.72
  Q 69.39 137.19 69.50 137.16
  L 63.88 134.65
  L 62.55 113.79
  L 74.48 113.20
  Z"
      />
      <path
        fill="#006fe8"
        d="
  M 46.35 113.54
  L 53.38 113.52
  C 54.08 119.44 54.83 126.72 56.88 132.27
  Q 57.37 133.58 56.87 135.32
  Q 56.19 136.16 54.91 136.56
  Q 54.38 136.73 54.52 136.19
  L 54.81 135.09
  A 1.51 1.51 0.0 0 0 53.26 133.20
  L 47.07 133.60
  L 46.35 113.54
  Z"
      />
      <path
        fill="#d7e5ff"
        d="
  M 56.87 135.32
  Q 57.39 135.25 58.11 134.58
  L 59.61 146.39
  Q 59.69 147.03 59.05 147.03
  L 41.72 147.03
  Q 41.19 147.03 41.04 146.52
  Q 40.90 146.07 40.95 145.47
  Q 40.91 144.97 41.25 144.97
  Q 49.37 144.98 57.53 145.14
  A 0.39 0.38 0.0 0 0 57.92 144.76
  L 57.92 144.45
  Q 57.92 144.20 57.67 144.20
  L 40.78 144.24
  Q 40.89 139.49 45.22 138.15
  L 50.97 138.51
  A 0.29 0.28 -37.2 0 0 51.05 137.95
  Q 50.63 137.86 50.43 137.60
  A 0.35 0.35 0.0 0 1 50.73 137.04
  Q 51.73 137.09 53.49 137.27
  Q 53.91 137.31 54.00 136.93
  Q 54.11 136.50 53.67 136.48
  L 47.09 136.12
  Q 45.76 134.47 47.07 133.60
  L 53.26 133.20
  A 1.51 1.51 0.0 0 1 54.81 135.09
  L 54.52 136.19
  Q 54.38 136.73 54.91 136.56
  Q 56.19 136.16 56.87 135.32
  Z"
      />
      <path
        fill="#d7e5ff"
        d="
  M 74.51 133.47
  Q 75.55 133.53 76.36 134.02
  Q 76.84 134.31 76.65 134.83
  Q 76.42 135.45 75.64 136.14
  L 69.37 136.72
  Q 68.27 136.60 68.20 134.60
  A 1.35 1.35 0.0 0 1 69.61 133.21
  L 74.51 133.47
  Z"
      />
      <path
        fill="#d7e5ff"
        d="
  M 63.88 134.65
  L 69.50 137.16
  L 72.13 137.06
  Q 72.36 137.05 72.43 137.26
  Q 72.48 137.42 72.53 137.58
  Q 72.59 137.74 72.42 137.80
  Q 71.95 137.95 71.59 138.25
  A 0.13 0.12 -66.0 0 0 71.67 138.47
  L 77.83 138.16
  Q 82.24 139.52 82.25 144.24
  L 65.39 144.27
  Q 65.19 144.27 65.19 144.48
  L 65.19 144.75
  A 0.26 0.25 90.0 0 0 65.44 145.01
  L 82.16 145.05
  L 82.04 146.54
  Q 82.00 147.03 81.51 147.03
  L 63.78 147.03
  Q 63.34 147.03 63.36 146.59
  L 63.88 134.65
  Z"
      />
      <path
        fill="#77818d"
        d="
  M 47.09 136.12
  L 53.67 136.48
  Q 54.11 136.50 54.00 136.93
  Q 53.91 137.31 53.49 137.27
  Q 51.73 137.09 50.73 137.04
  A 0.35 0.35 0.0 0 0 50.43 137.60
  Q 50.63 137.86 51.05 137.95
  A 0.29 0.28 -37.2 0 1 50.97 138.51
  L 45.22 138.15
  Q 45.98 137.99 46.25 137.74
  Q 46.44 137.56 46.44 137.33
  Q 46.42 136.90 45.99 136.96
  Q 45.66 137.00 45.50 136.86
  Q 45.30 136.70 45.55 136.62
  L 47.09 136.12
  Z"
      />
      <path
        fill="#77818d"
        d="
  M 75.64 136.14
  L 77.04 136.55
  Q 77.60 136.71 77.11 137.03
  Q 76.99 137.10 76.85 137.15
  Q 76.56 137.26 76.72 137.52
  Q 77.02 138.00 77.83 138.16
  L 71.67 138.47
  A 0.13 0.12 -66.0 0 1 71.59 138.25
  Q 71.95 137.95 72.42 137.80
  Q 72.59 137.74 72.53 137.58
  Q 72.48 137.42 72.43 137.26
  Q 72.36 137.05 72.13 137.06
  L 69.50 137.16
  Q 69.39 137.19 69.37 136.72
  L 75.64 136.14
  Z"
      />
      <path
        fill="#77818d"
        d="
  M 40.78 144.24
  L 57.67 144.20
  Q 57.92 144.20 57.92 144.45
  L 57.92 144.76
  A 0.39 0.38 -0.0 0 1 57.53 145.14
  Q 49.37 144.98 41.25 144.97
  Q 40.91 144.97 40.95 145.47
  Q 40.68 144.92 40.78 144.24
  Z"
      />
      <path
        fill="#77818d"
        d="
  M 82.25 144.24
  Q 82.28 144.63 82.16 145.05
  L 65.44 145.01
  A 0.26 0.25 -90.0 0 1 65.19 144.75
  L 65.19 144.48
  Q 65.19 144.27 65.39 144.27
  L 82.25 144.24
  Z"
      />
    </svg>
  );
};

export default SuccessGuy;
