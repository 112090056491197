import "./assets/main.css";
import "./assets/deposit.css";
import "../../../root/tracking/sentryConfig";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { QuoteProvider } from "./context/quoteContext";
import { FormDataProvider } from "./context/formContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LanguageProvider } from "./context/languageContext";
import { PaymentMethodProvider } from "./context/paymentMethodContext";
import { LocationProvider } from "./context/locationContext";
import Route from "./route";
import { CountriesProvider } from "./context/countriesContext";
import { ActionButtonProvider } from "./context/actionButtonContext";
import { StepsProvider } from "./context/stepsContext";
import { PrismaProvider } from "./context/prismaContext";
import * as Sentry from "@sentry/react";
import { LoaderProvider } from "./context/loaderContext";
import { WhatsappProvider } from "./context/whatsappContext";
import { useState } from "react";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
  // shadows: [
  //   "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  //   "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  //   "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  //   "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  // ],
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-input": {
            padding: "12px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            padding: "4.5px 12px",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: 10,
            backgroundColor: "white",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          backgroundColor: "#2F6DE0",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#FA9134",
          },
          "&.Mui-disabled": {
            fontSize: "5rem",
            background: "red",
            backgroundColor: "#9CBEFF",
            color: "#ffffff",
            borderRadius: "10px",
          },
        },
      },
    },
  },
});

export default function Root() {
  const [prismaActivation, setPrismaActivation] = useState(false);

  return (
    <Sentry.ErrorBoundary fallback={"Algo salió mal"}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <LoaderProvider>
            <LocationProvider>
              <LanguageProvider>
                <WhatsappProvider>
                  <CountriesProvider>
                    <QuoteProvider>
                      <FormDataProvider>
                        <PaymentMethodProvider>
                          <ActionButtonProvider>
                            <StepsProvider>
                              <PrismaProvider
                                prismaActivation={prismaActivation}
                              >
                                <Route
                                  setPrismaActivation={setPrismaActivation}
                                />
                              </PrismaProvider>
                            </StepsProvider>
                          </ActionButtonProvider>
                        </PaymentMethodProvider>
                      </FormDataProvider>
                    </QuoteProvider>
                  </CountriesProvider>
                </WhatsappProvider>
              </LanguageProvider>
            </LocationProvider>
          </LoaderProvider>
        </I18nextProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}
