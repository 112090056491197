// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whatsapp-support-container {
    position: fixed;
    bottom: 0.75rem;
    right: 1rem;
    z-index: 999999;
}

.whatsapp-support-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #2A62C9;
    padding: 1rem;
    border-radius: 6px;
}

.whatsapp-support-message {
    font-size: 13px;
    max-width: 232px;
    font-weight: 500;
    color: #fff;
    margin-right: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/WhatsappSupport/WhatsappSupport.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;IACf,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".whatsapp-support-container {\n    position: fixed;\n    bottom: 0.75rem;\n    right: 1rem;\n    z-index: 999999;\n}\n\n.whatsapp-support-box {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    background: #2A62C9;\n    padding: 1rem;\n    border-radius: 6px;\n}\n\n.whatsapp-support-message {\n    font-size: 13px;\n    max-width: 232px;\n    font-weight: 500;\n    color: #fff;\n    margin-right: 12px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
