import React from "react";
const Luggage = () => {
    return (
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33462 6.12891H26.2285C27.1101 6.12891 27.8298 6.84858 27.8298 7.73189V22.5265C27.8298 23.4115 27.1101 24.1312 26.2285 24.1312H3.33462C2.45483 24.1312 1.7334 23.4115 1.7334 22.5265V7.73189C1.7334 6.84858 2.45483 6.12891 3.33462 6.12891Z" fill="white" />
            <path d="M4.2442 6.91582H25.9926C26.8302 6.91582 27.5129 7.6003 27.5129 8.43962V22.4916C27.5129 23.3327 26.8284 24.0154 25.9926 24.0154H4.2442C3.4084 24.0154 2.72217 23.3327 2.72217 22.4916V8.43786C2.72217 7.59854 3.4084 6.91406 4.2442 6.91406V6.91582Z" fill="#57B7FF" />
            <path d="M3.3214 6.46268H3.42537V6.46092H26.2153C26.902 6.46092 27.461 7.02116 27.461 7.71007V22.5046C27.461 23.1935 26.902 23.7538 26.2153 23.7538H3.3214C2.63294 23.7538 2.07402 23.1935 2.07402 22.5046V7.71183C2.07402 7.0231 2.63461 6.46268 3.3214 6.46268ZM3.31964 24.4632H26.2136C27.2934 24.4632 28.1704 23.5842 28.1704 22.5046V7.71007C28.1704 6.63039 27.2916 5.7515 26.2136 5.7515H3.31964C2.23978 5.7515 1.36283 6.63046 1.36283 7.71007V22.5046C1.36283 23.5842 2.23978 24.4632 3.31964 24.4632Z" fill="#31319B" stroke="#31319B" stroke-width="0.207941" />
            <path d="M23.4482 24.1311C23.4482 24.3275 23.6073 24.4866 23.8038 24.4866C24.0002 24.4866 24.1594 24.3275 24.1594 24.1311V6.13053C24.1594 5.93409 24.0002 5.77494 23.8038 5.77494C23.6073 5.77494 23.4482 5.9341 23.4482 6.13053V24.1311ZM5.40189 24.1311C5.40189 24.3275 5.56107 24.4866 5.75749 24.4866C5.95392 24.4866 6.11308 24.3275 6.11308 24.1311V6.13053C6.11308 5.9341 5.95392 5.77494 5.75749 5.77494C5.56107 5.77494 5.40189 5.93409 5.40189 6.13053V24.1311Z" fill="#31319B" stroke="#31319B" stroke-width="0.207941" />
            <path d="M18.4057 2.37109H11.1562V6.1278H18.4057V2.37109Z" fill="white" />
            <path d="M18.5623 2.80469H11.6753V6.37312H18.5623V2.80469Z" fill="#57B7FF" />
            <path d="M11.1559 6.48501H18.4054C18.6018 6.48501 18.761 6.32584 18.761 6.12942V2.37271C18.761 2.17629 18.6018 2.01712 18.4054 2.01712H11.1559C10.9595 2.01712 10.8003 2.17629 10.8003 2.37271V6.12942C10.8003 6.32584 10.9595 6.48501 11.1559 6.48501ZM18.0516 2.7283V5.77559H11.5115V2.7283H18.0516Z" fill="#31319B" stroke="#31319B" stroke-width="0.207941" />
            <path d="M23.8038 9.54621H5.75749C5.61848 9.54621 5.50586 9.4336 5.50586 9.29459C5.50586 9.15558 5.61848 9.04297 5.75749 9.04297H23.8038C23.9428 9.04297 24.0554 9.15558 24.0554 9.29459C24.0554 9.4336 23.9428 9.54621 23.8038 9.54621Z" fill="#31319B" />
            <path d="M16.5563 3.90168H13.0089C12.8699 3.90168 12.7573 3.78906 12.7573 3.65006C12.7573 3.51105 12.8699 3.39844 13.0089 3.39844H16.5563C16.6953 3.39844 16.8079 3.51105 16.8079 3.65006C16.8079 3.78906 16.6953 3.90168 16.5563 3.90168Z" fill="#31319B" />
            <path d="M16.5563 4.89387H13.0089C12.8699 4.89387 12.7573 4.78125 12.7573 4.64225C12.7573 4.50324 12.8699 4.39062 13.0089 4.39062H16.5563C16.6953 4.39062 16.8079 4.50324 16.8079 4.64225C16.8079 4.78125 16.6953 4.89387 16.5563 4.89387Z" fill="#31319B" />
            <path d="M20.8089 25.7665H6.31168C6.23009 25.7665 6.16403 25.7004 6.16403 25.6188C6.16403 25.5372 6.23009 25.4712 6.31168 25.4712H20.8089C20.8905 25.4712 20.9565 25.5372 20.9565 25.6188C20.9565 25.7004 20.8905 25.7665 20.8089 25.7665Z" fill="#31319B" stroke="#31319B" stroke-width="0.207941" />
            <path d="M22.4124 25.7665H21.2633C21.1818 25.7665 21.1157 25.7004 21.1157 25.6188C21.1157 25.5372 21.1818 25.4712 21.2633 25.4712H22.4124C22.4939 25.4712 22.56 25.5372 22.56 25.6188C22.56 25.7004 22.4939 25.7665 22.4124 25.7665Z" fill="#31319B" stroke="#31319B" stroke-width="0.207941" />
            <path d="M23.3867 25.7665H22.8307C22.7491 25.7665 22.6831 25.7004 22.6831 25.6188C22.6831 25.5372 22.7491 25.4712 22.8307 25.4712H23.3867C23.4683 25.4712 23.5344 25.5372 23.5344 25.6188C23.5344 25.7004 23.4683 25.7665 23.3867 25.7665Z" fill="#31319B" stroke="#31319B" stroke-width="0.207941" />
            <path d="M19.8555 27.2899H11.3532C11.2716 27.2899 11.2055 27.2238 11.2055 27.1422C11.2055 27.0607 11.2716 26.9946 11.3532 26.9946H19.8555C19.9371 26.9946 20.0031 27.0607 20.0031 27.1422C20.0031 27.2238 19.9371 27.2899 19.8555 27.2899Z" fill="#31319B" stroke="#31319B" stroke-width="0.207941" />
            <path d="M11.0876 27.2899H10.4137C10.3321 27.2899 10.2661 27.2238 10.2661 27.1422C10.2661 27.0607 10.3321 26.9946 10.4137 26.9946H11.0876C11.1692 26.9946 11.2353 27.0607 11.2353 27.1422C11.2353 27.2238 11.1692 27.2899 11.0876 27.2899Z" fill="#31319B" stroke="#31319B" stroke-width="0.207941" />
            <path d="M10.1692 27.2899H9.52165C9.44006 27.2899 9.37399 27.2238 9.37399 27.1422C9.37399 27.0607 9.44006 26.9946 9.52165 26.9946H10.1692C10.2508 26.9946 10.3168 27.0607 10.3168 27.1422C10.3168 27.2238 10.2508 27.2899 10.1692 27.2899Z" fill="#31319B" stroke="#31319B" stroke-width="0.207941" />
            <path d="M22.3691 11.3742H4.75152C4.61581 11.3742 4.50586 11.2493 4.50586 11.0953C4.50586 10.9412 4.61581 10.8164 4.75152 10.8164H22.3691C22.5048 10.8164 22.6148 10.9412 22.6148 11.0953C22.6148 11.2493 22.5048 11.3742 22.3691 11.3742Z" fill="#31319B" />
            <path d="M19.0631 15.7032L19.0626 15.7026L18.1567 14.6097C18.1565 14.6095 18.1564 14.6093 18.1563 14.6092C18.131 14.5777 18.1179 14.5407 18.1179 14.5046V11.3726C18.1179 11.2822 18.1911 11.209 18.2815 11.209H20.1186C20.2091 11.209 20.2823 11.2822 20.2823 11.3726V14.5046C20.2823 14.5442 20.2682 14.5812 20.2447 14.6079L20.2434 14.6094L19.3132 15.7035L19.3129 15.7038C19.2829 15.7394 19.2375 15.7604 19.1884 15.7604H19.1618C19.1239 15.7543 19.0886 15.7344 19.0631 15.7032ZM8.41488 14.6099L8.41486 14.6099L8.41352 14.6114L7.48523 15.7055L7.48502 15.7057C7.45501 15.7413 7.40964 15.7623 7.36051 15.7623C7.31337 15.7623 7.26745 15.7409 7.23622 15.704C7.23615 15.7039 7.23608 15.7039 7.23602 15.7038L6.3264 14.6111C6.30115 14.5795 6.28808 14.5426 6.28808 14.5066V11.3746C6.28808 11.2842 6.36131 11.2109 6.45174 11.2109H8.2888C8.37923 11.2109 8.45245 11.2842 8.45245 11.3746V14.5066C8.45245 14.5462 8.43838 14.5832 8.41488 14.6099Z" fill="#59D3C2" stroke="#31319B" stroke-width="0.230466" />
        </svg>
    );
};

export default Luggage;
