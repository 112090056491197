import React from "react";
const A365Logo = ({ width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0.00 0.00 61.00 56.00"
      width={width}
    >
      <path
        fill="#59d3c2"
        d="
  M 5.11 10.00
  Q 4.58 12.32 1.94 11.36
  Q 1.42 11.17 1.63 10.67
  L 5.59 1.28
  A 2.07 2.07 0.0 0 1 9.41 1.28
  L 13.44 10.82
  Q 13.63 11.27 13.17 11.42
  Q 10.56 12.26 9.87 10.11
  A 0.86 0.85 81.1 0 0 9.06 9.51
  L 5.75 9.49
  Q 5.22 9.49 5.11 10.00
  Z
  M 8.45 6.29
  L 7.86 4.74
  A 0.35 0.35 0.0 0 0 7.21 4.71
  L 6.43 6.33
  Q 6.11 6.99 6.84 6.99
  L 7.96 7.00
  A 0.52 0.52 0.0 0 0 8.45 6.29
  Z"
      />
      <path
        fill="#59d3c2"
        d="
  M 20.55 8.50
  Q 20.73 7.64 19.75 7.42
  Q 17.40 6.87 15.93 6.32
  A 1.32 1.29 84.3 0 1 15.25 5.75
  C 12.12 0.40 19.57 -0.80 22.88 1.27
  A 0.66 0.65 34.5 0 1 23.06 2.22
  L 22.29 3.27
  A 0.71 0.70 35.3 0 1 21.33 3.43
  Q 19.68 2.29 18.30 2.93
  Q 16.71 3.67 18.40 4.13
  C 20.42 4.68 23.44 5.09 23.77 7.99
  Q 24.06 10.61 20.77 11.62
  Q 17.84 12.52 14.56 10.10
  A 0.62 0.61 -50.9 0 1 14.47 9.19
  L 15.30 8.27
  Q 15.72 7.80 16.27 8.12
  Q 18.00 9.15 20.02 8.97
  Q 20.46 8.94 20.55 8.50
  Z"
      />
      <path
        fill="#59d3c2"
        d="
  M 28.82 2.88
  Q 27.19 3.65 28.92 4.15
  C 31.39 4.86 35.47 5.81 33.81 9.49
  C 32.31 12.84 27.70 11.82 25.20 10.31
  A 0.85 0.84 -52.9 0 1 25.02 9.00
  L 25.65 8.34
  Q 26.29 7.67 27.02 8.24
  Q 28.18 9.13 30.28 8.96
  Q 30.78 8.92 30.95 8.64
  Q 31.04 8.48 31.03 8.30
  Q 30.99 7.82 30.55 7.66
  Q 28.79 7.03 27.02 6.50
  C 21.63 4.88 27.01 -2.43 33.26 1.17
  A 0.65 0.64 29.8 0 1 33.50 2.04
  L 32.83 3.24
  A 0.62 0.62 0.0 0 1 31.97 3.47
  Q 30.03 2.31 28.82 2.88
  Z"
      />
      <rect
        fill="#59d3c2"
        x="-1.55"
        y="-5.59"
        transform="translate(37.29,5.91) rotate(0.1)"
        width="3.10"
        height="11.18"
        rx="0.26"
      />
      <path
        fill="#59d3c2"
        d="
  M 44.30 2.99
  Q 43.15 3.76 44.48 4.13
  C 45.89 4.52 48.28 4.97 49.26 6.37
  C 50.74 8.47 48.92 11.18 46.79 11.63
  Q 43.46 12.31 40.55 10.03
  Q 40.09 9.67 40.49 9.23
  L 41.43 8.18
  A 0.66 0.66 0.0 0 1 42.27 8.06
  Q 44.04 9.17 46.17 8.97
  A 0.56 0.55 2.8 0 0 46.66 8.53
  Q 46.79 7.84 45.97 7.61
  Q 44.22 7.12 42.49 6.51
  C 38.95 5.27 40.73 0.82 44.02 0.34
  Q 46.72 -0.05 48.80 1.14
  Q 49.58 1.59 49.08 2.34
  L 48.60 3.04
  A 1.01 1.01 0.0 0 1 47.21 3.33
  Q 45.47 2.22 44.30 2.99
  Z"
      />
      <path
        fill="#59d3c2"
        d="
  M 53.76 3.01
  L 51.07 2.99
  A 0.33 0.33 0.0 0 1 50.74 2.66
  L 50.74 0.58
  A 0.33 0.33 0.0 0 1 51.07 0.25
  L 60.16 0.25
  A 0.33 0.33 0.0 0 1 60.49 0.57
  L 60.52 2.65
  A 0.33 0.33 0.0 0 1 60.19 2.99
  L 57.47 3.00
  A 0.33 0.33 0.0 0 0 57.14 3.33
  L 57.16 11.17
  A 0.33 0.33 0.0 0 1 56.83 11.50
  L 54.40 11.50
  A 0.33 0.33 0.0 0 1 54.07 11.17
  L 54.09 3.34
  A 0.33 0.33 0.0 0 0 53.76 3.01
  Z"
      />
      <path
        fill="#59d3c2"
        d="
  M 61.00 41.67
  L 61.00 44.48
  C 58.80 52.21 53.97 56.92 45.60 54.86
  Q 42.66 54.13 39.79 51.26
  Q 39.41 50.87 39.03 51.25
  C 32.36 57.88 19.40 55.94 18.70 45.05
  Q 18.47 41.45 18.02 40.55
  Q 16.23 36.98 11.32 37.59
  Q 11.00 37.63 11.00 37.30
  L 11.02 31.52
  Q 11.02 31.24 11.30 31.23
  C 19.72 30.92 25.84 37.20 24.68 45.79
  Q 24.62 46.28 24.89 46.68
  C 26.63 49.26 30.09 49.88 32.63 48.42
  C 39.49 44.49 34.25 34.32 26.13 38.64
  Q 25.64 38.90 25.36 38.42
  Q 24.38 36.70 23.27 35.10
  Q 22.29 33.68 20.66 32.66
  Q 19.32 31.83 18.01 30.97
  Q 17.62 30.72 17.76 30.28
  C 20.17 22.95 10.79 18.70 6.91 25.91
  Q 6.62 26.45 6.01 26.45
  L 1.99 26.43
  A 1.22 1.22 0.0 0 1 0.83 24.84
  C 5.78 9.36 29.08 17.12 23.02 32.54
  Q 22.58 33.67 23.65 33.10
  C 31.60 28.90 40.87 33.24 41.79 42.38
  Q 42.05 44.88 42.89 46.06
  C 45.41 49.55 51.65 50.57 54.09 46.38
  C 56.08 42.95 54.35 38.97 50.65 37.75
  C 48.47 37.04 43.04 38.50 42.94 35.01
  Q 42.69 26.94 42.98 19.71
  A 2.55 2.54 1.0 0 1 45.53 17.26
  L 58.67 17.25
  Q 59.35 17.25 59.35 17.92
  L 59.34 23.21
  Q 59.34 23.75 58.79 23.75
  L 49.99 23.75
  A 0.26 0.26 0.0 0 0 49.73 24.01
  L 49.73 31.26
  Q 49.73 31.59 50.05 31.64
  Q 59.46 33.31 61.00 41.67
  Z"
      />
      <path
        fill="#59d3c2"
        d="
  M 25.20 23.92
  A 0.24 0.23 -31.8 0 1 24.87 23.85
  L 22.14 19.29
  Q 22.02 19.10 22.21 18.98
  Q 25.92 16.47 27.61 16.36
  Q 37.23 15.77 40.91 24.51
  Q 41.01 24.74 40.78 24.80
  L 35.55 26.32
  Q 35.32 26.39 35.23 26.17
  Q 33.67 22.70 30.09 22.10
  C 28.23 21.79 26.73 22.90 25.20 23.92
  Z"
      />
      <path
        fill="#59d3c2"
        d="
  M 19.86 53.03
  Q 10.76 57.43 4.84 52.15
  Q 0.92 48.66 0.77 43.73
  Q 0.75 43.01 1.47 43.00
  L 6.20 43.00
  Q 6.86 43.00 6.85 43.65
  Q 6.82 45.22 7.24 45.81
  Q 11.40 51.57 17.06 47.41
  Q 17.28 47.25 17.41 47.50
  L 20.01 52.61
  Q 20.15 52.89 19.86 53.03
  Z"
      />
    </svg>
  );
};

export default A365Logo;
