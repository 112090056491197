import React, { createContext, useContext, useState } from "react";

const StepsContext = createContext();
export const useSteps = () => {
  return useContext(StepsContext);
};
export const StepsProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);

  const contextValue = {
    activeStep,
    setActiveStep,
  };

  return (
    <StepsContext.Provider value={contextValue}>
      {children}
    </StepsContext.Provider>
  );
};
