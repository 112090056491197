import React from "react";
import { FormGroup, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const PolicyForm = ({ control, Controller, errors }) => {
  const { t } = useTranslation();

  const ERROR_MESSAGES = {
    form: {
      INVALID_EMAIL: t("form.error.invalidEmail"),
    },
  };

  return (
    <>
      <FormGroup className="mb-4">
        <label className="font-bold text-xs mb-2 text-[#414D58]">
          {t("form.label.email")}
        </label>
        <Controller
          name={`other_email_send_voucher`}
          control={control}
          rules={{
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: ERROR_MESSAGES.form.INVALID_EMAIL,
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              autoComplete="none"
              type="email"
              fullWidth
              error={!!errors[`other_email_send_voucher`]}
              helperText={errors[`other_email_send_voucher`]?.message}
              placeholder="Ej: John@lennon.com"
            />
          )}
        />
      </FormGroup>
    </>
  );
};

export default PolicyForm;
