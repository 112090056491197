import React, { createContext, useContext, useEffect, useState } from "react";
import { quoteFetchController } from "../dataController/dataController";
import { useLocation } from "./locationContext";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const QuoteContext = createContext({
  data: null,
});
export function QuoteProvider({ children }) {
  const [data, setData] = useState(null);
  const [linkExpired, setLinkExpired] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const { getParamFromUrl } = useLocation();
  const reserveId = getParamFromUrl("reserve_id");
  const { transactionId } = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await quoteFetchController.fetchData(transactionId);
        setData(result.data);
      } catch (error) {
        console.warn(error);
      } finally {
        // console.warn("done quoteContext");
      }
    };

    if (!reserveId) {
      fetchData();
    }
  }, [transactionId, reserveId]);

  const isLink = data?.hasOwnProperty("beneficiaries");

  useEffect(() => {
    if (isLink) {
      if (data?.quote.from == 155) {
        i18n.changeLanguage("pt-BR");
      }

      const link_closed_at = dayjs(data?.quote_detail?.link_closed_at?.date);
      const link_created_at = dayjs(data?.quote_detail?.link_created_at?.date);
      const link_date_now = dayjs(data?.quote_detail?.link_date_now?.date);
      const to_date = dayjs(data?.quote?.to_date);

      const now = link_date_now;
      const close_at = link_closed_at;
      const date_to = to_date;
      const created = link_created_at;

      // const now = dayjs("2024-09-09 16:00:00");
      // const close_at = dayjs("2024-09-10 15:00:00");
      // const date_to = dayjs("2024-09-11");

      // const now = dayjs(toDay);

      let date = new Date();
      let options = {
        timeZone: "America/Argentina/Buenos_Aires",
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      };

      let toDay = date.toLocaleDateString([], options);
      console.warn("date ---> ", toDay);

      const link_expired = now.isAfter(close_at) || now.isSame(close_at);

      setLinkExpired(link_expired);

      // salidas 2 dias despues

      if (date_to.isAfter(dayjs().add(1, "d"))) {
        const countdown = close_at.diff(now, "s");

        if (countdown > 0) {
          setCountdown(countdown);
        }
      } else {
        const date_to_formatted = date_to.format("DD-MM-YY");
        const tomorrow = dayjs().add(1, "d").format("DD-MM-YY");

        if (
          date_to_formatted === tomorrow ||
          date_to_formatted === now.format("DD-MM-YY")
        ) {
          //24hs del dia de hoy

          const time_lapse = dayjs()
            .set("hour", 23)
            .set("minute", 59)
            .set("second", 59);

          const countdown = time_lapse.diff(now, "s");

          setCountdown(countdown);
        }
      }
    }
  }, [isLink]);

  const quote = isLink
    ? {
        quote: {
          ...data?.quote,
          ...data?.quote_detail,
          beneficiaries: [...data?.beneficiaries],
          assistpay: { countdown: countdown },
        },
      }
    : data;

  const value = {
    data: quote,
    isLink,
    linkExpired,
  };

  return (
    <QuoteContext.Provider value={value}>{children}</QuoteContext.Provider>
  );
}

export function useQuote() {
  const context = useContext(QuoteContext);
  if (!context) {
    throw new Error("useData debe ser utilizado dentro de un DataProvider");
  }
  return context;
}
