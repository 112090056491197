import React, { useCallback, useEffect, useState } from "react";
import { Button, FormControl, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useLocation } from "../../context/locationContext";
import { useForm, Controller } from "react-hook-form";
import * as paymentProcessHelper from "../../helpers/paymentProcessHelper";
import { useFormData } from "../../context/formContext";
import { useLoader } from "../../context/loaderContext";
import TransferCard from "../TransferCard";

const useStyles = makeStyles(
  () => ({
    transferDataFormButton: {
      backgroundColor: "#7BD0C2 !important",
      "&:hover": {
        backgroundColor: "#FA9134 !important",
      },
    },
  }),
  { classNamePrefix: "transferDataForm" }
);

const TransferDataForm = ({ formRef }) => {
  const { t } = useTranslation();
  const { parsedData } = useFormData();
  const { setLoader } = useLoader();
  const [emitedFlag, setEmitedFlag] = useState(false);
  const [linkSuccess, setLinkSuccess] = useState(null);

  const ERROR_MESSAGES = {
    form: {
      REQUIRED: t("form.error.required"),
      INVALID_EMAIL: t("form.error.invalidEmail"),
    },
  };
  const { basePath, transactionId } = useLocation();

  const classes = useStyles();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const sendEmail = async (data, target) => {
    const result = await paymentProcessHelper.sendDepositNotification(data);
    // if (result?.data?.status) {
    if (target === "form") {
      const url = `${basePath}/success?id=${data?.reserve_id}&method=deposit&form=true&email=${data?.email}`;
      window.location.href = url;
    } else {
      setEmitedFlag(true);
      setLoader(false);
    }
    // } else {
    //   setLoader(false);
    //   alert("Error!! sendEmail");
    // }
  };

  const emit = useCallback(
    async (email, target) => {
      setLoader(true);
      const payload = {
        ...parsedData,
        method: "deposit",
        payment_method: "deposit",
      };

      const result = await paymentProcessHelper.emit(payload);
      if (result?.reserve_status) {
        const data = {
          reserve_id: result?.reserve_id,
          email: email,
          payment_method: "deposit",
        };
        setLinkSuccess(
          `${basePath}/success?id=${result?.reserve_id}&method=deposit&form=true&email=${email}`
        );
        sendEmail(data, target);
      }
    },
    [parsedData]
  );

  const onSubmit = (data, target) => {
    if (!emitedFlag) {
      if (data) {
        emit(data?.email, target);
      }
    } else {
      window.location.href = linkSuccess;
    }
  };
  useEffect(() => {
    setValue("email", parsedData["beneficiary[email][0]"]);
  }, [parsedData]);

  return (
    <div>
      <div className="mb-8 flex items-center justify-center lg:justify-start">
        <TransferCard
          onSubmit={handleSubmit((e) => onSubmit(e, "clipboard"))}
        />
      </div>
      <h1 className="font-bold text-2xl text-[#006FE8] mb-5">
        {t("steps.payment.transferDataViaEmail")}
      </h1>
      <FormControl fullWidth>
        <label
          className="text-[#414D58] font-bold text-xs mb-2"
          aria-controls="#email-input"
        >
          {t("form.label.email")}
        </label>
        <div>
          <form
            onSubmit={handleSubmit((e) => onSubmit(e, "form"))}
            ref={formRef}
          >
            <div className="mb-8">
              <Controller
                name={`email`}
                control={control}
                rules={{
                  required: ERROR_MESSAGES.form.REQUIRED,
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: ERROR_MESSAGES.form.INVALID_EMAIL,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="email"
                    fullWidth
                    error={!!errors[`email`]}
                    helperText={errors[`email`]?.message}
                  />
                )}
              />
            </div>
            <div className="text-center mb-8 flex items-center justify-center lg:justify-start">
              <Button
                className={classes.transferDataFormButton}
                variant="contained"
                type="submit"
              >
                <span className="text-[20px] mx-6">
                  {t("form.button.receiveDataByEmail")}
                </span>
              </Button>
            </div>
          </form>
        </div>
      </FormControl>
    </div>
  );
};

export default TransferDataForm;
