import { useTranslation } from "react-i18next";
import { dateFormat } from "./dateFormat";
import { parseFullName } from "parse-full-name";

export const mapPurchaseDetailData = (data, isLink, locale) => {
  const {
    name,
    country_id,
    date_from,
    date_to,
    input_total,
    total_price,
    base_price,
    total_price_back,
    upgrade_list,
    total_price_usd,
    to,
    to_id,
    total_insured,
    from_date,
    to_date,
    total_price_promo,
    total_price_promo_usd,
    currency_symbol,
    discount,
    discount_percent,
    promo_percent,
    deposit_discount,
    source_country_id,
  } = data;

  return {
    name,
    country_id: isLink ? source_country_id : country_id,
    date_from: dateFormat(isLink ? from_date : date_from, locale),
    date_to: dateFormat(isLink ? to_date : date_to, locale),
    total_pax: isLink ? total_insured : input_total,
    destiny: isLink ? to_id : to,
    total_price: total_price,
    base_price: base_price,
    total_price_back: total_price_back,
    total_discount: null,
    upgrade_list: upgrade_list || [],
    total_price_usd: total_price_usd,
    total_price_promo,
    total_price_promo_usd,
    currency_symbol: currency_symbol,
    discount: discount,
    discount_percent: isLink ? discount_percent : null,
    promo_percent: isLink ? promo_percent : null,
    deposit_discount: null,
  };
};

export const mapPassengersData = (inputObj) => {
  const outputObj = {};
  const BENEFICIARY_KEY = "beneficiary";
  const BIRTH_DATE_KEY = "birth_date";
  const CONTACT_PHONE_KEY = "contact_phone";
  const CONTACT_NAME_KEY = "contact_name";
  const UPGRADE_KEY = "upgrade";
  const FULL_NAME = "fullName";
  const DOC_NUMBER = "doc_number";

  const processBirthDate = (key, value, index) => {
    const [day, month, year] = value.split("/");
    outputObj[`${BENEFICIARY_KEY}[bday][${index}]`] = day;
    outputObj[`${BENEFICIARY_KEY}[bmonth][${index}]`] = month;
    outputObj[`${BENEFICIARY_KEY}[byear][${index}]`] = year;
  };

  const processContactInfo = (inputName, value) => {
    const name = inputName.split("_");
    const newKey = `${BENEFICIARY_KEY}[emergency][${name[1]}]`;
    outputObj[newKey] = value;
  };

  const processUpgradeData = (inputName, value, index) => {
    const newKey = `${BENEFICIARY_KEY}[${UPGRADE_KEY}][${index}][${value}]`;
    outputObj[newKey] = value;
  };

  const processOtherData = (inputName, value, index) => {
    const newKey = `${BENEFICIARY_KEY}[${inputName}][${index}]`;
    outputObj[newKey] = value;
  };

  const processFullName = (value, index) => {
    const parsedName = parseFullName(value);
    const name = `${parsedName.first} ${parsedName?.middle}`;
    const lastname = parsedName?.last;
    outputObj[`${BENEFICIARY_KEY}[name][${index}]`] = name.trim();
    outputObj[`${BENEFICIARY_KEY}[lastname][${index}]`] = lastname.trim();
  };

  const processPassDocNumber = (value, index) => {
    if (value) {
      const parsedDoc = value.replace(/\.|-/gi, "");
      outputObj[`${BENEFICIARY_KEY}[doc_number][${index}]`] = parsedDoc;
    }
  };

  Object.keys(inputObj).forEach((key) => {
    const value = inputObj[key];
    const match = key.match(/^(.+)_(\d+)$/);

    if (match) {
      const [, inputName, index] = match;
      const upgrade = inputName.split("_");

      if (inputName === BIRTH_DATE_KEY) {
        processBirthDate(key, value, index);
      } else if (inputName === FULL_NAME) {
        processFullName(value, index);
      } else if (inputName === DOC_NUMBER) {
        processPassDocNumber(value, index);
      } else if (
        (inputName === CONTACT_PHONE_KEY || inputName === CONTACT_NAME_KEY) &&
        value
      ) {
        processContactInfo(inputName, value);
      } else if (inputName === `${UPGRADE_KEY}_${upgrade[1]}` && value) {
        processUpgradeData(inputName, upgrade[1], index);
      } else if (typeof value !== "undefined" && value) {
        processOtherData(inputName, value, index);
      }
    } else if (typeof value !== "undefined" || value === "" || value) {
      outputObj[key] = value;
    }
  });

  return outputObj;
};

export const mapUpgradesData = (upgrades) => {
  const { t } = useTranslation();

  return upgrades.map((row) => {
    if (row.name.trim() === "EMBARAZO") {
      return {
        ...row,
        title: t("upgrades.pregnant.title"),
        description: t("upgrades.pregnant.description"),
        note: t("upgrades.pregnant.note"),
        label: t("upgrades.pregnant.name"),
      };
    }
    if (
      row.name.trim() === "TECNOLOGÍA PROTEGIDA" ||
      row.name.trim() === "TECNOLOGIA PROTEGIDA"
    ) {
      return {
        ...row,
        title: t("upgrades.technology.title"),
        description: t("upgrades.technology.description"),
        note: t("upgrades.technology.note"),
        label: t("upgrades.technology.name"),
      };
    }
    if (
      row.name.trim() === "ROTURA DE EQUIPAJE" ||
      row.name.trim() === "ROTURA DE EQUIPAJE."
    ) {
      return {
        ...row,
        title: t("upgrades.luggage.title"),
        description: t("upgrades.luggage.description"),
        note: t("upgrades.luggage.note"),
        label: t("upgrades.luggage.name"),
      };
    }
    if (
      row.name.trim() === "COBERTURA PARA MASCOTAS" ||
      row.name.trim() === "COBERTURA PARA MASCOTAS."
    ) {
      return {
        ...row,
        title: t("upgrades.pet.title"),
        description: t("upgrades.pet.description"),
        note: t("upgrades.pet.note"),
        label: t("upgrades.pet.name"),
      };
    }
    return row;
  });
};

export const mapBillingData = (
  reserveData,
  userCountry = { name: "Argentina" },
  method = "Paypal"
) => {
  return {
    reserve_id: reserveData.reserve_id,
    seller_id: reserveData.seller,
    entity_id: reserveData.entity,
    pay_method: method,
    payment_type: method,
    email: reserveData["beneficiary[email][0]"],
    phone_type: "mobile",
    country_code: reserveData.country,
    area_code: reserveData.country,
    phone: false,
    fiscal_type: "3",
    fiscal_doc_number: reserveData["beneficiary[doc_number][0]"],
    name: `${reserveData["beneficiary[name][0]"]} ${reserveData["beneficiary[lastname][0]"]}`,
    city: userCountry?.name,
    street: userCountry?.name,
    doc_type: "3",
  };
};
