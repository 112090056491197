import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import * as paymentProcessHelper from "../../helpers/paymentProcessHelper";
import { useFormData } from "../../context/formContext";
import { useCountries } from "../../context/countriesContext";
import { mapBillingData } from "../../helpers/dataMapper";
import { useLocation } from "../../context/locationContext";
import { useQuote } from "../../context/quoteContext";
import { Alert } from "@mui/material";
import WhatsappSupport from "../WhatsappSupport/WhatsappSupport";
import { useSteps } from "../../context/stepsContext";
import { useActionButton } from "../../context/actionButtonContext";

const PayPalButton = ({ purcheaseDetails }) => {
  const { parsedData } = useFormData();
  const [reserveData, setReserveData] = useState(parsedData);
  const { countries } = useCountries();
  const [billingState, setBillingState] = useState(false);
  const { basePath } = useLocation();
  const { isLink } = useQuote();
  const [requestError, setRequestError] = useState(null);

  const userCountry = countries.find(
    (country) => country.id === reserveData.country
  );

  const billingData = useMemo(() => {
    return reserveData?.reserve_id
      ? mapBillingData(reserveData, userCountry, "Paypal")
      : null;
  }, [reserveData]);

  const { name, country_id, total_price, total_price_back, total_price_usd } =
    purcheaseDetails || {};

  const price = isLink ? total_price : total_price_back;
  const currency = country_id === 199 ? "EUR" : "USD" || "USD";
  const totalAmount = country_id === 199 ? price : total_price_usd;

  const initialOptions = {
    clientId:
      "AV_oKhYnzehOvdoe9lCyGpIgx-PDIIXCzltfvhKL-K4-5WWg9xfSoRN4u8sZbu5fMfDdJxup7i89VZAk",
    //clientId:
    //"AW6qubKYqoiBZQWnAWUeoA9FTaV9jc16LuqOBDj-Z3m_wqwgu2B2Ysn_an3JLZIGQF6tXt4nU-ojIbAX",
  };

  if (country_id === 199) {
    initialOptions.clientId += `&currency=${currency}`;
  }

  const createOrder = useCallback(
    (data, actions) => {

      const result = emitAux();

      if (totalAmount) {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: totalAmount,
                currency_code: currency,
                description: name,
              },
            },
          ],
        });
      }
      return null;
    },
    [totalAmount, currency, name]
  );

  const emit = useCallback(
    async (data) => {

      if (parsedData?.discode_dual) {
        const dual_code = parsedData?.discode_dual || null;
        if (dual_code) {
          parsedData["discode_dual"] = dual_code;
        }
      }

      const result = await paymentProcessHelper.emit(parsedData);

      if (result.reserve_status) {
        setReserveData((prev) => ({
          ...prev,
          ...{
            reserve_id: result?.reserve_id,
            reserve_status: result?.reserve_status,
            orderID: data.orderID,
            payerID: data.payerID,
          },
        }));
      } else {
        alert("Error!!");
      }
    },
    [parsedData]
  );

  const emitAux = useCallback(
    async () => {
      
      if (parsedData?.discode_dual) {
        const dual_code = parsedData?.discode_dual || null;
        if (dual_code) {
          parsedData["discode_dual"] = dual_code;
        }
      }

      const result = await paymentProcessHelper.emit(parsedData);

      // if (result.reserve_status) {
      //   setReserveData((prev) => ({
      //     ...prev,
      //     ...{
      //       reserve_id: result?.reserve_id,
      //       reserve_status: result?.reserve_status,
      //     },
      //   }));
      // } else {
      //   alert("Error!!");
      // }
    },
    [parsedData]
  );


  const endFollowProcess = useCallback(async () => {
    await paymentProcessHelper.endFollowProcess(
      parsedData,
      reserveData,
      "Paypal"
    );
  }, [reserveData, parsedData]);

  const emitVoucher = useCallback(async () => {
    const success = await paymentProcessHelper.emitVoucher(
      parsedData,
      reserveData
    );

    if (success?.data?.status) {
      endFollowProcess();
      window.location.href = `${basePath}/success?reserve_id=${reserveData?.reserve_id}&method=paypal`;
    } else {
      alert("Error!!");
    }
    console.info("emitVoucher done");
  }, [reserveData, parsedData]);

  const fetchBilling = useCallback(async () => {
    const result = await paymentProcessHelper.fetchBilling(billingData);
    setBillingState(result.status);
    console.info("fetchBilling done");
  }, [billingData]);

  const fetchPay = useCallback(
    async (data) => {
      const body = {
        method: "Paypal",
        orderID: !isLink ? reserveData.orderID : data.orderID,
        payerID: !isLink ? reserveData.payerID : data.payerID,
        reserveID: reserveData.reserve_id,
        country_id: reserveData.country,
      };

      const success = await paymentProcessHelper.fetchPay(body);

      if (success?.data?.status) {
        await emitVoucher();
      } else {
        console.error("fetchPay Paypal", success?.data?.err);
        setRequestError(success?.data?.msg);
        if (!isLink) {
          setBillingState(false);
          setReserveData(parsedData);
        }
      }
      console.info("fetchPay done");
    },
    [reserveData, isLink, parsedData, setBillingState, setReserveData]
  );

  const onSubmit = useCallback(
    (data) => {

      setRequestError(null);
      if (!isLink) {
        emit(data);
      } else {
        fetchPay(data);
      }
    },
    [isLink]
  );

  useEffect(() => {
    if (billingData && !isLink) {
      fetchBilling();
    }
  }, [billingData, isLink]);

  useEffect(() => {
    if (billingState && !isLink) {
      fetchPay();
    }
  }, [billingState, isLink]);

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      onSubmit(data, actions);
    });
  };

  return (
    <>
      {requestError && <WhatsappSupport reserve_id={reserveData?.reserve_id} />}
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons createOrder={createOrder} onApprove={onApprove} on />
      </PayPalScriptProvider>
      <Alert
        severity="error"
        id="card-errors"
        className={!requestError ? "!hidden" : "flex"}
      >
        {requestError}
      </Alert>
    </>
  );
};

export default PayPalButton;
