import React from "react";
import Technology from "./svg/Technology";
import Pregnant from "./svg/Pregnant";
import Pet from "./svg/Pet";
import Luggage from "./svg/Luggage";

import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

const Illustrations = ({ name }) => {
  const renderIllustration = () => {
    switch (name) {
      case "EMBARAZO":
        return <Pregnant />;
      case "TECNOLOGÍA PROTEGIDA":
      case "TECNOLOGIA PROTEGIDA":
        return <Technology />;
      case "ROTURA DE EQUIPAJE":
      case "ROTURA DE EQUIPAJE.":
        return <Luggage />;
      case "COBERTURA PARA MASCOTAS":
      case "COBERTURA PARA MASCOTAS.":
        return <Pet />;
      default:
        return (
          <div className="w-[42px] h-[41px] flex items-center justify-center">
            <ImageNotSupportedIcon
              style={{ color: "#ccc", fontSize: "28px" }}
            />
          </div>
        );
    }
  };

  return renderIllustration();
};
export default Illustrations;
