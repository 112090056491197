import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { useTranslation } from "react-i18next";
import { convertDate } from "../../helpers/dateFormat";
import DateRangeIcon from "@mui/icons-material/DateRange";
const CustomerDetail = ({ detail }) => {
  const { t } = useTranslation();

  return (
    <table className="table-auto w-full mb-8">
      <tbody>
        <tr>
          <td>
            <PersonIcon style={{ fill: "#675CE5" }} />
            <span className="ml-2 font-semibold text-[#1B2225]">
              {`${detail.firstname} ${detail.lastname}`}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <BadgeOutlinedIcon style={{ fill: "#675CE5" }} />
            <span className="ml-2 text-[#506373] text-xs">Pasaporte:</span>
            <span className="ml-2 text-[#506373] text-xs font-semibold">
              {detail.passport}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <DateRangeIcon style={{ fill: "#675CE5" }} />
            <span className="ml-2 text-[#506373] text-xs">
              Fecha de nacimiento:
            </span>
            <span className="ml-2 text-[#506373] text-xs font-semibold">
              {detail.birthday}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CustomerDetail;
