import React, { useCallback, useEffect, useMemo, useState } from "react";
import BackButton from "../../components/BackButton";
import { useTranslation } from "react-i18next";
import Notification from "../../assets/illustrations/svg/Notification";
import { convertDateFormat } from "../../helpers/dateFormat";
import { useLoader } from "../../context/loaderContext";
import { useQuote } from "../../context/quoteContext";
import { useWhatsapp } from "../../context/whatsappContext";

const AgeException = ({ exception, continuePurchase }) => {

  const { t } = useTranslation();
  const { setLoader } = useLoader();
  const { age_alert } = exception;
  const { data } = useQuote();
  const { whatsapp_number } = useWhatsapp();
  const [totalPaxFlag, setTotalPaxFlag] = useState(0);

  const age_fault = age_alert?.age_fault;
  const who = age_alert?.who || null;

  useEffect(() => {

    const input_total = data?.quote?.input_total;
    const flagPax = input_total > 1 ? 2 : 1;
    setTotalPaxFlag(flagPax);

  }, [data]);

  const handleBtnAction = (e) => {

    const action = e.target.getAttribute('action');

    if (action === 'new_quotation') {

      const pQuery = window.localStorage.getItem('pQuery');
      const pQueryParams = new URLSearchParams(pQuery);

      let date_from = new Date(pQueryParams.get('date_from').replace(/-/g, '/'));
      date_from.setHours(0, 0, 0, 0);
      date_from.setDate(date_from.getDate() + 89);

      const date_to = convertDateFormat(date_from);
      pQueryParams.set('date_to', date_to);
      window.localStorage.setItem('pQuery', "?" + pQueryParams.toString());
    }

    if (action === "new_quotation" || action === "re_quotation") {
      setLoader(true);
      const geoUrl = window.location.pathname.split("/")[1];
      setTimeout(() => {
        // window.location.href = `https://assist-365.com/products`;
        // window.location.href = `http://localhost:9000/ar/products`;
        window.location.href = `/${geoUrl}/products`;
      }, 2000);
    }

    if (action === "continue_workflow") {
      continuePurchase("continue");
    }
  }

  return (
    <main className={`w-full max-w-[350px] sm:max-w-[1024px] mx-auto bg-[#EFF1F4] px-5 py-8 flex flex-col`}>
      <div className="mb-4 sm:mb-0">
        <BackButton label={t("ageException.backToCheckout")} event={() => continuePurchase("back")} />
      </div>
      <div className="max-w-[634px] mx-auto space-y-[34px]">
        <h1 className="font-titillium text-2xl md:text-3xl md:px-8 mt-4 font-bold leading-[32px] text-center text-[#006FE8]">
          {totalPaxFlag === 1 ? t(`ageException.${age_fault}.${totalPaxFlag}.title`) : t(`ageException.${age_fault}.${totalPaxFlag}.title`, { pax_fullname: who })}
        </h1>

        {age_fault === 65 || age_fault === 70 || age_fault === 69 && (
          <p className="text-[#453608] text-left text-sm leading-4"
          >{t(`ageException.${age_fault}.${totalPaxFlag}.dropLine`)}</p>)}

        < div className="flex justify-center"><Notification /></div>
        <h2 className="text-center font-titillium text-2xl font-bold text-[#006FE8]">{t(`ageException.${age_fault}.${totalPaxFlag}.subTitle`)}</h2>
        <p className="text-center text-base font-semibold leading-[120%] text-[#2454AD]">
          {t(`ageException.${age_fault}.${totalPaxFlag}.content`)}
        </p>

        {age_fault === 65 ? (
          <button
            action={"new_quotation"}
            onClick={handleBtnAction}
            className="flex mx-auto text-[#006FE8] text-base font-semibold leading-[140%] underline"
          >{t("ageException.links.newQuotation")}</button>
        ) : (
          <>
            <button
              action={"re_quotation"}
              onClick={handleBtnAction}
              className="flex mx-auto text-[#006FE8] text-base font-semibold leading-[140%] underline"
            >{t("ageException.links.reQuote")}</button>

            {age_fault !== 70 && age_fault !== 69 && <button
              action={"continue_workflow"}
              onClick={handleBtnAction}
              className="flex mx-auto text-[#006FE8] text-base font-semibold leading-[140%] underline"
            >{t("ageException.links.continue")}</button>}
          </>

        )}
        {age_fault !== 70 && age_fault !== 69 &&
          <a id="btn_whatsapp_checkout" className="flex justify-center items-center shadow-md mx-auto bg-[#7BD0C2] mt-8 text-center p-4 text-white text-xl font-semibold leading-[120%] rounded-lg w-full max-w-[303px]"
            href={`https://wa.me/+${whatsapp_number}`}
            target="_blank"
          >
            {t("ageException.buttonChat")}
          </a>}
      </div>
    </main >
  );
};

export default AgeException;