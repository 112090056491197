import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import AlertIcon from "../../assets/icons/iconAlert";
import { useWhatsapp } from "../../context/whatsappContext";

const PricingStatus = ({ open, close, paxName }) => {
  const { t } = useTranslation();
  const { whatsapp_number } = useWhatsapp();

  const handleClose = (action) => {
    
    if (action === "my-voucher") {
      location.href = "https://assist-365.com/mi-voucher";
    }
    if (action === "whatsapp") {
      location.href = `https://wa.me/+${whatsapp_number}`;
    }
    if (action === "home") {
      location.href = "https://assist-365.com";
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ padding: "32px 16px 0 16px", textAlign: "center" }}
      >
        <AlertIcon />
        <span className="text-[#2F6DE0] font-bold text-2xl font-titillium leading-tight pb-3">
          {t("index.errors.pricingModal.reserveVigency.title")}
        </span>
      </DialogTitle>
      <DialogContent
        sx={{ paddingBottom: 0, marginBottom: "0.5rem" }}
        className="text-3xl"
      >
        <DialogContentText id="alert-dialog-description">
          {t("index.errors.pricingModal.reserveVigency.message", {
            fullname: paxName,
          })}
          <ul className="p-0 mx-4 md:max-w-[512px] mt-1 list-disc space-y-1">
            <li>
              <Trans
                i18nKey={"index.errors.pricingModal.reserveVigency.item1"}
                components={{
                  fullname: paxName,
                  ul: <ul></ul>,
                  br: <br />,
                  a: <a></a>,
                  strong: <strong></strong>,
                }}
              />
            </li>
            <li>{t("index.errors.pricingModal.reserveVigency.item2")}</li>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="dialog-actions-btns mx-auto w-full flex flex-col space-2 items-center justify-center mt-2 px-2 max-w-[428px] mb-4">
          <button
            className="bg-[#2F6DE0] text-white text-xl font-bold"
            onClick={() => handleClose("my-voucher")}
          >
            {t(
              "index.errors.pricingModal.reserveVigency.actions.goToMyVoucher"
            )}
          </button>
          <button
            className="bg-[#59D3C2] text-[#1B2225] text-xl font-bold"
            onClick={() => handleClose("whatsapp")}
          >
            {t("index.errors.pricingModal.reserveVigency.actions.contactAgent")}
          </button>
          <button
            className="border-[#006FE8] border-solid  text-[#006FE8] text-xl font-fold rounded-lg border-2"
            onClick={() => handleClose("home")}
          >
            {t("index.errors.pricingModal.reserveVigency.actions.goToHome")}
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default PricingStatus;
