import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./StepperTransition.css";

const StepperContent = ({ children, activeStep }) => {
  return (
    <div className="w-full overflow-hidden mb-12">
      <TransitionGroup>
        <CSSTransition
          key={activeStep}
          timeout={500}
          classNames="stepper-transition"
        >
          <div className="clear-both">{children[activeStep]}</div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default StepperContent;
