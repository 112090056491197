import React, { createContext, useContext, useEffect, useState } from "react";

const PrismaContext = createContext();

export const usePrisma = () => {
  return useContext(PrismaContext);
};

export const PrismaProvider = ({ prismaActivation, children }) => {
  const [decidirInstance, setDecidirInstance] = useState(null);

  const publicApiKey = "d1279bbbff194999996c6db4571793b4";
  const urlSandbox = "https://live.decidir.com/api/v2";

  const init = () => {
    const decidir = new Decidir(urlSandbox);
    decidir.setPublishableKey(publicApiKey);
    decidir.setTimeout(5000);
    setDecidirInstance(decidir);
  };

  useEffect(() => {
    if (prismaActivation) {
      init();
    }
  }, [prismaActivation]);

  const contextValue = {
    decidirInstance,
  };

  return (
    <PrismaContext.Provider value={contextValue}>
      {children}
    </PrismaContext.Provider>
  );
};
