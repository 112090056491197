import React from "react";
const Pet = () => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame">
        <g id="Group 633258">
          <g id="Group 633256">
            <g id="Vector">
              <path d="M11.514 8.12401C11.514 6.75016 9.87783 5.65997 7.8827 5.97724C4.95381 6.44178 3.61542 11.9135 3.73266 12.8959C3.81566 13.5907 5.52236 14.5979 7.5258 13.8843C8.8341 13.4178 9.55932 12.4511 9.95772 11.4133" fill="white" />
              <path d="M11.514 8.12401C11.514 6.75016 9.87783 5.65997 7.8827 5.97724C4.95381 6.44178 3.61542 11.9135 3.73266 12.8959C3.81566 13.5907 5.52236 14.5979 7.5258 13.8843C8.8341 13.4178 9.55932 12.4511 9.95772 11.4133" stroke="white" stroke-width="2.17814" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <g id="Vector_2">
              <path d="M15.9414 8.12401C15.9414 6.75016 17.5776 5.65997 19.5727 5.97724C22.5016 6.44178 23.84 11.9135 23.7227 12.8959C23.6397 13.5907 21.933 14.5979 19.9296 13.8843C18.6213 13.4178 18.005 12.4511 17.6066 11.4133" fill="white" />
              <path d="M15.9414 8.12401C15.9414 6.75016 17.5776 5.65997 19.5727 5.97724C22.5016 6.44178 23.84 11.9135 23.7227 12.8959C23.6397 13.5907 21.933 14.5979 19.9296 13.8843C18.6213 13.4178 18.005 12.4511 17.6066 11.4133" stroke="white" stroke-width="2.17814" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <g id="Vector_3">
              <path d="M8.11019 8.97116C7.81982 10.0393 5.28803 16.2948 5.28907 17.3982C5.28907 21.5217 9.00543 23.7673 13.5892 23.7673C18.1729 23.7673 21.8892 21.5217 21.8892 17.3982C21.8892 16.3495 18.7551 9.35177 18.4117 8.25566M11.788 8.11629C12.3813 8.00527 12.9846 7.95064 13.5892 7.95321C14.3984 7.95321 15.1454 8.05996 15.8312 8.25566" fill="white" />
              <path d="M8.11019 8.97116C7.81982 10.0393 5.28803 16.2948 5.28907 17.3982C5.28907 21.5217 9.00543 23.7673 13.5892 23.7673C18.1729 23.7673 21.8892 21.5217 21.8892 17.3982C21.8892 16.3495 18.7551 9.35177 18.4117 8.25566M11.788 8.11629C12.3813 8.00527 12.9846 7.95064 13.5892 7.95321C14.3984 7.95321 15.1454 8.05996 15.8312 8.25566" stroke="white" stroke-width="2.17814" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </g>
          <g id="Group 633257">
            <g id="Vector_4">
              <path d="M13.0775 8.12401C13.0775 6.75016 11.4413 5.65997 9.44618 5.97724C6.51729 6.44178 5.1789 11.9135 5.29614 12.8959C5.37914 13.5907 7.08584 14.5979 9.08927 13.8843C10.3976 13.4178 11.1228 12.4511 11.5212 11.4133" fill="#57B7FF" />
              <path d="M13.0775 8.12401C13.0775 6.75016 11.4413 5.65997 9.44618 5.97724C6.51729 6.44178 5.1789 11.9135 5.29614 12.8959C5.37914 13.5907 7.08584 14.5979 9.08927 13.8843C10.3976 13.4178 11.1228 12.4511 11.5212 11.4133" stroke="#57B7FF" stroke-width="2.17814" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <g id="Vector_5">
              <path d="M17.5044 8.12401C17.5044 6.75016 19.1405 5.65997 21.1357 5.97724C24.0646 6.44178 25.403 11.9135 25.2857 12.8959C25.2027 13.5907 23.496 14.5979 21.4926 13.8843C20.1843 13.4178 19.568 12.4511 19.1696 11.4133" fill="#57B7FF" />
              <path d="M17.5044 8.12401C17.5044 6.75016 19.1405 5.65997 21.1357 5.97724C24.0646 6.44178 25.403 11.9135 25.2857 12.8959C25.2027 13.5907 23.496 14.5979 21.4926 13.8843C20.1843 13.4178 19.568 12.4511 19.1696 11.4133" stroke="#57B7FF" stroke-width="2.17814" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <g id="Vector_6">
              <path d="M9.67318 8.97116C9.38281 10.0393 6.85102 16.2948 6.85206 17.3982C6.85206 21.5217 10.5684 23.7673 15.1521 23.7673C19.7359 23.7673 23.4522 21.5217 23.4522 17.3982C23.4522 16.3495 20.3181 9.35177 19.9747 8.25566M13.351 8.11629C13.9442 8.00527 14.5476 7.95064 15.1521 7.95321C15.9614 7.95321 16.7084 8.05996 17.3942 8.25566" fill="#57B7FF" />
              <path d="M9.67318 8.97116C9.38281 10.0393 6.85102 16.2948 6.85206 17.3982C6.85206 21.5217 10.5684 23.7673 15.1521 23.7673C19.7359 23.7673 23.4522 21.5217 23.4522 17.3982C23.4522 16.3495 20.3181 9.35177 19.9747 8.25566M13.351 8.11629C13.9442 8.00527 14.5476 7.95064 15.1521 7.95321C15.9614 7.95321 16.7084 8.05996 17.3942 8.25566" stroke="#57B7FF" stroke-width="2.17814" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </g>
        </g>
        <path id="Vector_7" d="M11.769 6.8272C11.769 5.19499 9.91722 3.89978 7.65913 4.27672C4.34421 4.82862 2.82942 11.3293 2.96211 12.4965C3.05605 13.322 4.9877 14.5186 7.25519 13.6708C8.73592 13.1165 9.55673 11.9681 10.0076 10.7351" stroke="#31319B" stroke-width="1.43467" stroke-linecap="round" stroke-linejoin="round" />
        <path id="Vector_8" d="M16.7793 6.8272C16.7793 5.19499 18.6311 3.89978 20.8892 4.27672C24.2041 4.82862 25.7189 11.3293 25.5862 12.4965C25.4923 13.322 23.5606 14.5186 21.2931 13.6708C19.8124 13.1165 19.1149 11.9681 18.664 10.7351" stroke="#31319B" stroke-width="1.43467" stroke-linecap="round" stroke-linejoin="round" />
        <path id="Vector_9" d="M9.42041 17.1953V17.7824" stroke="#31319B" stroke-width="2.58775" stroke-linecap="round" stroke-linejoin="round" />
        <path id="Vector_10" d="M18.8145 17.1953V17.7824" stroke="#31319B" stroke-width="2.58775" stroke-linecap="round" stroke-linejoin="round" />
        <path id="Vector_11" d="M13.2363 19.8359H14.9977L14.117 20.7166L13.2363 19.8359Z" stroke="#31319B" stroke-width="2.58775" stroke-linecap="round" stroke-linejoin="round" />
        <path id="Vector_12" d="M5.21683 13.9607C4.88819 15.2297 4.72246 16.5354 4.72364 17.8463C4.72364 22.7453 8.92982 25.4132 14.1177 25.4132C19.3055 25.4132 23.5117 22.7453 23.5117 17.8463C23.5117 16.6004 23.3215 15.2629 22.9328 13.9607M12.0792 6.81886C12.7506 6.68695 13.4334 6.62204 14.1177 6.6251C15.0336 6.6251 15.8791 6.75192 16.6552 6.98443" stroke="#31319B" stroke-width="1.43467" stroke-linecap="round" stroke-linejoin="round" />
      </g>
    </svg>
  );
};

export default Pet;
