import React, { useEffect, useState } from "react";
import { useLocation } from "../context/locationContext";
import Checkout from "../view/Checkout";
import Congrats from "../view/Congrats";
import A365Logo from "../assets/illustrations/svg/a365logo";
import { useQuote } from "../context/quoteContext";
import AgeException from "../view/AgeException";
import CountdownTimer from "../components/CountDownTimer";
import LinkExpired from "../view/LinkExpired";

const Route = ({ setPrismaActivation }) => {
  const { isCongrats, isAgeException } = useLocation();
  const { isLink, linkExpired } = useQuote();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let renderCheckout =
    typeof isLink !== "undefined" ? (
      <Checkout setPrismaActivation={setPrismaActivation} />
    ) : null;

  return (
    <>
      <div className="flex items-center justify-start w-full bg-[#0371e8] py-3 px-6">
        <A365Logo width="45px" />
        {isLink && !linkExpired && <CountdownTimer />}
      </div>
      {isCongrats && <Congrats />}
      {isAgeException && <AgeException />}
      {!isCongrats && !isAgeException && !linkExpired && renderCheckout}
      {isLink && linkExpired && <LinkExpired />}
    </>
  );
};

export default Route;
