import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DateRangeIcon from "@mui/icons-material/DateRange";
import GppGoodIcon from "@mui/icons-material/GppGood";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useTranslation } from "react-i18next";
import { Card, CardContent } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useQuote } from "../../context/quoteContext";

const PurchaseDetail = ({ purchaseDetail }) => {

  const { t } = useTranslation();
  const { isLink } = useQuote();

  const mapUpgradesData = (upgrades) => {
    return upgrades.map((row) => {
      if (row.name.trim() === "EMBARAZO") {
        return {
          ...row,
          title: t("upgrades.pregnant.title"),
          description: t("upgrades.pregnant.description"),
          note: t("upgrades.pregnant.note"),
          label: t("upgrades.pregnant.name"),
        };
      }
      if (
        row.name.trim() === "TECNOLOGÍA PROTEGIDA" ||
        row.name.trim() === "TECNOLOGIA PROTEGIDA"
      ) {
        return {
          ...row,
          title: t("upgrades.technology.title"),
          description: t("upgrades.technology.description"),
          note: t("upgrades.technology.note"),
          label: t("upgrades.technology.name"),
        };
      }
      if (
        row.name.trim() === "ROTURA DE EQUIPAJE" ||
        row.name.trim() === "ROTURA DE EQUIPAJE."
      ) {
        return {
          ...row,
          title: t("upgrades.luggage.title"),
          description: t("upgrades.luggage.description"),
          note: t("upgrades.luggage.note"),
          label: t("upgrades.luggage.name"),
        };
      }
      if (
        row.name.trim() === "COBERTURA PARA MASCOTAS" ||
        row.name.trim() === "COBERTURA PARA MASCOTAS."
      ) {
        return {
          ...row,
          title: t("upgrades.pet.title"),
          description: t("upgrades.pet.description"),
          note: t("upgrades.pet.note"),
          label: t("upgrades.pet.name"),
        };
      }
      return row;
    });
  };

  const currency = purchaseDetail?.currency_symbol;
  const upgrades = purchaseDetail?.upgrade_list ? mapUpgradesData(purchaseDetail.upgrade_list) : null;
  const deposit_discount = purchaseDetail?.deposit_discount ?? null;

  return (
    <Card>
      <CardContent>
        <h1 className="text-[#2F6DE0] font-semibold text-2xl text-center mb-6">
          {t("purchaseDetail.title")}
        </h1>
        <hr className="mb-6 ml-14 mr-14" />
        <>
          <table className="table-auto w-full mb-8">
            <thead>
              <tr>
                <th className="text-left py-3">
                  <h3 className="font-semibold text-[#1B2225]">
                    {purchaseDetail?.name}
                  </h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <PersonIcon style={{ fill: "#675CE5" }} />
                  <span className="ml-2 text-[#506373] text-xs">
                    {t("purchaseDetail.assistenceForPeople", {
                      count: purchaseDetail?.total_pax,
                    })}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <LocationOnIcon style={{ fill: "#675CE5" }} />
                  <span className="ml-2 text-[#506373] text-xs">
                    {t("purchaseDetail.destiny", {
                      destiny: t(
                        `purchaseDetail.destinations.${purchaseDetail?.destiny}`
                      ),
                    })}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <DateRangeIcon style={{ fill: "#675CE5" }} />
                  <span className="ml-2 text-[#506373] text-xs">
                    {t("purchaseDetail.from", {
                      from: purchaseDetail?.date_from,
                    })}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <DateRangeIcon style={{ fill: "#675CE5" }} />
                  <span className="ml-2 text-[#414D58] text-xs">
                    {t("purchaseDetail.to", {
                      to: purchaseDetail?.date_to,
                    })}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table-auto w-full mb-8">
            <thead>
              <tr>
                <th className="text-left py-3">
                  <h3 className="font-semibold text-[#1B2225]">
                    {t("purchaseDetail.purchaseDetail")}
                  </h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <GppGoodIcon style={{ fill: "#675CE5" }} />
                  <span className="ml-2 text-[#506373] text-xs">
                    {t("purchaseDetail.value", {
                      plan_name: purchaseDetail?.name,
                    })}
                  </span>
                </td>
                <td className="text-right">
                  <span className="text-[#414D58] text-xs font-bold">
                    {t("purchaseDetail.basePrice", {
                      base_price: purchaseDetail?.base_price,
                      currency,
                    })}
                  </span>
                </td>
              </tr>
              {deposit_discount &&
                <tr>
                  <td>
                    <GppGoodIcon style={{ fill: "#675CE5" }} />
                    <span className="ml-2 text-[#506373] text-xs">
                      Descuento Transferencia
                    </span>
                  </td>
                  <td className="text-right">
                    <span className="text-[#414D58] text-xs font-bold">
                      -${deposit_discount} {currency}
                    </span>
                  </td>
                </tr>
              }
              <tr>
                <td>
                  <GppGoodIcon style={{ fill: "#675CE5" }} />
                  <span className="ml-2 text-[#506373] text-xs">
                    {t("purchaseDetail.valueWithPercentOff", {
                      percent: !isLink ? parseInt(purchaseDetail?.promo?.promo_percent_apply) :
                        parseInt(purchaseDetail?.promo_percent),
                    })}
                  </span>
                </td>
                <td className="text-right">
                  <span className="text-[#414D58] text-xs font-bold">
                    {t("purchaseDetail.discountPrice", {
                      discount_price: purchaseDetail?.total_price_promo,
                      currency,
                    })}
                  </span>
                </td>
              </tr>
              {upgrades &&
                upgrades.length > 0 &&
                upgrades.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <ShoppingCartIcon style={{ fill: "#675CE5" }} />
                      <span className="ml-2 text-[#506373] text-xs">
                        {`${row?.label} ${row.count > 0 ? "(x" + row.count + ")" : ""
                          }`}
                      </span>
                    </td>
                    <td className="text-right">
                      <span className="text-[#414D58] text-xs font-bold">
                        {t("purchaseDetail.upgradePrice", {
                          upgrade_price: row.price,
                          currency,
                        })}
                      </span>
                    </td>
                  </tr>
                ))}

              {((purchaseDetail?.discount && isLink) || (purchaseDetail?.discount?.value)) && (
                <tr>
                  <td>
                    <LocalOfferIcon style={{ fill: "#675CE5" }} />
                    <span className="ml-2 text-[#506373] text-xs">
                      {t("purchaseDetail.priceWithDiscountCode", {
                        percent: !isLink ? purchaseDetail?.discount?.percent :
                          purchaseDetail?.discount_percent
                        ,
                      })}
                    </span>
                  </td>
                  <td className="text-right">
                    <span className="text-[#414D58] text-xs font-bold">
                      {t("purchaseDetail.discountPrice", {
                        discount_price: !isLink ? purchaseDetail?.discount?.value : purchaseDetail?.discount,
                        currency,
                      })}
                    </span>
                  </td>
                </tr>
              )}

              {purchaseDetail?.discount_dual?.value && (
                <tr>
                  <td>
                    <LocalOfferIcon style={{ fill: "#675CE5" }} />
                    <span className="ml-2 text-[#506373] text-xs">
                      {t("purchaseDetail.priceWithDiscountCodeDual", {
                        percent: purchaseDetail?.discount_dual?.percent,
                      })}
                    </span>
                  </td>
                  <td className="text-right">
                    <span className="text-[#414D58] text-xs font-bold">
                      {t("purchaseDetail.discountPrice", {
                        discount_price: purchaseDetail?.discount_dual?.value,
                        currency,
                      })}
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <table className="table-auto w-full">
            <tbody>
              <tr>
                <td>
                  <span className="text-[#414D58] text-2xl font-bold">
                    {t("purchaseDetail.total")}
                  </span>
                </td>
                <td className="text-right">
                  <span className="text-[#414D58] text-2xl font-bold">
                    {t("purchaseDetail.totalPrice", {
                      total_price: purchaseDetail?.total_price,
                      currency,
                    })}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      </CardContent>
    </Card>
  );
};

export default PurchaseDetail;
