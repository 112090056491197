import React from "react";
import InputMask from "react-input-mask";
import { FormGroup, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const ContactForm = ({ control, Controller, errors }) => {
  const { t } = useTranslation();

  const ERROR_MESSAGES = {
    form: {
      REQUIRED: t("form.error.required"),
    },
  };

  return (
    <>
      <FormGroup className="mb-4">
        <label className="font-bold text-xs mb-2 text-[#596E80]">
          {t("form.label.name")}
        </label>
        <Controller
          name={`contact_name_0`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              error={!!errors[`contact_name_0`]}
              helperText={errors[`contact_name_0`]?.message}
            />
          )}
        />
      </FormGroup>
      <FormGroup className="mb-4">
        <label className="font-bold text-xs mb-2 text-[#596E80]">
          {t("form.label.phone")}
        </label>
        <Controller
          name={`contact_phone_0`}
          control={control}
          render={({ field }) => (
            <InputMask
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            >
              {() => (
                <TextField
                  fullWidth
                  error={!!errors[`contact_phone_0`]}
                  helperText={errors[`contact_phone_0`]?.message}
                  placeholder="+54 011-4444-4444"
                  type="phone"
                />
              )}
            </InputMask>
          )}
        />
      </FormGroup>
    </>
  );
};

export default ContactForm;
