import React, { useEffect, useState } from "react";
import Whatsapp from "../../assets/illustrations/svg/Whatsapp";
import { useTranslation } from "react-i18next";
import "./WhatsappSupport.css";
import { useWhatsapp } from "../../context/whatsappContext";

const WhatsappSupport = ({ reserve_id }) => {

    const { t } = useTranslation();
    const [reserveID, setReserveId] = useState(null);
    const { whatsapp_number } = useWhatsapp();

    useEffect(() => {
        if (reserve_id) {
            setReserveId(reserve_id);
        }

    }, [reserve_id]);

    return (
        <div className="whatsapp-support-container ws-app-wrapper">
            <a id="btn_whatsapp_checkout"
                href={`https://wa.me/+${whatsapp_number}/?text=${t("index.whatsappSupportMessage")}${reserveID}`}
                target="_blank"
            >
                <div className="whatsapp-support-box shadow-lg">
                    <div className="whatsapp-support-message">
                        {t("index.whatsappSupport")}
                    </div>
                    <Whatsapp />
                </div>
            </a>
        </div >

    )
}

export default WhatsappSupport;