import React, { createContext, useContext, useState } from "react";
import "./loader.css";

const LoaderContext = createContext();
export const useLoader = () => {
  return useContext(LoaderContext);
};
export const LoaderProvider = ({ children }) => {
  const [loader, setLoader] = useState(false);

  const setLoading = (value) => {
    setLoader(value);
  };

  const contextValue = {
    setLoader: setLoading,
  };

  return (
    <LoaderContext.Provider value={contextValue}>
      {loader && (
        <div className="loader-backdrop">
          <span class="loader"></span>
        </div>
      )}
      {children}
    </LoaderContext.Provider>
  );
};
