// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.depositBoxNotification {
  background: #E5E3FC;
  border: 1px solid #3C2CE8;
  color: #0e0849;
  font-family: Inter;
  font-size: 16px;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  padding: 12px 56px 12px 16px;
  margin-bottom: 2rem;
  position: relative;
}
.closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/assets/deposit.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,4BAA4B;EAC5B,mBAAmB;EACnB,kBAAkB;AACpB;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;AACnB","sourcesContent":[".depositBoxNotification {\n  background: #E5E3FC;\n  border: 1px solid #3C2CE8;\n  color: #0e0849;\n  font-family: Inter;\n  font-size: 16px;\n  border-radius: 8px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 120%;\n  text-align: center;\n  padding: 12px 56px 12px 16px;\n  margin-bottom: 2rem;\n  position: relative;\n}\n.closeBtn {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
