import React from "react";

const AlertIcon = () => {
  return (
    <div className="py-8">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="mx-auto"
        width="100"
        height="98"
        viewBox="0 0 100 98"
        fill="none"
      >
        <path d="M0 0H100V97.6166H0V0Z" fill="white" fill-opacity="0.01" />
        <path
          d="M50.001 89.482C61.5068 89.482 71.9235 84.9294 79.4637 77.5689C87.0039 70.2084 91.6677 60.04 91.6677 48.8084C91.6677 37.5768 87.0039 27.4084 79.4637 20.0478C71.9235 12.6873 61.5068 8.13477 50.001 8.13477C38.4952 8.13477 28.0785 12.6873 20.5382 20.0478C12.9981 27.4084 8.33435 37.5768 8.33435 48.8084C8.33435 60.04 12.9981 70.2084 20.5382 77.5689C28.0785 84.9294 38.4952 89.482 50.001 89.482Z"
          fill="white"
          fill-opacity="0.74"
          stroke="#4C64D6"
          stroke-width="7.33456"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M50.0001 75.2465C52.8765 75.2465 55.2084 72.9702 55.2084 70.1623C55.2084 67.3544 52.8765 65.0781 50.0001 65.0781C47.1236 65.0781 44.7917 67.3544 44.7917 70.1623C44.7917 72.9702 47.1236 75.2465 50.0001 75.2465Z"
          fill="#4C64D6"
        />
        <path
          d="M49.9993 24.4038V56.9427"
          stroke="#4C64D6"
          stroke-width="7.33456"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default AlertIcon;
