import React, { createContext, useContext, useEffect, useState } from "react";
import { countriesFetchController } from "../dataController/dataController";

const CountriesContext = createContext({
  data: null,
});
export function CountriesProvider({ children }) {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await countriesFetchController.fetchData();

        const res_order = result.data.sort(function (a, b) {
          var nameA = a.name.toUpperCase();
          var nameB = b.name.toUpperCase();
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        });

        let uniqueCountries = new Map(
          res_order.map((obj) => [obj.name, obj])
        ).values();

        uniqueCountries = [...uniqueCountries];
        setCountries(uniqueCountries);
      } catch (error) {
        console.warn(error);
      } finally {
        // console.warn("done");
      }
    };

    fetchData();
  }, []);

  const value = {
    countries,
  };

  return (
    <CountriesContext.Provider value={value}>
      {children}
    </CountriesContext.Provider>
  );
}

export function useCountries() {
  const context = useContext(CountriesContext);
  if (!context) {
    throw new Error("useData debe ser utilizado dentro de un DataProvider");
  }
  return context;
}
